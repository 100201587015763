import React from 'react';

import { Table } from 'reactstrap';

import { Button, Col, Row } from 'antd';
import { useScreenshot } from 'use-react-screenshot';
import classes from './TableauComparatif.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import CoordonneesClient from './Coordonneesclient/Coordonneesclient';
import GroupMember from './Groupmember/Groupmember';

import SessionStorageService from '../../../utils/sessionStorageService';
import ImageBase from '../../../assets/ImageBase64/ImageBase64';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { PanoramaSharp } from "@mui/icons-material";

const TableauComparatif = (props) => {
  //eslint-disable-next-line no-unused-vars
  const [numeroDevis, setNumeroDevis] = React.useState(Date.now());

  const HospitalisationTitles = ['Honoraires', 'Chambre particulière', 'Soins'];

  const dentaire = ['Soins', 'Prothèse', 'Orthodontie'];

  const OptiqueTitles = [
    'Monture + Verres simples',
    'Monture + Verres complexes',
    'Lentille remboursé par la ss',
    'Chirurgie refractive',
  ];

  const SoinsCourantsTitles = [
    'Consultations',
    'Labo ',
    ' Auxiliaires medicaux',
    'Médecine alternative',
    'Radiologie',
  ];

  const pharmacieTitles = [
    'Médicaments - Important',
    'Médicaments - Rendu modéré ',
    'Médicaments - Rendu faible',
    'Pharmacie non remboursée',
  ];
  const acheelProducts = [
    '1193',
    '1194',
    '1195',
    '1196',
    '1197',
    '1198',
    '1199',
    '1200',
  ];

  const ccmoProducts = ['1201', '1202', '1203'];
  const oreilleTitles = ['Audio prothése '];

  const containerDiv = React.createRef(null);

  // eslint-disable-next-line
  const [image, takeScreenshot] = useScreenshot();

  const navigate = useNavigate();
  const params = useLocation();

  let comparison = SessionStorageService().getBareComparison();
  let tarifs = params.state.tarifs;

  React.useEffect(() => {
    const Tarifkeys = Object.keys(tarifs);
    for (let i = 0; i < Tarifkeys.length; i++) {
      const idx = comparison.findIndex(
        (item) =>
          parseInt(item.produit.produit_sante.cotation_id) ===
          parseInt(Tarifkeys[i])
      );
      if (idx !== -1) {
        comparison[idx].tarif = tarifs[Tarifkeys[i]];
      }
    }
    SessionStorageService().setComparaison(comparison);
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    let apiKey = window.location.search;
    navigate(`/devis${apiKey}`, {
      state: {
        comparison: comparison,
        tarifs: tarifs,
        numeroDevis: numeroDevis,
      },
    });
  };

  return (
    <div>
      <CoordonneesClient />
      <GroupMember />

      <div className={classes.headerContainer}>
        <Row
          style={{
            paddingInline: '2rem',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Col>
            <Button
              onClick={() =>
                navigate(`/?partnership_id=${localStorage.getItem('api_key')}`)
              }
              className={classes.buttons}
              size='large'
              type='primary'
              style={{
                backgroundColor: '#8c8c8c',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: '0.5rem' }}
                icon={faArrowLeft}
              />
              Précédent
            </Button>
          </Col>
          <Col lg='6' xs='12'>
            <Button
              className={classes.devisComparatifButton}
              onClick={handleClick}
              size='large'
              type='primary'
              style={{
                backgroundColor: '#4682b4',
                color: 'white',
              }}
            >
              Devis Comparatif{' '}
            </Button>
            <Button
              size='large'
              type='primary'
              style={{
                backgroundColor: '#faad14',
                color: 'white',
                margin: '1rem',
              }}
              onClick={() => {
                takeScreenshot(containerDiv.current)
                  .then((dataUrl) => {
                    const downloadLink = document.createElement('a');
                    const fileName = 'tableauComparatif.png';
                    downloadLink.href = dataUrl;
                    downloadLink.download = fileName;
                    downloadLink.click();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Exporter PNG
            </Button>
            {/* <Button
              onClick={toggle}
              size="large"
              type="primary"
              style={{ backgroundColor: "#32cd32", color: "white" }}
            >
              Envoyer par Email
            </Button> */}
          </Col>
        </Row>
      </div>
      <div className={classes.table} ref={containerDiv}>
        <Table>
          <thead>
            <tr style={{ background: '#283062' }}>
              <td></td>
              {comparison &&
                comparison?.map((item, index) => {
                  return (
                    <td key={index}>
                      <h6 className={classes.solution_h6}>
                        Cie : {item?.produit.nom_cie}
                      </h6>
                      <h6 className={classes.solution_h6}>
                        {item?.produit.produit_sante.nom_produit}
                      </h6>
                      <h6 className={classes.solution_h6}>
                        Option : {item?.produit.produit_sante.option}
                      </h6>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img alt='pdf_icon' src={ImageBase[0].pdf} />
                        <h6 className={classes.solution_h6}>
                          <a
                            // href={
                            //   acheelProducts.includes(
                            //     item.produit.produit_sante.cotation_id
                            //   )
                            //     ? "https://sdn.as-solution.cloud.geoprod.com/static/uploads/Documentation%20contractuelle_acheel/ALL%20SANT%C3%89-%20CG%20[V6%2012102023].pdf"
                            //     : ccmoProducts.includes(
                            //         item.produit.produit_sante.cotation_id
                            //       )
                            //     ? "https://sdn.geoprod.com/static/uploads/cmp/780508073/1700126765.620177__Notice_dinformation.pdf"
                            //     : !["undefined",null,""].includes(sessionStorage.getItem("notice_information"))
                            //     ? sessionStorage.getItem("notice_information")
                            //     : "404"
                            // }
                            href={
                              acheelProducts.includes(
                                item?.produit?.produit_sante?.cotation_id
                              )
                                ? process.env
                                    .REACT_APP_NOTICE_INFORMATION_ACHEEL
                                : item?.produit?.notice
                                ? item?.produit?.notice
                                : '404'
                            }
                            target='_blank'
                            rel='noreferrer'
                          >
                            Notice d'information
                          </a>
                        </h6>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img alt='pdf_icon' src={ImageBase[0].pdf} />
                        <h6 className={classes.solution_h6}>
                          <a
                            href={
                              item?.tableau_garantie
                                ? item?.tableau_garantie[0]
                                : '/'
                            }
                            target='_blank'
                            rel='noreferrer'
                          >
                            Tableau de garantie
                          </a>
                        </h6>
                      </div>
                    </td>
                  );
                })}
            </tr>
          </thead>

          <tbody>
            <tr className={classes.table_th}>
              <th colSpan={comparison?.length + 1}>
                <img
                  alt='Hospitalisation'
                  src={ImageBase[1].Hospitalisation}
                ></img>
                HOSPITALISATION
              </th>
            </tr>

            {HospitalisationTitles?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => {
                  return (
                    <td key={index}>
                      {
                        item?.garanties.find((a) => a?.libelle === title)
                          ?.valeur_affiche
                      }{' '}
                    </td>
                  );
                })}
              </tr>
            ))}
            <tr className={classes.table_th}>
              <th colSpan={comparison?.length + 1}>
                <img alt='DENTAIRE' src={ImageBase[2].dentaire}></img>
                DENTAIRE
              </th>
            </tr>

            {dentaire?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => {
                  return (
                    <td key={index}>
                      {
                        item?.garanties.find((a) => a?.libelle === title)
                          ?.valeur_affiche
                      }{' '}
                    </td>
                  );
                })}
              </tr>
            ))}
            <tr className={classes.table_th}>
              <th className={classes.table_th} colSpan={comparison?.length + 1}>
                <img alt='Optique' src={ImageBase[3].optique}></img>
                OPTIQUE
              </th>
            </tr>

            {OptiqueTitles?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => (
                  <td key={index}>
                    {
                      item?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }{' '}
                  </td>
                ))}
              </tr>
            ))}
            <tr className={classes.table_th}>
              <th colSpan={comparison?.length + 1}>
                <img
                  alt='SOINS COURANTS'
                  src={ImageBase[4].soins_courants}
                ></img>
                SOINS COURANTS
              </th>
            </tr>

            {SoinsCourantsTitles?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => (
                  <td key={index}>
                    {
                      item?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }{' '}
                  </td>
                ))}
              </tr>
            ))}
            <tr className={classes.table_th}>
              <th colSpan={comparison?.length + 1}>
                <img alt='pharmacie' src={ImageBase[5].pharmacie}></img>
                PHARMACIE
              </th>
            </tr>

            {pharmacieTitles?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => (
                  <td key={index}>
                    {
                      item?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }{' '}
                  </td>
                ))}
              </tr>
            ))}
            <tr className={classes.table_th}>
              <th colSpan={comparison?.length + 1}>
                <img alt='oreille' src={ImageBase[6].oreille}></img>
                Audio prothése
              </th>
            </tr>
            {oreilleTitles?.map((title, index) => (
              <tr key={index}>
                <td className={classes.propositionTableItem}>{title}</td>
                {comparison?.map((item, index) => (
                  <td key={index}>
                    {
                      item?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }{' '}
                  </td>
                ))}
              </tr>
            ))}
            <tr className={classes.cotisation}>
              <td> COTISATION</td>
              {comparison?.map((item, index) => (
                <td key={index}>
                  {tarifs &&
                    tarifs[item?.produit.produit_sante.cotation_id] + ' €'}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TableauComparatif;
