const SessionStorageService = () => {
  const _setUserInfo = (userInfo) => {
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
  };
  const _getUserInfo = () => {
    return JSON.parse(sessionStorage.getItem("userInfo"));
  };
  const _setProductData = (productData) => {
    sessionStorage.setItem("productData", JSON.stringify(productData));
  };
  const _getProductData = () => {
    return JSON.parse(sessionStorage.getItem("productData"));
  };
  const _setEmailProspect = (EmailProspect) => {
    sessionStorage.setItem("prospect", JSON.stringify(EmailProspect));
  };

  const _getEmailProspect = () => {
    return JSON.parse(sessionStorage.getItem("prospect"));
  };

  const _setId = (id_opportunite) => {
    sessionStorage.setItem("SessionURLID", id_opportunite);
  };

  const _getId = () => {
    return sessionStorage.getItem("SessionURLID");
  };
  const _setIdProspect = (id_prospect) => {
    sessionStorage.setItem("id_prospect", id_prospect);
  };

  const _getIdProspect = () => {
    return sessionStorage.getItem("id_prospect");
  };
  const _setTarif = (tarif) => {
    sessionStorage.setItem("Tarif", JSON.stringify(tarif));
  };

  const _getTarif = () => {
    return JSON.parse(sessionStorage.getItem("Tarif"));
  };

  const _setBareComparison = (bareComparison) => {
    sessionStorage.setItem("BareComparison", JSON.stringify(bareComparison));
  };

  const _getBareComparison = (bareComparison) => {
    return JSON.parse(sessionStorage.getItem("BareComparison"));
  };

  const _setCustomerInfo = (customerInfo) => {
    sessionStorage.setItem("customerInfo", JSON.stringify(customerInfo));
  };

  const _getCustomerInfo = () => {
    return JSON.parse(sessionStorage.getItem("customerInfo"));
  };

  const _setProspect = (prospect) => {
    sessionStorage.setItem("prospect", JSON.stringify(prospect));
  };

  const _getProspect = () => {
    return JSON.parse(sessionStorage.getItem("prospect"));
  };

  const _setComparaison = (comparaison) => {
    sessionStorage.setItem("comparaison", JSON.stringify(comparaison));
  };

  const _getComparaison = () => {
    return JSON.parse(sessionStorage.getItem("comparaison"));
  };
  const _setCompInformations = (informations) => {
    sessionStorage.setItem("informations", JSON.stringify(informations));
  };
  const _getCompInformations = () => {
    sessionStorage.getItem("informations");
  };
  
  const _setResiliation = (resiliation) => {
    sessionStorage.setItem("resiliation_data", JSON.stringify(resiliation));
  };

  const _getResiliation = () => {
    return JSON.parse(sessionStorage.getItem("resiliation_data"));
  };
    const _setResiliationCompany = (resiliationCompany) => {
    sessionStorage.setItem("resiliation_company", JSON.stringify(resiliationCompany));
  };

  const _getResiliationCompany = () => {
    return JSON.parse(sessionStorage.getItem("resiliation_company"));
  };

  return {
    setUserInfo: _setUserInfo,
    getUserInfo: _getUserInfo,
    setCustomerInfo: _setCustomerInfo,
    getCustomerInfo: _getCustomerInfo,
    setProspect: _setProspect,
    getProspect: _getProspect,
    setComparaison: _setComparaison,
    getComparaison: _getComparaison,
    getId: _getId,
    setId: _setId,
    setBareComparison: _setBareComparison,
    getBareComparison: _getBareComparison,
    setTarif: _setTarif,
    getTarif: _getTarif,
    setEmailProspect: _setEmailProspect,
    getEmailProspect: _getEmailProspect,
    getIdProspect: _getIdProspect,
    setIdProspect: _setIdProspect,
    setCompInformations: _setCompInformations,
    getCompInformations: _getCompInformations,
    setProductData: _setProductData,
    getProductData: _getProductData,
    setResiliation:_setResiliation,
    getResiliation:_getResiliation,
    setResiliationCompany:_setResiliationCompany,
    getResiliationCompany:_getResiliationCompany
  };
};

export default SessionStorageService;
