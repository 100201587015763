import React, { useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Button, Row, Col } from "antd";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalContext from "../../../../contexts/GlobalContext";
import classes from "./GarantiesComponent.module.css";
const GarantiesComponent = (props) => {
  //eslint-disable-next-line
  const {
    setModalData,
    toggle,
    groupeGaranties,
    garantiesFilterValues,
    setGarantiesFilterValues,
  } = props;
  const { valueChecked, setValueChecked } = useContext(GlobalContext);
  const calculeData = (value) => {
    if (garantiesFilterValues) {
      let tempData = [...garantiesFilterValues];
      if (value === "Verres progressifs") {
        let a = tempData.find((i) => i.name === "ex32");
        let b = groupeGaranties?.list.find((h) => h.id === "ex32");
        a.value = (b?.val_max * 1) / 4;
        let x = tempData.find((i) => i.name === "ex31");
        x.value = 0;
      } else if (value === "Verres simples") {
        let a = tempData.find((i) => i.name === "ex31");
        let b = groupeGaranties?.list.find((h) => h.id === "ex31");
        a.value = (b?.val_max * 1) / 4;
        let x = tempData.find((i) => i.name === "ex32");
        x.value = 0;
      }

      groupeGaranties?.list.forEach((item, index) => {
        let a = tempData.find((i) => item.id === i.name);
        switch (value) {
          case "<= 125€":
            a.value = item.val_min;
            break;
          case "> 125€ et <=150€":
            a.value = (item.val_max * 1) / 4;
            break;
          case "> 150€ et <= 250€":
            a.value = (item.val_max * 1) / 2;
            break;
          case "> 250€":
            a.value = (item.val_max * 3) / 4;
            break;
          case "Indifférent":
            a.value = 0;
            break;
          default:
            break;
        }
      });
      setGarantiesFilterValues(tempData);
    }
  };

  return (
    <div
      className={classes.Container}
      style={{
        backgroundColor: props?.color || "#FEEED6",
      }}
    >
      <h5>{props?.title}</h5>
      <FormControl style={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(event) => calculeData(event.target.value)}
          value={valueChecked[props.title] ? valueChecked[props.title] : ""}
        >
          <Row gutter={24} className={classes.RadioContainer}>
            {props?.items?.map((item, index) => (
              <Col style={{ marginRight: "3.5rem" }} key={index}>
                <FormControlLabel
                  onChange={(e) =>
                    setValueChecked({
                      ...valueChecked,
                      [props.title]: e.target.value,
                    })
                  }
                  value={item || ""}
                  control={<Radio />}
                  label={item}
                />
              </Col>
            ))}
          </Row>
        </RadioGroup>
      </FormControl>
      {props?.btn && (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            toggle();
            setModalData(groupeGaranties);
          }}
        >
          <FontAwesomeIcon
            style={{ marginRight: "0.5rem" }}
            icon={faArrowUpRightFromSquare}
          />
          Personnaliser
        </Button>
      )}
    </div>
  );
};

export default GarantiesComponent;
