import React from "react";
import classes from "./LayoutTarificateur.module.css";
import { Row, Col } from "reactstrap";
import { Outlet } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import { Skeleton } from "@material-ui/lab";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";
import ExpirationPage from "../../pages/ExpirationPage/ExpirationPage";
const LayoutTarificateur = () => {
  const { tarifFinal, tarifLoadder, repriseData, expiredReprise } =
    React.useContext(GlobalContext);
  if (expiredReprise) {
    return <ExpirationPage />;
  }
  return Object.keys(repriseData).length > 0 ? (
    <div>
      <Row className={classes.header}>
        <Col className={classes.headerCol}>
          <div style={{ width: "50px", height: "50px" }}>
            {/* <img src={`${assets.logo}`} alt="" /> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <h2 className={classes.title}>
              TARIFICATEUR
              <span className={classes.organismTitle}>
                {" "}
                As Solutions (SANTÉ) - Total cotisation mensuelle
              </span>
            </h2>
            <span style={{ color: "white" }} className={classes.organismTitle}>
              (y compris options , services, hors frais de dossier){" "}
            </span>
          </div>
          <h1 className={classes.tarifTotal}>
            {tarifLoadder ? (
              <Skeleton
                style={{ backgroundColor: "#ffffff8a", borderRadius: "20px" }}
                variant="circle"
                width={105}
                height={45}
              />
            ) : (
              tarifFinal ? `${tarifFinal} €` :""
            )}{" "}
          </h1>
        </Col>
      </Row>
      <Outlet className={classes.outlet} />
      <Row className={classes.footer}>
        <Col xs="3" className={classes.footerCol}>
          <p>  {process.env.REACT_APP_VERSION} © 2015 - {moment().format("YYYY")} . Tous droits réservés.</p>
        </Col>
        <Col xs="9" className={classes.footerCol}>
          <div>
            AS Solutions Société par Actions Simplifiée au capital social de 1
            046 000 euros Siège social : 15 Rue Bernoulli, 75008 PARIS, FRANCE
            RCS Paris 908 499 056 code APE 6622Z – courtier d’assurances ORIAS
            N°22000804 https://www.orias.fr Entreprise régie par le Code des
            assurances et soumise au contrôle de l’ACPR 4 Place de Budapest – CS
            92459 – 75436 paris Cedex 09. RC Professionnelle et garantie
            financière conformes aux articles L512-6 et L5612-7 du Code des
            assurances 9 As SOLUTIONS déclare être détenue par : UNIRE, société
            d’assurance mutuelle immatriculée sous le numéro 779 352 814 au
            répertoire SIRENE, dont le siège social est situé 40 rue de la Croix
            des Hormes 69250 Montanay à travers une participation directe égale
            à 9,5% du capital - ASSURANCE MUTUELLE D’OUTREMER, société
            d’assurance mutuelle immatriculée sous le numéro 399 965 524 au RCS
            de Fort-de-France, dont le siège social est situé au 2 bis avenue
            des Arawaks 97200 Fort-de-France à travers une participation directe
            égale à 9,5% du capital - MUTUELLE D’ASSURANCE DES ARMEES, société
            d’assurance mutuelle immatriculée sous le numéro 784 338 451 au
            répertoire SIRENE, dont le siège social est situé 27 rue de Madrid
            75008 Paris à travers une participation directe égale à 9,5% du
            capital - L’ETOILE, société d’assurance mutuelle immatriculée sous
            le numéro 775 687 627 au répertoire SIRENE, dont le siège social est
            situé 16 avenue Hoche 75008 Paris à travers une participation
            directe égale à 9,5% du capital.
            {/* <p className={classes.solution}>As Solutions - Société par Actions Simplifiée (SAS) / capital : 740 000€   RCS Paris 908 499 056    NAF 6622Z - APE 6622Z – courtier d’assurances  - ORIAS 22000804</p>
            <p >Entreprise régie par le Code des assurances et soumise au contrôle de l’ACPR 4 Place de Budapest – CS 92459 – 75436 paris Cedex 09.</p>
            <p className={classes.garantie}>
              RC Professionnelle et garantie financière conformes aux articles L512-6 et L512-7 du Code des assurances
            </p>
            <p>Conformément aux articles L. 521-2 et R. 521-1 du Code des assurances, As SOLUTIONS déclare être détenue par :
              UNIRE, société d’assurance mutuelle immatriculée sous le numéro 779 352 814 au répertoire SIRENE, dont le siège social est situé 40 rue de la Croix des Hormes 69250 Montanay à travers une participation directe égale à 13,5% du capital
              ASSURANCE MUTUELLE D’OUTREMER, société d’assurance mutuelle immatriculée sous le numéro 399 965 524 au RCS de Fort-de-France, dont le siège social est situé au 2 bis avenue des Arawaks 97200 Fort-de-France à travers une participation directe égale à 13,5% du capital</p>
            <p>MUTUELLE D’ASSURANCE DES ARMEES, société d’assurance mutuelle immatriculée sous le numéro 784 338 451 au répertoire SIRENE, dont le siège social est situé 27 rue de Madrid 75008 Paris à travers une participation directe égale à 13,5% du capital</p>
            <p>L’ETOILE, société d’assurance mutuelle immatriculée sous le numéro 775 687 627 au répertoire SIRENE, dont le siège social est situé 16 avenue Hoche 75008 Paris à travers une participation directe égale à 13,5% du capital.</p> */}
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LayoutTarificateur;
