import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Button, Input } from 'reactstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import classes from './CalendarComparateur.module.css';

const CalendarComparateur = (props) => {
  const { modal, toggle } = props;
  const [value, onChange] = useState(new Date());

  return (
    <div>
      <Modal size='xl' isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Button onClick={toggle} className={classes.xButton}>
            x
          </Button>
          <Row>
            <Col xs='12' lg='6'>
              <p className={classes.souhait}>je souhaite être rappelé le : </p>
              <p className={classes.date}>Date du rendez-vous :</p>
              <Calendar
                className={classes.calendar}
                onChange={onChange}
                value={value}
              />
              <p className={classes.heure}>Heure du rendez-vous :</p>
              <div className={classes.bouttonDiv}>
                <Button className={classes.boutton}>Matin</Button>
                <Button className={classes.boutton}>Journée</Button>
                <Button className={classes.boutton}>Soir</Button>
              </div>
              <p className={classes.numéro}>Sur ce numéro de téléphone :</p>
              <Input
                className={classes.input}
                placeholder='votre numéro de téléphone'
              ></Input>
              <Button className={classes.vailderButton}>Valider</Button>
            </Col>
            <Col md='6' lg='6' className={classes.imgCol}>
              <img
                alt='women-call'
                style={{ width: '100%', height: '100%' }}
                src='	https://mutuelle-direct.cloud.geoprod.com/css/imgs/women-call.jpg'
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CalendarComparateur;
