import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Select, Row, Col } from "antd";
import classes from "./Member.module.css";

function Member({ name, birth_date, ro }) {
  const [annee, setAnnee] = useState([]);
  const roOptions = [
    { Label: "Sécurité sociale", value: "Régime général" },
    { Label: "Travailleurs non salariés", value: "Travailleurs non salariés" },
    { Label: "Agricole salarié", value: "Agricole salarié" },
    { Label: "Agricole exploitant", value: "Agricole exploitant" },
    { Label: "Alsace mosellle", value: "Régime local" },
  ];
  let ladate = new Date();
  useEffect(() => {
    let arr = [];
    for (let i = 1900; i <= ladate.getFullYear(); i++) {
      arr.push({ label: `${i}`, value: i });
    }
    setAnnee(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icon = () => {
    switch (name) {
      case "Assuré":
        return (
          <img
            src={require("../../../../../assets/assuree.png")}
            alt="assuree"
          />
        );
      case "Conjoint ":
        return (
          <img
            src={require("../../../../../assets/couple.png")}
            alt="conjoint"
          />
        );
      case "Enfant 1":
        return (
          <img
            src={require("../../../../../assets/enfant1.png")}
            alt="enfant1"
          />
        );
      case "Enfant 2":
        return (
          <img
            src={require("../../../../../assets/enfant2.png")}
            alt="enfant2"
          />
        );
      case "Enfant 3":
        return (
          <img
            src={require("../../../../../assets/enfant3.png")}
            alt="enfant3"
          />
        );
      default:
        return (
          <img
            src={require("../../../../../assets/enfant4.png")}
            alt="enfant4"
          />
        );
    }
  };

  return (
    <Row gutter={24}>
      <Col className={classes.colStyle}>
        <Accordion defaultActiveKey="1" style={{ marginBottom: "2rem" }}>
          <Accordion.Item eventKey="0" style={{ minHeight: "4.5rem" }}>
            <Accordion.Header>
              {icon()}
              <span className={classes.titre}> {name} </span>
            </Accordion.Header>
            <Accordion.Body>
              <Form layout="vertical" className={classes.formStyle}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={"Régime Obligatoire"}>
                      <Select
                        options={roOptions}
                        size="large"
                        defaultValue=  {ro?.toString() === 'Régime général'
                        ? 'Sécurité sociale'
                        : ro?.toString() === 'Régime local'
                        ? 'Alsace moselle'
                        : ro?.toString()}
                        // defaultValue={ro.toString()}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label={"Année de naissance"}>
                      <Select
                        options={annee}
                        size="large"
                        defaultValue={new Date(birth_date)
                          .getFullYear()
                          .toString()}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
}

export default Member;
