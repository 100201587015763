import React from "react";
import Familymember from "./Familymember/Familymember";
import { Col, Row } from "antd";
import { Button as AntdButton } from "antd";
import classes from "../Menage/Menage.module.css";
import SessionStorageService from "./../../../../utils/sessionStorageService";
import dayjs from "dayjs";
// import { Alert, Snackbar } from "@mui/material";
import { alertMessage } from "../../../../utils/alertFunction";
import GlobalContext from "../../../../contexts/GlobalContext";
function Menage(props) {
  const { customerInformation, setCostumerInformation, getYears } = props;
  const {
    dateEffet
  } = React.useContext(GlobalContext);
  // const [limiteEnfant, setLimiteEnfants] = useState(false);
  const onAddChild = () => {
    let customerInformations = SessionStorageService().getCustomerInfo();
    let temp = [...customerInformations];
    let nb =
      customerInformations.filter((item) => item.relationship === "children")
        ?.length || 0;
    if (nb < 6) {
      temp.push({
        number: customerInformations[0].number + nb + 1,
        primary_subscriber: false,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: "",
        },
        address: {
          zip_code: customerInformations[0].address.zip_code,
        },
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
        },
        relationship: "children",
        relationship_rank: 11 + nb,
      });
      setCostumerInformation(temp);
    } else {
      // setLimiteEnfants(true);
      alertMessage("Nous vous informons que le nombre maximum d'enfants à assurer est de 6.","error")
    }

    // setNbr(temp);
  };
  const onAddConjoint = () => {
    let customerInformations = SessionStorageService().getCustomerInfo();
    let temp = [...customerInformations];
    !temp.find((item) => item.relationship === "married") &&
      temp.push({
        number: 2,
        primary_subscriber: false,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: "",
        },
        address: {
          zip_code: customerInformations[0].address.zip_code,
        },
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
        },
        relationship: "married",
        relationship_rank: 2,
      });
    setCostumerInformation(temp);
  };

  return (
    <div className={classes.memberRow}>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1rem",
        }}
      >
        <Col>
          <h4 className={classes.title}>Composition Familiale</h4>
        </Col>
        <Col className={classes.newChildButton}>
          <Row gutter={24}>
            <Col>
              <AntdButton
                size="large"
                type="primary"
                className={classes.newChildButton}
                onClick={onAddConjoint}
              >
                Ajouter Conjoint
              </AntdButton>
            </Col>
            <Col>
              <AntdButton
                size="large"
                type="primary"
                onClick={onAddChild}
                className={classes.newChildButton}
              >
                Ajouter un enfant
              </AntdButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        {customerInformation.find(
          (item) => item.relationship === "married"
        ) && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Familymember
              customerInformation={customerInformation}
              setCostumerInformation={setCostumerInformation}
              count={1}
              name="Conjoint(e)"
              item={customerInformation.find(
                (item) => item.relationship === "married"
              )}
              dn={
                customerInformation.find(
                  (item) => item.relationship === "married"
                )?.birth_info?.date
              }
              disabledDate={(current) => {
                // return current && current.isAfter(dayjs().subtract(18, 'years'));
                return    current && current.isAfter(dayjs(dateEffet,"DD/MM/YYYY").subtract(18, "years").endOf("year"))
              }}
              // setConjoint={setConjoint}
            />
          </Col>
        )}

        {customerInformation
          .filter((item) => item.relationship === "children")
          ?.map((nb, index) => {
            return (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                <Familymember
                  customerInformation={customerInformation}
                  setCostumerInformation={setCostumerInformation}
                  count={index + 2}
                  name={`Enfant ${index + 1}`}
                  item={nb}
                  dn={nb?.birth_info?.date}
                  disabledDate={(current) => {
                    return    current && current.isAfter(dayjs().subtract(0, "years"))
                  }}
                />
              </Col>
            );
          })}
      </Row>
      {/* <Snackbar
        open={limiteEnfant}
        autoHideDuration={3000}
        onClose={() => setLimiteEnfants(false)}
      >
        <Alert
          onClose={() => setLimiteEnfants(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Nous vous informons que le nombre maximum d'enfants à assurer est de
          6.
        </Alert>
      </Snackbar> */}
    </div>
  );
}

export default Menage;
