import React from "react";
import classes from "./DevisTarificateurCard.module.css";
const DevisTarificateurCard = (props) => {
  const { title, content } = props;
  return (
    <div>
      <div className={classes.divContainer}>
        <h3>{title}</h3>
        <h3 className={classes.content_h3}>{content}</h3>
      </div>
    </div>
  );
};

export default DevisTarificateurCard;
