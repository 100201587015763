import React from "react";
import classes from "../InformationsComplementaires/InformationsComplementaires.module.css";
import InformationsCard from "../InformationsComplementaires/InformationsCard/InformationsCard";
const InformationsComplémentaires = () => {
  return (
    <div className={classes.InformationsBox}>
      <InformationsCard />
    </div>
  );
};

export default InformationsComplémentaires;
