import React from 'react';
import classes from './TheadComponent.module.css';
import GlobalContext from '../../../../contexts/GlobalContext';
const TheadComponent = (props) => {
  const { nom_produit, option, proposition } = props;
  const {tarifFinal} = React.useContext(GlobalContext);
  return (
    <div>
      <h6 className={classes.centerBlock}>{nom_produit}</h6>
      <h6 className={classes.centerBlock}>Option : {option} </h6>
      <h6 className={classes.centerBlock}>Prix/mois : {tarifFinal} €/mois </h6>
      <h6 className={classes.rightBlock}>
        Téléchargez la{' '}
        <a
          href={proposition?.produit?.notice}
          target='_blank'
          rel='noreferrer'
          style={{ color: 'red' }}
        >
          {' '}
          Notice d'information
        </a>
      </h6>
      <h6 className={classes.rightBlock}>
        {' '}
        Téléchargez le{' '}
        <a
          href={proposition.tableau_garantie[0]}
          target='_blank'
          rel='noreferrer'
          style={{ color: 'red' }}
        >
          {' '}
          Tableau de garanties
        </a>
      </h6>
    </div>
  );
};

export default TheadComponent;
