import React from "react";
import classes from "./TotalCotisation.module.css";
import { Row, Col } from "react-bootstrap";
import GlobalContext from "../../../contexts/GlobalContext";
import { Skeleton } from "@mui/material";

function TotalCotisation({ cout, name, final = false }) {
  const { tarifLoadder } = React.useContext(GlobalContext);
  const style = final  ? {
    backgroundColor: "#FFE0EA",
    color: "#ED1C24",
  }:{
    backgroundColor: "#ebf0ff",
    color: "#3237AF",
  }
  return (
    <div className={classes.cotisation}>
      {/* <Row>
        <Col className={classes.title}>
          <EastIcon />
          <h4>{name}</h4>
        </Col>
      </Row> */}
      <Row className={classes.rowcout}>
        <Col className={classes.cout} style={style}>
          <h4>{name}</h4>
          {tarifLoadder ? (
            <Skeleton
              animation="wave"
              variant="text"
              style={{  borderRadius: "10px" }}
              sx={{ fontSize: "2.5rem", width: 60 }}
            />
          ) : (
            <h2>{cout} €</h2>
          )}{" "}
          <div></div>
        </Col>
      </Row>
    </div>
  );
}

export default TotalCotisation;
