import React, { useState, useEffect } from "react";
import classes from "./Coordonneesclient.module.css";
import { Row, Col, Form, Input } from "antd";
import SessionStorageService from "../../../../utils/sessionStorageService";

import moment from "moment";
function CoordonneesClient(props) {
  //eslint-disable-next-line
  const [annee, setAnnee] = useState([]);
  // eslint-disable-next-line
  const [departement, setDepartement] = useState([]);

  // eslint-disable-next-line
  const [prospectData, setProspectData] = useState(
    SessionStorageService().getProspect()
  );

  let ladate = new Date();
  useEffect(() => {
    let arr = [];
    for (let i = 1900; i <= ladate.getFullYear() + 4; i++) {
      arr.push(i);
    }
    setAnnee(arr);
    let arrdep = [];
    for (let i = 1; i < 99; i++) {
      arrdep.push(i);
    }
    setDepartement(arrdep);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form layout="vertical" className={classes.formulaire}>
      <h5 className={classes.clientTitle}>Coordonnées Client</h5>
      {/* <Row>
        <Col xs="12" lg="6">
          <Label for="nom">Nom</Label>
          <Input
            name="email"
            value={prospectData?.name}
            id="nom"
            placeholder=""
            disabled={true}
          />
        </Col>
        <Col xs="12" lg="6">
          <Label for="Prenom">Prénom</Label>
          <Input
            name="Prenom"
            id="exampleEmail"
            value={prospectData?.surname}
            placeholder=""
            disabled={true}
          />
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {/* <Label for='dept'>Département (*) :</Label>
          <Input
            type='select'
            value={prospectData?.departement ? prospectData?.departement : ''}
            name='select'
            id='dept'
            disabled={true}
          >
            {departement &&
              departement?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </Input> */}
          <Form.Item label={"Code Postal "}>
            <Input
              size="large"
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address.zip_code
              }
              style={{ color: "black" }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Date d'effet "}>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={ moment(prospectData?.dateEffet, 'DD-MM-YYYY').format(
                'DD/MM/YYYY'
              ) }
              disabled
            />
          </Form.Item>
          {/* {annee &&
              annee?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))} */}
        </Col>
      </Row>
    </Form>
  );
}

export default CoordonneesClient;
