/* eslint-disable no-unused-vars */
import React from "react";
import classes from "./Echeance.module.css";
import { Row, Col, Form, Input, Select } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import { checkSelectedGamme } from "../../../utils/utils";

const Echances = ({
  data,
  setData,
  isValidBic,
  isValidIBan,
  setBanquePrelevement,
  setBanqueRemboursement,
}) => {
  const [assure, setAssure] = React.useState(
    SessionStorageService().getEmailProspect()
  );
  const datePrelevementOptions = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
  ];
  const datePrelevementOptionsCcmo = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
  ];
  const paymentMethodOptions = [
    { label: "Prélèvement automatique", value: "Prélèvement automatique" },
    { label: "PRELEVEMENT SEPA", value: "PRELEVEMENT SEPA" },
    { label: "CHEQUE", value: "CHEQUE" },
  ];
  const paymentOptions = [
    {
      label: <span style={{ color: "black" }}>Prélèvement automatique</span>,
      value: "Prélèvement automatique",
    },
    {
      label: <span style={{ color: "black" }}>PRELEVEMENT SEPA</span>,
      value: "Direct Debit",
    },
    { label: <span style={{ color: "black" }}>CHEQUE</span>, value: "Cheque" },
  ];

  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    SessionStorageService().setProspect(prospect);
    // setData([...data], (data[DataIndex][name] = e.target.value));
    /* let newdata = dataAdhesion
    newdata[`${name}`] = e.target.value */
    let key = name;
    setData((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  React.useEffect(() => {
    /* setData(
      [...data],
      (data[105]["type_paiement_sepa||Type de paiement||7"] = "Direct Debit"),
      (data[101]["nomprenom_sepa||Nom/Prénom du débiteur||1"] =
        data[146]["nom_assure||Nom||1"] +
        " " +
        data[2]["prenom_assure||Prénom||2"]),
      (data[100]["adresse_sepa||Adresse||2"] =
        SessionStorageService().getCustomerInfo()[0].address.locality),
      (data[102]["cp_sepa||Code postal||3"] =
        data[4]["cp_assure||Code postal||4"]),
      (data[106]["ville_sepa||Ville||4"] =
        SessionStorageService().getCustomerInfo()[0].address.locality),
      (data[103]["bic_sepa||BIC||5"] = assure["bic_sepa||BIC||5"] || ""),
      (data[104]["iban_sepa||IBAN||6"] = assure["iban_sepa||IBAN||6"] || ""),
      (data[98]["ibanremb_assure||IBAN rembourssement||11"] =
        assure["ibanremb_assure||IBAN rembourssement||11"] || ""),
      (data[97]["bicremb_assure||BIC rembourssement||10"] =
        assure["bicremb_assure||BIC rembourssement||10"] || "")
    ); */

    setData({
      ...data,
      "type_paiement_sepa||Type de paiement||7": "Direct Debit",
      "nomprenom_sepa||Nom/Prénom du débiteur||1":
        data["nom_assure||Nom||1"] + " " + data["prenom_assure||Prénom||2"],
      "adresse_sepa||Adresse||2":
        SessionStorageService().getCustomerInfo()[0].address.locality,
      "cp_sepa||Code postal||3": data["cp_assure||Code postal||4"],
      "ville_sepa||Ville||4":
        SessionStorageService().getCustomerInfo()[0].address.locality,
      "bic_sepa||BIC||5": assure["bic_sepa||BIC||5"] || "",
      "iban_sepa||IBAN||6": assure["iban_sepa||IBAN||6"] || "",
      "ibanremb_assure||IBAN rembourssement||11":
        assure["ibanremb_assure||IBAN rembourssement||11"] || "",
      "bicremb_assure||BIC rembourssement||10":
        assure["bicremb_assure||BIC rembourssement||10"] || "",
    });

    // eslint-disable-next-line
  }, []);

  return data["type_paiement_sepa||Type de paiement||7"] ? (
    <Form layout="vertical" className={classes.form}>
      <Row gutter={16}>
        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Référence"}>
            <Input
              size="large"
              name="referenca_sepa||Référence||0"
              onChange={(e) =>
                handleChange(e, "change", "referenca_sepa||Référence||0")
              }
            />
          </Form.Item>
        </Col> */}
        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Nom/Prénom du débiteur"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="nomprenom_sepa||Nom/Prénom du débiteur||1"
              defaultValue={
                data["nom_assure||Nom||1"] +
                " " +
                data["prenom_assure||Prénom||2"]
              }
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "nomprenom_sepa||Nom/Prénom du débiteur||1"
                )
              }
              disabled
            />
          </Form.Item>
        </Col> */}

        {/* </Row>
      <Row gutter={16}> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Adresse"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="adresse_sepa||Adresse||2"
              // defaultValue={
              //   SessionStorageService().getCustomerInfo()[0].address.fullAddress
              // }
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address.AdressPostale
              }
              onChange={(e) =>
                handleChange(e, "change", "adresse_sepa||Adresse||2")
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Code postal"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="cp_sepa||Code postal||3"
              defaultValue={data["cp_assure||Code postal||4"]}
              onChange={(e) =>
                handleChange(e, "change", "cp_sepa||Code postal||3")
              }
              disabled
            />
          </Form.Item>
        </Col>
        {/* </Row>
      <Row gutter={16}> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Ville"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="ville_sepa||Ville||4"
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address.locality
              }
              onChange={(e) =>
                handleChange(e, "change", "ville_sepa||Ville||4")
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Titulaire du compte prélèvement"}>
            <Input
              size="large"
              name="titulaire_compte_prev"
              defaultValue={data["titulaire_compte_prev"]}
              onChange={(e) =>
                handleChange(e, "change", "titulaire_compte_prev")
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"IBAN prélèvement"}
            name="iban_sepa||IBAN||6"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              name="iban_sepa||IBAN||6"
              defaultValue={data["iban_sepa||IBAN||6"]}
              onChange={(e) => handleChange(e, "change", "iban_sepa||IBAN||6")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"BIC prélèvement"}
            name="bic_sepa||BIC||5"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              name="bic_sepa||BIC||5"
              defaultValue={data["bic_sepa||BIC||5"]}
              onChange={(e) => handleChange(e, "change", "bic_sepa||BIC||5")}
            />
          </Form.Item>
        </Col>
        {/* </Row>
      <Row gutter={16}> */}

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"Mode de paiement"}
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Select
              size="large"
              name="type_paiement_sepa||Type de paiement||7"
              options={paymentOptions}
              defaultValue={data["type_paiement_sepa||Type de paiement||7"]}
              onChange={(e) =>
                handleChange(
                  e,
                  "select",
                  "type_paiement_sepa||Type de paiement||7"
                )
              }
              disabled
            />
          </Form.Item>
        </Col> */}
        {/* </Row>
      <Row gutter={16}> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={
              "IBAN remboursement (S'il est différent du Compte Prélèvement.)"
            }
          >
            <Input
              size="large"
              name="ibanremb_assure||IBAN rembourssement||11"
              defaultValue={data["ibanremb_assure||IBAN rembourssement||11"]}
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "ibanremb_assure||IBAN rembourssement||11"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={
              "BIC remboursement (S'il est différent du Compte Prélèvement.)"
            }
          >
            <Input
              size="large"
              name="bicremb_assure||BIC rembourssement||10"
              defaultValue={data["bicremb_assure||BIC rembourssement||10"]}
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "bicremb_assure||BIC rembourssement||10"
                )
              }
            />
          </Form.Item>
        </Col>

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Titulaire du compte prélèvement"}>
            <Input
              size="large"
              name="titulaire_compte_prev"
              defaultValue={data["titulaire_compte_prev"]}
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "titulaire_compte_prev"
                )
              }
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={
              "Titulaire du compte remboursement (S'il est différent du Compte Prélèvement.)"
            }
          >
            <Input
              size="large"
              name="titulaire_compte_remb"
              defaultValue={data["titulaire_compte_remb"]}
              onChange={(e) =>
                handleChange(e, "change", "titulaire_compte_remb")
              }
            />
          </Form.Item>
        </Col>
        {/* </Row>
      <Row gutter={16}> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Mode de paiement"}>
            <Select
              size="large"
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "modepc_sante||Mode de paiement du comptant||22"
                )
              }
              name="modepc_sante||Mode de paiement du comptant||22"
              options={paymentMethodOptions}
              value={data["modepc_sante||Mode de paiement du comptant||22"]}
              type="select"
              disabled
            />
          </Form.Item>
          {/* <Form.Item label={"Nom de la banque prélèvement"}>
            <Input
              size="large"
              onChange={(e) => {
                setBanquePrelevement(e.target.value);
              }}
            />
          </Form.Item> */}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Jour de prélèvement"}>
            <Select
              size="large"
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "date_prelevement_sante||Date de prélèvement||24"
                )
              }
              options={
                checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
                  ? datePrelevementOptionsCcmo
                  : datePrelevementOptions
              }
              name="date_prelevement_sante||Date de prélèvement||24"
              value={data["date_prelevement_sante||Date de prélèvement||24"]}
            />
          </Form.Item>
          {/* <Form.Item label={"Nom de la banque remboursement"}>
            <Input
              size="large"
              onChange={(e) => setBanqueRemboursement(e.target.value)}
            />
          </Form.Item> */}
        </Col>
      </Row>
    </Form>
  ) : (
    <></>
  );
};

export default Echances;
