import React, { useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faPrint,
  faFilePdf,
  faArrowLeft,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { Table, Container, Row, Col } from 'reactstrap';
import { Button } from 'antd';
import moment from 'moment';
import headerImage from './Img-header.png';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import classes from './DevisComparatif.module.css';
import Calendar from '../calendarComparateur/CalendarComparateur';
import { useLocation, useNavigate } from 'react-router-dom';
import SessionStorageService from '../../../../utils/sessionStorageService';

import { useReactToPrint } from 'react-to-print';
import GlobalContext from '../../../../contexts/GlobalContext';
import ImageBase from '../../../../assets/ImageBase64/ImageBase64';
import SendEmail from '../SendEmail/SendEmail';
const DevisComparatif = () => {
  const prospect = SessionStorageService().getProspect();
  const { image, numero } = React.useContext(GlobalContext);
  // const idUrl = sessionStorage.getItem("idUrl");

  const HospitalisationTitles = ['Honoraires', 'Chambre particulière', 'Soins'];

  const dentaire = ['Soins', 'Prothèse', 'Orthodontie'];
  const acheelProducts = [
    '1193',
    '1194',
    '1195',
    '1196',
    '1197',
    '1198',
    '1199',
    '1200',
  ];

  // const ccmoProducts = ["1201", "1202", "1203"];
  const OptiqueTitles = [
    'Monture + Verres simples',
    'Monture + Verres complexes',
    'Lentille remboursé par la ss',
    'Chirurgie refractive',
  ];

  const SoinsCourantsTitles = [
    'Consultations',
    'Labo ',
    ' Auxiliaires medicaux',
    'Médecine alternative',
    'Radiologie',
  ];

  const pharmacieTitles = [
    'Médicaments - Important',
    'Médicaments - Rendu modéré ',
    'Médicaments - Rendu faible',
    'Pharmacie non remboursée',
  ];
  const oreilleTitles = ['Audio prothése '];
  const printRef = useRef();
  const [modal, setModal] = React.useState(false);
  const [idUrl, setIdUrl] = React.useState(sessionStorage.getItem('idUrl'));
  const [SpiningMail, setSpiningMail] = React.useState(false);
  const { setEditorMessage } = useContext(GlobalContext);

  const toggle = () => {
    setModal(!modal);
  };
  const navigate = useNavigate();

  // React.useEffect(() => {

  //   fetchComparison(idUrl);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [idUrl]);

  const handleDownloadPdf = async (type) => {
    let element = printRef.current;
    let canvas = await html2canvas(
      element,
      {
        onclone: function (document, el) {
          const buttons = el.querySelectorAll('.' + classes.noPrint);
          for (let btn of buttons) btn.classList.add(classes.hide);
        },
      },
      {
        onclone: function (document, el) {
          const buttons = el.querySelectorAll('.' + classes.noPrint);
          for (let btn of buttons) btn.classList.add(classes.hide);
        },
      }
    );
    var imgData = canvas.toDataURL('image/png');

    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;

    var doc = new jsPDF('p', 'mm');
    var position = 0;

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    if (type === 'export') {
      doc.save('devis_' + numeroDevis + '.pdf');
    } else {
      const pdfBlob = doc.output('blob');
      return pdfBlob;
    }
  };

  const params = useLocation();
  const { comparison, tarifs, numeroDevis } = params.state;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const sendMailToggle = async () => {
    setEditorMessage(
      `<p style="text-align: justify;">Nous vous remercions de votre intérêt pour notre offre. En réponse à votre demande, veuillez trouver ci-joint le devis correspondant.</p><p><br/></p><p style="text-align: justify;">Si vous avez la moindre question ou si vous souhaitez discuter de certains points du devis, notre équipe se tient à votre disposition. Nous serions ravis de vous aider à comprendre tous les détails et de répondre à toutes vos préoccupations.</p><p><br/></p><p style="text-align: justify;">Cordialement,</p><p><br/></p><p style="text-align: justify;">Service commercial,</p><p style="text-align: justify;"><br/></p><p style="text-align: justify;" class="last-child">As Solutions, </p>`
    );
    const idSessionURL = Date.now() + SessionStorageService().getId();
    sessionStorage.setItem('idUrl', idSessionURL);
    sessionStorage.setItem('idAdhesion', `${idSessionURL + numeroDevis}`);

    setIdUrl(idSessionURL);
    // if (modal === false) {
    //   try {
    //     await axios.post(
    //       `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
    //       {
    //         id: idSessionURL,
    //         objet: {
    //           prospect: SessionStorageService().getProspect(),
    //           customerInfo: SessionStorageService().getCustomerInfo(),
    //           comparison: SessionStorageService().getComparaison(),
    //           tarifs: tarifs,
    //           generatedDate: Date.now(),
    //           numDevis: numeroDevis,
    //         },
    //       }
    //     );
    //     setModal(!modal);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    setModal(!modal);
  };

  const getPdf = async () => {
    const pdfBlob = await handleDownloadPdf('mail');
    const pdfDataUrl = URL.createObjectURL(pdfBlob);
    return pdfDataUrl;
  };

  return (
    <div ref={printRef}>
      <SendEmail
        toggle={toggle}
        modal={modal}
        idURL={idUrl}
        setModal={setModal}
        url={getPdf()}
        SpiningMail={SpiningMail}
        setSpiningMail={setSpiningMail}
        type='comparatif'
      />

      <Calendar toggle={toggle} modal={modal} />
      <Container
        fluid
        style={{
          paddingInline: '4rem',
          backgroundColor: 'white',
        }}
      >
        <Row style={{ position: 'relative' }}>
          <img
            className={classes.headerImage}
            src={headerImage}
            alt='headerImage'
          />
          {image && (
            <img
              className={classes.head}
              alt='logo'
              src={`data:image/png;base64,${image}`}
            />
          )}
          <h1 className={classes.headerTitle}>Votre devis</h1>
        </Row>
        <div>
          <Row style={{ margin: '3rem' }}>
            <Col className={classes.devisContainer}>
              <h2>Devis n° {numeroDevis}</h2>
            </Col>
            {numero && (
              <Col
                lg='6'
                xs='12'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <FontAwesomeIcon icon={faPhone} className={classes.phoneIcon} />
                <h3 className={classes.phoneNumber}> {numero} </h3>
              </Col>
            )}
          </Row>

          <Row>
            <Col xs='12'>
              <h4>Date de génération : {moment().format('DD/MM/YYYY')}</h4>
              <h4>Offre valable pour 60 jours.</h4>
            </Col>
          </Row>

          <Row
            lg='12'
            style={{ backgroundColor: '#f7f6fa', paddingBlock: '1rem' }}
          >
            <Col xs='12' style={{ padding: '1.5rem' }}>
              <p className={classes.devis}> Bonjour,</p>
              <p style={{ fontSize: '1.5rem' }}>
                Merci de nous avoir choisi pour l'élaboration de votre étude
                personnalisée.
                <br />
                Suite à notre entretien, vous trouverez ci-dessous le
                récapitulatif des garanties que nous avons sélectionnées
                ensemble, les tarifs ainsi que des liens pour les consulter.
              </p>
            </Col>
            <Col>
              <h3 className={classes.aneeEffet}>
                DATE D'EFFET :{' '}
                {moment(prospect?.dateEffet, 'DD-MM-YYYY').format('DD/MM/YYYY')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <Table>
                <thead>
                  <tr style={{ background: '#283062' }}>
                    <td></td>
                    {params.state.comparison?.map((item, index) => {
                      return (
                        <td key={index}>
                          <h6 className={classes.solution_h6}>
                            Cie : {item?.produit.nom_cie}
                          </h6>
                          <h6 className={classes.solution_h6}>
                            {item?.produit.produit_sante.nom_produit}
                          </h6>
                          <h6 className={classes.solution_h6}>
                            Option : {item?.produit.produit_sante.option}
                          </h6>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <img alt='pdf_icon' src={ImageBase[0].pdf} />
                            <h6 className={classes.solution_h6}>
                              <a
                                // href={
                                //   acheelProducts.includes(
                                //     item?.produit?.produit_sante?.cotation_id
                                //   )
                                //     ? "https://sdn.as-solution.cloud.geoprod.com/static/uploads/Documentation%20contractuelle_acheel/ALL%20SANT%C3%89-%20CG%20[V6%2012102023].pdf"
                                //     : ccmoProducts.includes(
                                //         item?.produit?.produit_sante?.cotation_id
                                //       )
                                //     ? "https://sdn.geoprod.com/static/uploads/cmp/780508073/1700126765.620177__Notice_dinformation.pdf"
                                //     : !["undefined",null,""].includes(sessionStorage.getItem("notice_information"))
                                //     ? sessionStorage.getItem("notice_information")
                                //     : "404"
                                // }
                                href={
                                  acheelProducts.includes(
                                    item?.produit?.produit_sante?.cotation_id
                                  )
                                    ? process.env
                                        .REACT_APP_NOTICE_INFORMATION_ACHEEL
                                    : item?.produit?.notice
                                    ? item?.produit?.notice
                                    : '404'
                                }
                                target='_blank'
                                rel='noreferrer'
                              >
                                Notice d'information
                              </a>
                            </h6>
                          </div>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <img alt='pdf_icon' src={ImageBase[0].pdf} />
                            <a
                              href={item?.tableau_garantie[0]}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <h6 className={classes.solution_h6}>
                                Tableau de garantie
                              </h6>
                            </a>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th
                      className={classes.table_th}
                      colSpan={params.state.comparison?.length + 1}
                    >
                      <img
                        alt='Hospitalisation'
                        src={ImageBase[1].Hospitalisation}
                      ></img>
                      HOSPITALISATION
                    </th>
                  </tr>

                  {HospitalisationTitles?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {params.state.comparison?.map((item, index) => {
                        return (
                          <td key={index}>
                            {
                              item?.garanties.find((a) => a?.libelle === title)
                                ?.valeur_affiche
                            }
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr className={classes.table_th}>
                    <th colSpan={comparison?.length + 1}>
                      <img alt='DENTAIRE' src={ImageBase[2].dentaire}></img>
                      DENTAIRE
                    </th>
                  </tr>

                  {dentaire?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {comparison?.map((item, index) => {
                        return (
                          <td key={index}>
                            {
                              item?.garanties.find((a) => a?.libelle === title)
                                ?.valeur_affiche
                            }
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr className={classes.table_th}>
                    <th colSpan={params.state.comparison?.length + 1}>
                      <img alt='Optique' src={ImageBase[3].optique}></img>
                      OPTIQUE
                    </th>
                  </tr>

                  {OptiqueTitles?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {params.state.comparison?.map((item, index) => (
                        <td key={index}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr className={classes.table_th}>
                    <th colSpan={params.state.comparison?.length + 1}>
                      <img
                        alt='SOINS COURANTS'
                        src={ImageBase[4].soins_courants}
                      ></img>
                      SOINS COURANTS
                    </th>
                  </tr>

                  {SoinsCourantsTitles?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {params.state.comparison?.map((item, index) => (
                        <td key={index}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr className={classes.table_th}>
                    <th colSpan={params.state.comparison?.length + 1}>
                      <img alt='pharmacie' src={ImageBase[5].pharmacie}></img>
                      PHARMACIE
                    </th>
                  </tr>

                  {pharmacieTitles?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {params.state.comparison?.map((item, index) => (
                        <td key={index}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr className={classes.table_th}>
                    <th colSpan={comparison?.length + 1}>
                      <img alt='oreille' src={ImageBase[6].oreille}></img>
                      Audio prothése
                    </th>
                  </tr>
                  {oreilleTitles?.map((title, index) => (
                    <tr key={index}>
                      <td className={classes.propositionTableItem}>{title}</td>
                      {comparison?.map((item, index) => (
                        <td key={index}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr className={classes.cotisation}>
                    <td> COTISATION</td>
                    {comparison?.map((item, index) => (
                      <td key={index}>
                        {tarifs &&
                          tarifs[item?.produit.produit_sante.cotation_id] +
                            ' €'}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className={classes.noPrint}>
          <Row>
            <Col lg='6'>
              <Button
                onClick={() =>
                  navigate('/tableauComparatif', {
                    state: {
                      comparison: comparison,
                      tarifs: tarifs,
                      numeroDevis: numeroDevis,
                    },
                  })
                }
                className={classes.buttons}
                size='large'
                type='primary'
                style={{
                  backgroundColor: '#8c8c8c',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon={faArrowLeft}
                />
                Précédent
              </Button>
            </Col>
            <Col
              xs='12'
              lg='6'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={sendMailToggle}
                size='large'
                type='primary'
                style={{
                  backgroundColor: '#8c8c8c',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon icon={faShare} className={classes.shareIcon} />
                Envoyer par Email
              </Button>
              <Button
                size='large'
                type='primary'
                onClick={() => handleDownloadPdf('export')}
                style={{
                  marginInline: '1rem',
                  backgroundColor: '#8c8c8c',
                  color: 'white',
                  display: 'flex',
                }}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  style={{ height: '1.5rem', marginRight: '0.3rem' }}
                />
                ExporterPdf
              </Button>

              <Button
                size='large'
                type='primary'
                onClick={handlePrint}
                style={{
                  backgroundColor: '#8c8c8c',
                  color: 'white',
                  display: 'flex',
                }}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    height: '1.5rem',
                    marginRight: '0.3rem',
                  }}
                />{' '}
                Imprimer
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs='12'>
            <h5 className={classes.remerciement_h5}>
              Je reste à votre entière disposition pour tout complément
              d'informations.
            </h5>
            <h5 className={classes.remerciement_h5}>
              Je vous remercie de la confiance que vous nous témoignez, en
              espérant avoir répondu à vos attentes.
            </h5>
            <h5
              style={{
                paddingLeft: '6rem',
                color: 'gray',
                paddingTop: '1.5rem',
              }}
            >
              Cordialement.
            </h5>
          </Col>
        </Row>
        {/* <div className={classes.noPrint}>
          <h3 className={classes.h3}>Ce devis m'intéresse, je souhaite :</h3>
          <Row>
            <Col lg="6" xs="12" className={classes.rappelButtonCol}>
              <Button className={classes.rappelButton} onClick={toggle}>
                ÊTRE RAPPELE
              </Button>
            </Col>
            <Col lg="6" xs="12">
              <Button disabled={true} className={classes.souscrireButton}>
                SOUSCRIRE EN LIGNE
              </Button>
            </Col>
          </Row>
        </div> */}
      </Container>
    </div>
  );
};

export default DevisComparatif;
