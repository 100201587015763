import React, { useState, useEffect } from "react";
import classes from "./Souscripteur.module.css";
import { Row, Col, Form, Select, Input, DatePicker } from "antd";
import GlobalContext from "../../../../contexts/GlobalContext";
import moment from "moment";
import SessionStorageService from "../../../../utils/sessionStorageService";
import axios from "../../../../utils/axios";
import GarantiesSouscripteur from "./GarantiesSouscripteur/GarantiesSouscripteur";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";

function Souscripteur(props) {
  const [form] = Form.useForm();
  const roOptions = [
    { value: "1", label: "Sécurité sociale" },
    { value: "2", label: "Travailleurs non salariés" },
    { value: "3", label: "Agricole salarié" },
    { value: "4", label: "Agricole exploitant" },
    { value: "5", label: "Alsace moselle" },
  ];

  const situationOptions = [
    { value: "Salarié", label: "Salarié" },
    { value: "Profession libérale", label: "Profession libérale" },
    { value: "Artisan", label: "Artisan" },
    { value: "Commerçant", label: "Commerçant" },
    { value: "Exploitant agricole", label: "Exploitant agricole" },
    { value: "Conjoint collaborateur", label: "Conjoint collaborateur" },
    { value: "Etudiant", label: "Etudiant" },
    { value: "Retraité", label: "Retraité" },
    { value: "Sans emploi", label: "Sans emploi" },
    // { value: "Auto-entrepreneur", label: "Auto-entrepreneur" },
    { value: "Non renseigné", label: "Non renseigné" },
    // { value: "Actif", label: "Actif" },








 
    
    // { value: "Enfant", label: "Enfant" },
  ];
  const cardGarantiesConjoint = [
    {
      name: "Êtes_vous_bénéficiaire_de_la_CSS",
      text: "Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?",
    },
    {
      name: "Bénéficiez_vous_une_aide_à_la_complémentaire_santé",
      text: "Bénéficiez-vous d’une aide à la complémentaire santé ?",
    },

    {
      name: "Faites_vous_objet_une_mesure_de_Curatelle_ou_de_Tutelle",
      text: "Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?",
    },
  ];
  // const { isValidNom, isValidPrenom } = props;
  const {
    prospect,
    prospectData,
    setProspectData,
    villeOptions,
    setVilleOptions,
    dateEffet,
    setDataAdhesion,
    dataAdhesion
  } = React.useContext(GlobalContext);
  //eslint-disable-next-line
  const [annee, setAnnee] = useState([]);

  const [villeSelected, setVilleSelected] = useState(
    SessionStorageService().getCustomerInfo()
      ? SessionStorageService().getCustomerInfo()[0]?.address?.locality
      : ""
  );
  const [Dn, SetDn] = useState(
    SessionStorageService().getCustomerInfo()
      ? SessionStorageService()?.getCustomerInfo()[0]?.birth_info?.date
      : ""
  );
  //eslint-disable-next-line
  const [departement, setDepartement] = useState([]);
  const [zipCode, setZipCode] = useState(
    SessionStorageService().getCustomerInfo()
      ? SessionStorageService().getCustomerInfo()[0]?.address?.zip_code
      : ""
  );
  const {
    setCostumerInformation,
    customerInformation,
    garantiesSouscripteur,
    setGarantiesSouscripteur,
    getYears,
  } = props;
  let ladate = new Date();
  const switchOptions = (val) => {
    switch (val) {
      case "Régime général":
        return "1";

      case "Travailleurs non salariés":
        return "2";

      case "Agricole salarié":
        return "3";

      case "Agricole exploitant":
        return "4";

      case "Régime local":
        return "5";

      default:
        return "1";
    }
  };

  useEffect(() => {
    let arr = [];
    for (let i = 1940; i <= ladate.getFullYear() + 1; i++) {
      arr.push(i);
    }
    setAnnee(arr); /* 
    let arrdep = [];
    for (let i = 1; i < 99; i++) {
      arrdep.push(i);
    }
    setDepartement(arrdep); */
    // if (villeSelected) {
    //   getVilles(zipCode);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SessionStorageService().setProspect({
      ...prospectData,
      ...SessionStorageService().getProspect(),
      name: prospect?.name || SessionStorageService().getProspect()?.name,
      surname:
        prospect?.surname || SessionStorageService().getProspect()?.surname,
      DN: prospect?.DN || SessionStorageService().getProspect()?.DN,
      dateEffet: SessionStorageService().getProspect()?.dateEffet
        ? SessionStorageService().getProspect()?.dateEffet
        : moment().add(1, "days").format("DD-MM-YYYY"),
      departement:
        prospect?.CP?.substring(0, 2) ||
        SessionStorageService().getProspect()?.departement ||
        "1",
      ro: SessionStorageService().getProspect()?.ro
        ? SessionStorageService().getProspect()?.ro
        : "1",
      situation: SessionStorageService().getProspect()?.situation
        ? SessionStorageService().getProspect()?.situation
        : "Non renseigné",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect]);
  useEffect(() => {
    setProspectData(
      SessionStorageService().getProspect()?.name
        ? SessionStorageService().getProspect()
        : {
            ...prospectData,
            name: prospect?.name,
            surname: prospect?.surname,
            DN: new Date(prospect?.DN).getFullYear(),
            departement:
              prospect?.CP?.substring(0, 2) ||
              SessionStorageService().getProspect()?.departement,
            ro: SessionStorageService().getProspect()?.ro,
          }
    );

    if (prospect) {
      let costumer = customerInformation;
      costumer[0].birth_info.date =
        "01/01/" + new Date(prospect?.DN).getFullYear();
      /*  costumer[0].address.zip_code = prospect?.CP?.substring(0, 2) + "000"; */
      setCostumerInformation(costumer);
      SessionStorageService().setCustomerInfo(costumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect]);

  const getVilles = (val) => {
    let customer = SessionStorageService().getCustomerInfo();
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/groupe_villes/get_villes_by_code_postal`,
        {
          postal_code: val,
        }
      )
      .then((res) => {
        const formattedOptions = res.data.villes?.map((ville) => ({
          id: ville.id,
          value: ville.nom_comm,
          label: ville.nom_comm,
        }));
        if (formattedOptions && formattedOptions.length > 0) {
          setVilleOptions(formattedOptions);
          setVilleSelected(formattedOptions[0]?.value);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: formattedOptions[0]?.value,
          });
          let newCustomer = SessionStorageService().getCustomerInfo();
          newCustomer[0].address.locality = formattedOptions[0]?.value;
          let ville_selected = formattedOptions?.filter(
            (element) => element.value === formattedOptions[0]?.value
          );
          newCustomer[0].address.code_insee = ville_selected[0]?.id;
          SessionStorageService().setCustomerInfo(newCustomer);
        } else {
          customer[0].address.locality = "";
          customer[0].address.code_insee = "";
          SessionStorageService().setCustomerInfo(customer);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: "",
          });
          setVilleSelected("");
          setVilleOptions([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // Handle the error, e.g., show a message to the user
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ville: "",
        });
        customer[0].address.locality = "";
        customer[0].address.code_insee = "";
        SessionStorageService().setCustomerInfo(customer);
        setVilleSelected("");
        setVilleOptions([]);
      });
  };
  return (
    <Form
      layout="vertical"
      className={classes.form}
      form={form}
      initialValues={{
        "Code postal": zipCode || "",
        ville: villeSelected || "",
        RO: prospectData?.ro,
        Situation: prospectData?.situation ? prospectData?.situation : "Non renseigné",
      }}
    >
      <h4 className={classes.souscripteurTitle}>Souscripteur</h4>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={"Date de naissance"}
            label="Date de naissance"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
              // {
              //   validator: (_, value) => {
              //     if (!value) {
              //       return Promise.resolve();
              //     } else {
              //       const minAgeDate = new Date();
              //       minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);
              //       const maxAgeDate = new Date();
              //       maxAgeDate.setFullYear(maxAgeDate.getFullYear() - 80);
              //       const inputDate = new Date(value);
              //       if (inputDate > minAgeDate) {
              //         return Promise.reject(
              //           new Error("Vous devez avoir au moins 18 ans.")
              //         );
              //       } else if (inputDate < maxAgeDate) {
              //         return Promise.reject(
              //           new Error("Vous devez avoir moins de 80 ans.")
              //         );
              //       }
              //     }
              //     return Promise.resolve();
              //   },
              // },
            ]}
          >
            <DatePicker
              size="large"
              type="date"
              format={"DD/MM/YYYY"}
              locale={locale}
              style={{ width: "100%" }}
              placeholder="JJ/MM/AAAA"
              defaultValue={Dn ? dayjs(Dn, "DD/MM/YYYY") : null}
              required
              onChange={(e) => {
                SetDn(e);
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].birth_info.date = dayjs(e).format("DD/MM/YYYY");
                SessionStorageService().setCustomerInfo(newCustomer);
                let customerInfoo = SessionStorageService().getCustomerInfo();
                customerInfoo[0].birth_info.date =
                  dayjs(e).format("DD/MM/YYYY");
                SessionStorageService().setCustomerInfo(customerInfoo);
                let sessionProspect = SessionStorageService().getProspect();

                SessionStorageService().setProspect({
                  ...sessionProspect,
                  DN: dayjs(e).format("DD/MM/YYYY"),
                });
                let costumer = customerInformation;
                costumer[0].birth_info.date = dayjs(e).format("DD/MM/YYYY");
                setCostumerInformation(costumer);
              }}
              disabledDate={(current) => {
                return (
                  current && current.isAfter(dayjs(dateEffet,"DD/MM/YYYY").subtract(18, "years").endOf("year"))
                );
              }}
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={"Adresse Postale"}
            label="Adresse Postale"
            rules={[
              {
                required: false,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              placeholder=""
              maxLength={128}
              onChange={(e) => {
                setAdresse(e.target.value);
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].address.fullAddress = e.target.value;
                SessionStorageService().setCustomerInfo(newCustomer);
              }}
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={"RO"}
            label="Régime Obligatoire "
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Select
              size="large"
              options={roOptions}
              onSelect={(option) => {
                setProspectData({ ...prospectData, ro: option });
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  ro: option,
                });

                let costumer = SessionStorageService().getCustomerInfo();

                switch (option) {
                  case "1":
                    costumer[0].job.regime = "Régime général";
                    break;
                  case "2":
                    costumer[0].job.regime = "Travailleurs non salariés";
                    break;
                  case "3":
                    costumer[0].job.regime = "Agricole salarié";
                    break;
                  case "4":
                    costumer[0].job.regime = "Agricole exploitant";
                    break;
                  case "5":
                    costumer[0].job.regime = "Régime local";
                    break;
                  default:
                    costumer[0].job.regime = "Régime général";
                    break;
                }

                setCostumerInformation(costumer);
                SessionStorageService().setCustomerInfo(costumer);

                // SessionStorageService().setEmailProspect({
                //   ...SessionStorageService().getEmailProspect(),
                //   ro: costumer[0].job.regime,
                // });
              }}
              defaultValue={SessionStorageService().getProspect()?SessionStorageService().getProspect()["ro"]:"1"}

            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name={"Code postal"}
            label="Code postal"
            maxLength={5}
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
              {
                pattern: new RegExp(/^.{0,5}$/),
                message: "Le code postale doit être au moins 5 chiffres.",
              },
            ]}
          >
            <Input
              size="large"
              maxLength={5}
              onChange={(e) => {
                form.setFieldsValue({
                  "Code postal": e.target.value.replace(/[^0-9]/g, ""),
                });
                if (e.target.value.toString().length === 5) {
                  let newCustomer = SessionStorageService().getCustomerInfo();
                  newCustomer[0].address.zip_code = e.target.value;
                  SessionStorageService().setCustomerInfo(newCustomer);
                  getVilles(e.target.value);
                  setZipCode(e.target.value);
                } else {
                  let newCustomer = SessionStorageService().getCustomerInfo();
                  newCustomer[0].address.zip_code = e.target.value;
                  newCustomer[0].address.locality = "";
                  newCustomer[0].address.code_insee = "";
                  SessionStorageService().setCustomerInfo(newCustomer);
                  setVilleSelected("");
                  setVilleOptions([]);
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    ville: "",
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name={"ville"}
            label="Ville"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Select
              size="large"
              options={villeOptions}
              value={villeSelected}
              defaultValue={villeSelected}
              onSelect={(option) => {
                setVilleSelected(option);
                  let newCustomer = SessionStorageService().getCustomerInfo();
                  newCustomer[0].address.locality = option;
                  let ville_selected = villeOptions?.filter(
                    (element) => element.value === option
                  );
                  newCustomer[0].address.code_insee = ville_selected[0]?.id;
                  SessionStorageService().setCustomerInfo(newCustomer);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label="Situation Professionnelle" name={"Situation"}>
            <Select
              listHeight={500}
              size="large"
              options={situationOptions}
              onChange={(option) => {
                setProspectData({ ...prospectData, situation: option });
                let sessionProspect = SessionStorageService().getProspect();
                SessionStorageService().setProspect({
                  ...sessionProspect,
                  situation: option,
                });
                SessionStorageService().setEmailProspect({
                  ...SessionStorageService().getEmailProspect(),
                  situation: option,
                });
                // setDataAdhesion((prev) => {
                //   return {
                //     ...prev,
                //     "exerceequalite_assure||Exercée en qualité de||17": option,
                //   };
                // });
              }}
             
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row> */}
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {cardGarantiesConjoint?.map((item, index) => (
            <GarantiesSouscripteur
              key={index}
              name={item.name}
              text={item.text}
              garantiesSouscripteur={garantiesSouscripteur}
              setGarantiesSouscripteur={setGarantiesSouscripteur}
            />
          ))}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
      </Row>

      <br />
      {/* <h5 className={classes.titre}>Les champs avec * sont obligatoires</h5> */}
    </Form>
  );
}
//test
export default Souscripteur;
