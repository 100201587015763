import React from "react";

import classes from "./PrincipalesGaranties.module.css";

const PrincipalesGaranties = (props) => {
  const { title, icon, data } = props;
  return (
    <div
      style={{
        textAlign: "center",
        borderRadius: "20px",
      }}
    >
      <div className={classes.divContainer}>
        <img style={{ marginLeft: "70px" }} src={icon} alt="icon" />
        <p style={{ fontSize: "25px", color: "white" }}>{title}</p>
      </div>
      <div className={classes.cardContainer}>
        {data?.map((item, index) => {
          return (
            <div key={index} className={classes.items}>
              <p>{item.key}</p>
              <p>{item.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrincipalesGaranties;
