import React from "react";
import Menage from "./Menage/Menage";
import Souscripteur from "./Souscripteur/Souscripteur";
import EffectiveDate from "./EffectiveDate/EffectiveDate";

function Identite(props) {
  const {
    customerInformation,
    setCostumerInformation,
    isValidNom,
    isValidPrenom,
    garantiesSouscripteur,
    setGarantiesSouscripteur,
    getYears,
  } = props;
  return (
    <div>
      <EffectiveDate />
      <Souscripteur
        customerInformation={customerInformation}
        setCostumerInformation={setCostumerInformation}
        isValidNom={isValidNom}
        isValidPrenom={isValidPrenom}
        garantiesSouscripteur={garantiesSouscripteur}
        setGarantiesSouscripteur={setGarantiesSouscripteur}
        getYears={getYears}
      />
      <Menage
        customerInformation={customerInformation}
        setCostumerInformation={setCostumerInformation}
        getYears={getYears}
      />
    </div>
  );
}

export default Identite;
