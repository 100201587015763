import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Table, Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import headerImage from './Img-header.png';
import classes from './DevisComparatif.module.css';
import Calendar from '../calendarComparateur/CalendarComparateur';

import GlobalContext from '../../../../contexts/GlobalContext';

const DevisURL = () => {
  const { id } = useParams();

  const [loaded, setLoaded] = React.useState(false);

  const {
    fetchComparison,
    prospect,
    comparison,
    image,
    numero,
    numDevis,
  } = React.useContext(GlobalContext);

  React.useEffect(() => {
   id && fetchComparison(id);

    
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    if (comparison) {
      setLoaded(true);
    }
  }, [comparison]);

  const HospitalisationTitles = ['Honoraires', 'Chambre particulière', 'Soins'];

  const dentaire = ['Soins', 'Prothèse', 'Orthodontie'];

  const OptiqueTitles = [
    'Monture + Verres simples',
    'Monture + Verres complexes',
    'Lentille remboursé par la ss',
    'Chirurgie refractive',
  ];

  const SoinsCourantsTitles = [
    'Consultations',
    'Labo ',
    ' Auxiliaires medicaux',
    'Médecine alternative',
    'Radiologie',
  ];

  const pharmacieTitles = [
    'Médicaments - Important',
    'Médicaments - Rendu modéré ',
    'Médicaments - Rendu faible',
    'Pharmacie non remboursée',
  ];
  const oreilleTitles = ['Audio prothése '];

  const printRef = useRef();

  /* const handlePrint = useReactToPrint({
    content: () => printRef.current,
  }); */

  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  /* const handleDownloadPdf = async () => {
    let element = printRef.current;
    let canvas = await html2canvas(element);
    let data = canvas?.toDataURL("image/png");

    let pdf = new jsPDF();
    let imgProperties = pdf?.getImageProperties(data);
    let pdfWidth = pdf?.internal?.pageSize?.getWidth();
    let pdfHeight = (imgProperties?.height * pdfWidth) / imgProperties?.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("devis.pdf");
  }; */

  return !loaded ? (
    <div style={{ textAlign: 'center' }}>Loader in here</div>
  ) : (
    <div ref={printRef}>
      <Calendar toggle={toggle} modal={modal} />

      <Container
        fluid
        style={{
          paddingInline: '2rem',
          backgroundColor: 'white',
        }}
      >
        <Row style={{ position: 'relative' }}>
          <img
            className={classes.headerImage}
            src={headerImage}
            alt='headerImage'
          />
          {image && (
            <img
              className={classes.head}
              alt='logo'
              src={`data:image/png;base64,${image}`}
            />
          )}
          <h1 className={classes.headerTitle}>Votre devis</h1>
        </Row>

        <Row style={{ margin: '3rem' }}>
          <Col className={classes.devisContainer}>
            <h2>Devis n° {numDevis}</h2>
          </Col>
          {numero && (
            <Col
              lg='6'
              xs='12'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <FontAwesomeIcon icon={faPhone} className={classes.phoneIcon} />
              <h3 className={classes.phoneNumber}> {numero} </h3>
            </Col>
          )}
        </Row>

        <Row>
          <Col xs='12'>
            <h4>
              Date de génération : {moment().format('DD/MM/YYYY')}
            </h4>
            <h4>Offre valable pour 60 jours.</h4>
          </Col>
        </Row>

        <Row
          lg='12'
          style={{ backgroundColor: '#f7f6fa', paddingBlock: '1rem' }}
        >
          <Col xs='12' style={{ padding: '1.5rem' }}>
            <p className={classes.devis}>
              {' '}
              Bonjour,
            </p>
            <p style={{ fontSize: '1.5rem' }}>
              Merci de nous avoir choisi pour l'élaboration de votre étude
              personnalisée.
              <br />
              Suite à notre entretien, vous trouverez ci-dessous le
              récapitulatif de la (des) garantie(s) que nous avons
              sélectionnée(s) ensemble, les tarifs ainsi que des liens
              pour les consulter.
            </p>
          </Col>
          <Col>
            <h3 className={classes.aneeEffet}>
              DATE D'EFFET : {prospect?.dateEffet}
            </h3>
          </Col>
        </Row>

        <Row>
          <Col xs='12'>
            <Table>
              <thead>
                <tr style={{ background: '#283062' }}>
                  <td></td>
                  {comparison?.map((item) => {
                    return (
                      <td>
                        <h6 className={classes.solution_h6}>
                          Cie:{item?.produit.nom_cie}
                        </h6>
                        <h6 className={classes.solution_h6}>
                          {item?.produit.produit_sante.nom_produit}
                        </h6>
                        <h6 className={classes.solution_h6}>
                          Option : {item?.produit.produit_sante.option}
                        </h6>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img
                            alt='pdf_icon'
                            src='https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png'
                          />
                          <h6 className={classes.solution_h6}>
                            Notice d'information
                          </h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img
                            alt='pdf_icon'
                            src='https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png'
                          />
                          <h6 className={classes.solution_h6}>
                            Tableau de garantie
                          </h6>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                <th
                  className={classes.table_th}
                  colSpan={comparison?.length + 1}
                >
                  <img
                    alt='Hospitalisation'
                    src='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Hospitalisation_2.png'
                  ></img>
                  HOSPITALISATION
                </th>

                {HospitalisationTitles?.map((title, index) => (
                  <tr>
                    <td key={index} className={classes.propositionTableItem}>
                      {title}
                    </td>
                    {comparison?.map((item) => {
                      return (
                        <td key={item}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      );
                    })}
                  </tr>
                ))}
                <tr className={classes.table_th}>
                  <th colSpan={comparison?.length + 1}>
                    <img
                      alt='DENTAIRE'
                      src='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Dentaire_2.png'
                    ></img>
                    DENTAIRE
                  </th>
                </tr>

                {dentaire?.map((title, index) => (
                  <tr key={index}>
                    <td className={classes.propositionTableItem}>{title}</td>
                    {comparison?.map((item, index) => {
                      return (
                        <td key={index}>
                          {
                            item?.garanties.find((a) => a?.libelle === title)
                              ?.valeur_affiche
                          }
                        </td>
                      );
                    })}
                  </tr>
                ))}

                <th
                  className={classes.table_th}
                  colSpan={comparison?.length + 1}
                >
                  <img
                    alt='Optique'
                    src='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Optique_2.png'
                  ></img>
                  OPTIQUE
                </th>

                {OptiqueTitles?.map((title, index) => (
                  <tr>
                    <td key={index} className={classes.propositionTableItem}>
                      {title}
                    </td>
                    {comparison?.map((item) => (
                      <td>
                        {
                          item?.garanties.find((a) => a?.libelle === title)
                            ?.valeur_affiche
                        }
                      </td>
                    ))}
                  </tr>
                ))}

                <th
                  className={classes.table_th}
                  colSpan={comparison?.length + 1}
                >
                  <img
                    alt='SOINS COURANTS'
                    src='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Soins courants_2.png'
                  ></img>
                  SOINS COURANTS
                </th>

                {SoinsCourantsTitles?.map((title, index) => (
                  <tr>
                    <td key={index} className={classes.propositionTableItem}>
                      {title}
                    </td>
                    {comparison?.map((item) => (
                      <td>
                        {
                          item?.garanties.find((a) => a?.libelle === title)
                            ?.valeur_affiche
                        }
                      </td>
                    ))}
                  </tr>
                ))}

                <th
                  className={classes.table_th}
                  colSpan={comparison?.length + 1}
                >
                  <img
                    alt='pharmacie'
                    src='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Pharmacie_2.png'
                  ></img>
                  PHARMACIE
                </th>

                {pharmacieTitles?.map((title, index) => (
                  <tr>
                    <td key={index} className={classes.propositionTableItem}>
                      {title}
                    </td>
                    {comparison?.map((item) => (
                      <td>
                        {
                          item?.garanties.find((a) => a?.libelle === title)
                            ?.valeur_affiche
                        }
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className={classes.table_th}>
                  <th colSpan={comparison?.length + 1}>
                    <img
                      alt='oreille'
                      src='https://sdn.geoprod.com/static/uploads/cmp/353508955/1665402132.160585__ear.png'
                    ></img>
                    Audio prothése
                  </th>
                </tr>
                {oreilleTitles?.map((title, index) => (
                  <tr key={index}>
                    <td className={classes.propositionTableItem}>{title}</td>
                    {comparison?.map((item, index) => (
                      <td key={index}>
                        {
                          item?.garanties.find((a) => a?.libelle === title)
                            ?.valeur_affiche
                        }
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className={classes.cotisation}>
                  <td> COTISATION</td>
                  {comparison?.map((item, index) => (
                    <td key={index}>{item.tarif + ' €'}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* <Row>
          <Col
            xs="12"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleDownloadPdf}
              style={{ marginInline: "1rem" }}
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ height: "1.5rem" }} />
              ExporterPdf
            </Button>

            <Button onClick={handlePrint}>
              <FontAwesomeIcon
                icon={faPrint}
                style={{ height: "1.5rem", marginRight: "0.3rem" }}
              />
              Imprimer
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col xs='12'>
            <h5 className={classes.remerciement_h5}>
              Je reste à votre entière disposition pour tout complément
              d'informations.
            </h5>
            <h5 className={classes.remerciement_h5}>
              Je vous remercie de la confiance que vous nous témoignez, en
              espérant avoir répondu à vos attentes.
            </h5>
            <h5
              style={{
                paddingLeft: '6rem',
                color: 'gray',
                paddingTop: '2.5rem',
              }}
            >
              Cordialement.
            </h5>
            {/* 
            <h3 className={classes.h3}>Ce devis m'intéresse, je souhaite :</h3> */}
          </Col>
        </Row>

        {/* <Row>
          <Col lg="6" xs="12" className={classes.rappelButtonCol}>
            <Button
              disabled={true}
              className={classes.rappelButton}
              onClick={toggle}
            >
              ÊTRE RAPPELE
            </Button>
          </Col>
          <Col lg="6" xs="12">
            <Button disabled={true} className={classes.souscrireButton}>
              SOUSCRIRE EN LIGNE
            </Button>
          </Col>
        </Row> */}
        {/* PRINTING PAGE */}
      </Container>
    </div>
  );
};

export default DevisURL;
