import { notification } from "antd";

export function alertMessage(message, type) {
  if (type === "warning") {
    notification.warning({
      message: "Warning",
      description: message,
      duration: 4, 
      // closable: true,
      // key: 'notification-key'
    });
  } else if(type==="error") {
    notification.error({
      message: "Erreur",
      description: message,
      duration: 4, 
      // closable: true,
      // key: 'notification-key'
    });
  }
  else{
    notification.warning({
      // message: "juyhj",
      description: message,
      duration: 4, 
      // closable: true,
    
    });
  }
}
