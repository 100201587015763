import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import classes from './FraisTarificateur.module.css';
import GlobalContext from '../../contexts/GlobalContext';
import { Skeleton } from '@mui/material';
// eslint-disable-next-line
import maloImage from '../../assets/maloo.png';
function FraisTarificateur({ options_frais_courtage ,onSelectedFraisCourtage}) {
  const {
    fraisLie,
    setFraisLie,
    fraisCourtage,
    setFraisCourtage,
    malo,
    tarifLoadder,
    fraisAssociatif,
    fraisPj,
    alturgence,
    fraisQuittancement,
  } = React.useContext(GlobalContext);
  const options_frais_lie = [
    { label: '0 €', value: 0 },
    { label: '15 €', value: 15 },
    { label: '30 €', value: 30 },
  ];

  return (
    <Form layout='vertical' className={classes.container}>
      <Row className={classes.rowcout} gutter={16}>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          lg={8}
          className={classes.card}
          style={{
            backgroundColor: '#4285BC',
            color: 'white',
          }}
        >
          <h5>Protection juridique CFDP</h5>
          {tarifLoadder ? (
            <Skeleton
              animation='wave'
              variant='text'
              style={{ borderRadius: '10px' }}
              sx={{ fontSize: '10rem', width: 60 }}
            />
          ) : (
            <h2>{fraisPj || '-'} €</h2>
          )}
        </Col>
      </Row>
      <Row className={classes.rowcout} gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          lg={8}
          className={classes.card}
          style={{
            backgroundColor: '#0F175E',
            color: 'white',
          }}
        >
          <h5>Service Malo</h5>
          {tarifLoadder ? (
            <Skeleton
              animation='wave'
              variant='text'
              style={{ borderRadius: '10px', backgroundColor: '#ffffff8a' }}
              sx={{ fontSize: '10rem', width: 60 }}
            />
          ) : (
            <h2>{malo || '-'} €</h2>
          )}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          lg={8}
          className={classes.card}
          style={{
            backgroundColor: '#0F175E',
            color: 'white',
          }}
        >
          <h5>Service Alturgences</h5>
          {tarifLoadder ? (
            <Skeleton
              animation='wave'
              variant='text'
              style={{ borderRadius: '10px', backgroundColor: '#ffffff8a' }}
              sx={{ fontSize: '10rem', width: 60 }}
            />
          ) : (
            <h2>{alturgence || '-'} €</h2>
          )}
        </Col>
      </Row>
      {/* <Row className={classes.rowcout} gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={11}
          lg={11}
          className={classes.card}
          style={{
            color: "white",
          }}
        >
          <img src={maloImage} />
          {tarifLoadder ? (
            <Skeleton
              animation="wave"
              variant="text"
              style={{ borderRadius: "10px" }}
              sx={{ fontSize: "10rem", width: 60 }}
            />
          ) : (
            <h2>{malo || "-"} €</h2>
          )}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={11}
          lg={11}
          className={classes.card}
          style={{
            backgroundColor: "#41C7CD",
            color: "white",
          }}
        >
          <h5>Service Alturgence</h5>
          {tarifLoadder ? (
            <Skeleton
              animation="wave"
              variant="text"
              style={{ borderRadius: "10px" }}
              sx={{ fontSize: "10rem", width: 60 }}
            />
          ) : (
            <h2>{alturgence || "-"} €</h2>
          )}
        </Col>
      </Row> */}

      {/* <Col
          xs={24}
          sm={24}
          md={24}
          xl={11}
          lg={11}
          className={classes.card}
          style={{
            backgroundColor: "#DBDBDB",
            color: "#191E96",
          }}
        >
          <h5>Frais associatifs 4AS</h5>
          {tarifLoadder ? (
            <Skeleton
              animation="wave"
              variant="text"
              style={{ borderRadius: "10px" }}
              sx={{ fontSize: "10rem", width: 60 }}
            />
          ) : (
            <h2>{fraisAssociatif || "-"} €</h2>
          )}
        </Col> */}
      <Row className={classes.rowcout} gutter={16}>
        <Col xs={24} sm={24} md={24} xl={6} lg={6}>
          <Form.Item label='Frais liés à la Distribution'>
            <Input
              value={`${fraisQuittancement} €`}
              style={{
                color: 'black',
              }}
              size='large'
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} xl={6} lg={6}>
          <Form.Item label='Frais associatifs 4AS'>
            <Input
              value={`${fraisAssociatif} €`}
              style={{
                color: 'black',
              }}
              size='large'
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} xl={6} lg={6}>
          <Form.Item label='Frais de Dossier Distributeur' required>
            <Select
              value={fraisLie}
              size='large'
              options={options_frais_lie}
              onSelect={(option) => {
                setFraisLie(option);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} xl={6} lg={6}>
          <Form.Item label='Frais de Courtage Accessoire' required>
            <Select
              size='large'
              options={options_frais_courtage}
              value={fraisCourtage}
              onSelect={(option) => {
                setFraisCourtage(option);
                if(option !== fraisCourtage){
                  onSelectedFraisCourtage(option);
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default FraisTarificateur;
