import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import classes from "./EffectiveDate.module.css";
import moment from "moment";
import SessionStorageService from "../../../../utils/sessionStorageService";
import GlobalContext from "../../../../contexts/GlobalContext";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";

function EffectiveDate() {
  const { dateEffet, setDateEffet } = React.useContext(GlobalContext);
  // const disabledDate = (current) => {
  //   const currentDate = dayjs();
  //   const minDate = currentDate.startOf('day')
  //   const maxDate = currentDate.add(120, "day");
  //   return current && (current < minDate || current > maxDate);
  // };

  return (
    <Form layout="vertical" className={classes.form}>
      <Row>
        <Col xs={24} md={24} lg={24}>
          {/* <LocalizationProvider

            dateAdapter={AdapterDayjs}
            localeText={
              frFR.components.MuiLocalizationProvider.defaultProps.localeText
            }
            adapterLocale={"fr"}
          >
            <MobileDatePicker
              minDate={moment()}
              maxDate={moment().add(120, "days")}
              label="Date d'effet souhaitée (*) :"
              inputFormat="DD-MM-YYYY"
              value={dateState || ""}
              onChange={(e) => {
                setDateEffet(moment(e["$d"]).format("DD-MM-YYYY"));
                setDateState(e);
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  dateEffet: moment(e["$d"]).format("DD-MM-YYYY"),
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}
          <Form.Item
            label={
              <label className={classes.label}>Date d'effet souhaitée</label>
            }
            name="date_effet"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <DatePicker
              size="large"
              defaultValue={dateEffet ? dayjs(dateEffet, "DD/MM/YYYY") : null}
              format={"DD/MM/YYYY"}
              locale={locale}
              // min={dayjs().startOf("day").add(1, "day").format("YYYY-MM-DD")}
              // max={dayjs().add(4, "month").format("YYYY-MM-DD")}
              onChange={(e) => {
                // setDateEffet(moment(e["$d"]).format("DD/MM/YYYY"));
                setDateEffet(dayjs(e).format("DD/MM/YYYY"));
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  dateEffet: dayjs(e).format("DD/MM/YYYY"),
                });
              }}
              disabledDate={(current) => {
                return current && current.isBefore(dayjs());
              }}
            />
            {/* <DatePicker
              defaultValue={dayjs(dateState)}
              size="large"
              disabledDate={disabledDate}
              format={"DD/MM/YYYY"}
              style={{ width: "100%", height: "100%" }}
              onChange={(e) => {
                // setDateEffet(moment(e["$d"]).format("DD/MM/YYYY"));
                setDateEffet(dayjs(e).format("DD/MM/YYYY"));
                setDateState(e);
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  dateEffet: dayjs(e).format("DD/MM/YYYY"),
                });
              }}
            /> */}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default EffectiveDate;
