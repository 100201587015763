import React, { useState } from "react";
import classes from "./Assure.module.css";
// import { Row, Col, Form, Label, Input } from "reactstrap";
import { Row, Col, Form, Input, Select, Space } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import moment from "moment";
import { PhoneOutlined } from "@material-ui/icons";
import { checkSelectedGamme } from "../../../utils/utils";
const Assure = ({
  setData,
  isTrueSS,
  isValidSS,
  isValidEmail,
  dataAdhesion,
  setDataAdhesion,
  setAdressePostale,
  adressePostal,
}) => {
  // eslint-disable-next-line
  const [assure, setAssure] = useState(
    SessionStorageService().getEmailProspect()
  );
  const [ville, setVille] = useState(false);
  // eslint-disable-next-line
  const [address, setAddress] = useState(false);
  const genderOptions = [
    { label: "M", value: "M" },
    { label: "Mme", value: "Mme" },
  ];
  const regimeOptions = [
    {
      label: <span style={{ color: "black" }}>Sécurité sociale</span>,
      value: "Régime général",
    },
    {
      label: <span style={{ color: "black" }}>Travailleurs non salariés</span>,
      value: "Travailleurs non salariés",
    },
    {
      label: <span style={{ color: "black" }}>Agricole salarié</span>,
      value: "Agricole salarié",
    },
    {
      label: <span style={{ color: "black" }}>Agricole exploitant</span>,
      value: "Agricole exploitant",
    },
    {
      label: <span style={{ color: "black" }}>Alsace Moselle</span>,
      value: "Régime local",
    },
  ];

  const exerceequaliteOptions = [
    { label: "Salarié", value: "Salarié" },
    { label: "Profession libérale", value: "Profession libérale" },
    { label: "Artisan", value: "Artisan" },
    { label: "Commerçant", value: "Commerçant" },
    { label: "Expoloitant agricole", value: "Expoloitant agricole" },
    { label: "Conjoint collaborateur", value: "Conjoint collaborateur" },
    { label: "Frontalier suisse TNS", value: "Frontalier suisse TNS" },
  ];
  /* const RO = SessionStorageService().getProspect(); */
  /* let tempRegime = "";

  React.useEffect(() => {
    switch (RO?.ro) {
      case '1':
        tempRegime = 'Sécurité sociale';
        break;
      case '2':
        tempRegime = 'TNS';
        break;
      case '3':
        tempRegime = 'Agricole';
        break;
      case '4':
        tempRegime = 'Agricole exploitant';
        break;
      case '5':
        tempRegime = 'Alsace moselle';
        break;
      default:
        tempRegime = 'Sécurité sociale';
        break;
    }
  }, [assure]); */
  React.useEffect(() => {
    let newdata = dataAdhesion;
    newdata["nom_assure||Nom||1"] = assure?.name || "";
    newdata["prenom_assure||Prénom||2"] = assure?.surname || "";
    newdata["mailassure_text"] = assure?.mailassure_text || "";
    newdata["email"] = assure?.mailassure_text || "";
    newdata["mobileassure_text"] = assure?.mobile || "";
    newdata["suffix_mobile_assure"] = assure["suffix_mobile_assure"] || "";
    // newdata["adresse_assure||Adresse||3"] =
    //   SessionStorageService()?.getCustomerInfo()[0]?.address?.fullAddress +
    //   ", " +
    //   SessionStorageService()?.getCustomerInfo()[0]?.address?.locality +
    //   ", " +
    //   SessionStorageService()?.getCustomerInfo()[0]?.address?.zip_code;
    newdata["dn_assure||Date de naissance||3"] = assure?.DN
      ? assure?.DN
      : "01/01/1980";
    newdata["cp_assure||Code postal||4"] =
      SessionStorageService().getCustomerInfo()[0].address.zip_code;
    newdata["ro_assure||Régime||4"] =
      SessionStorageService().getCustomerInfo()[0].job.regime;
    newdata["ville_assure||Ville||5"] =
      SessionStorageService().getCustomerInfo()[0].address.locality || "";
    newdata["num_sec_assure||N° sécurité sodiale||6"] =
      assure["num_sec_assure||N° sécurité sodiale||6"] || "";
    newdata["exerceequalite_assure||Exercée en qualité de||17"] =
      assure["exerceequalite_assure||Exercée en qualité de||17"] || "Salarié";
    newdata["civilite_assure||Civilité||0"] =
      assure["civilite_assure||Civilité||0"] || "M";
    newdata["prefix_mobile_assure"] = assure["prefix_mobile_assure"] || "+33";
    setDataAdhesion(newdata);
    if (dataAdhesion["adresse_assure||Adresse||3"]) {
      setAddress(true);
    }
    if (dataAdhesion["ville_assure||Ville||5"]) {
      setVille(true);
    }

    // eslint-disable-next-line
  }, [assure]);
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    if (name === "nom_assure||Nom||1") {
      prospect["name"] = e.target.value;
    }
    if (name === "prenom_assure||Prénom||2") {
      prospect["surname"] = e.target.value;
    }
    SessionStorageService().setProspect(prospect);
    let key = name;
    setDataAdhesion((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  const handleChangeAdresse = (e, name) => {
    setAdressePostale({
      ...adressePostal,
      [name]: e.target.value,
    });
    let newCustomer = SessionStorageService().getCustomerInfo();
    newCustomer[0].address[name] = e.target.value;
    SessionStorageService().setCustomerInfo(newCustomer);
    // SessionStorageService().setCustomerInfo({
    //   ...SessionStorageService().getCustomerInfo()[0].address,
    //   name:e.target.value,
    // });
  };

  return dataAdhesion["civilite_assure||Civilité||0"] ? (
    <Form layout="vertical" className={classes.form}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Civilité" required>
            <Select
              size="large"
              defaultValue={dataAdhesion["civilite_assure||Civilité||0"] || ""}
              options={genderOptions}
              onSelect={(e) => {
                handleChange(e, "select", "civilite_assure||Civilité||0");
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Nom"} required>
            <Input
              defaultValue={SessionStorageService().getProspect()?.name}
              // defaultValue={dataAdhesion["nom_assure||Nom||1"]}
              size="large"
              onChange={(e) => handleChange(e, "change", "nom_assure||Nom||1")}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Prénom"} required>
            <Input
              size="large"
              onChange={(e) =>
                handleChange(e, "change", "prenom_assure||Prénom||2")
              }
              defaultValue={SessionStorageService().getProspect()?.surname}
              // defaultValue={dataAdhesion["prenom_assure||Prénom||2"]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Email"} required>
            <Input
              size="large"
              defaultValue={dataAdhesion["mailassure_text"]}
              onChange={(e) => {
                handleChange(e, "change", "mailassure_text");
                isValidEmail(e.target.value);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {/* <Input
            disabled={name === "pays"}
            max={maxDate}
            min={minDate}
            // onChange={(e) => onChangeFunction(e, "input")}
            onBlur={(e) => {
              if (onBlur) onSearchFunction(e.target.value, name);
            }}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            placeholder={placeholder}
            value={
              name === "dateEffet"
                ? dayjs().add(1, "day").format("YYYY-MM-DD")
                : defaultValue
            }
            prefix={prefix}
            controls={controls}
            type={type}
            style={{
              backgroundColor:
                name === "dateEffet"
                  ? "#F2FAFF"
                  : name === "pays"
                  ? "#F2F3F4"
                  : "white",
              border:
                name === "dateEffet"
                  ? "2px solid #D2EEFF"
                  : name === "pays"
                  ? " 2px solid #E7E7E7"
                  : "",
              textAlign: name === "dateEffet" ? "center" : "start",
            }}
          /> */}

          <Form.Item label={"Numéro de téléphone"} required>
            <Space.Compact style={{ width: "100%" }}>
              <Input
                size="large"
                className={classes.antdInput}
                // onChange={(e) => {
                //   handleChange(e, "change", "prefix_mobile_assure");
                // }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^+\d]/g, "");
                  handleChange(
                    { ...e, target: { ...e.target, value: numericValue } },
                    "change",
                    "prefix_mobile_assure"
                  );
                }}
                defaultValue={dataAdhesion["prefix_mobile_assure"]}
                style={{ width: "20%" }}
                value={dataAdhesion["prefix_mobile_assure"]}
              />
              <Input
                style={{ width: "80%" }}
                addonAfter={<PhoneOutlined style={{ color: "black" }} />}
                size="large"
                // onChange={(e) => {
                //   handleChange(e, "change", "suffix_mobile_assure");
                // }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, "");
                  handleChange(
                    { ...e, target: { ...e.target, value: numericValue } },
                    "change",
                    "suffix_mobile_assure"
                  );
                }}
                value={dataAdhesion["suffix_mobile_assure"]}
                defaultValue={dataAdhesion["suffix_mobile_assure"]}
              />
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Adresse Postale"} required name="Adresse Postale">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService()?.getCustomerInfo()[0]?.address
                  ?.AdressPostale
              }
              onChange={(e) => {
                handleChangeAdresse(e, "AdressPostale");
              }}
              //   setAdressePostale((prev) => {
              //     return {
              //       ...prev,
              //       AdressPostale: e.target.value,
              //     };
              //   });
              // }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Adresse 2"} name="Adresse 2">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService()?.getCustomerInfo()[0]?.address?.Adresse2
              }
              onChange={(e) => {
                handleChangeAdresse(e, "Adresse2");
                // setAdressePostale((prev) => {
                //       return {
                //         ...prev,
                //         Adresse2: e.target.value,
                //       };
                //     });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Adresse 3"} name="Adresse 3">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService()?.getCustomerInfo()[0]?.address?.Adresse3
              }
              onChange={(e) => {
                handleChangeAdresse(e, "Adresse3");
                // setAdressePostale((prev) => {
                //       return {
                //         ...prev,
                //         Adresse3: e.target.value,
                //       };
                //     });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Code postal"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              onChange={(e) =>
                handleChange(e, "change", "cp_assure||Code postal||4")
              }
              defaultValue={dataAdhesion["cp_assure||Code postal||4"]}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Ville"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={dataAdhesion["ville_assure||Ville||5"]}
              onChange={(e) =>
                handleChange(e, "change", "ville_assure||Ville||5")
              }
              disabled={ville}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Date de naissance"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={moment(
                SessionStorageService()?.getCustomerInfo()[0]?.birth_info?.date,
                "DD/MM/YYYY"
              ).format("DD/MM/YYYY")}
              onChange={(e) => handleChange(e, "change", "")}
              disabled
            />
          </Form.Item>
        </Col>
        {checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME) && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Lieu de naissance"} required>
              <Input
                size="large"
                style={{ color: "black" }}
                onChange={(e) => {
                  handleChange(e, "change", "lieu_naissance_principal");
                }}
                defaultValue={
                  SessionStorageService().getProspect()[
                    "lieu_naissance_principal"
                  ]
                }
              />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"N° sécurité sociale"} required>
            <Input
              size="large"
              onChange={(e) => {
                handleChange(
                  e,
                  "change",
                  "num_sec_assure||N° sécurité sodiale||6"
                );
                isValidSS(e.target.value);
                isTrueSS(e.target.value);
              }}
              defaultValue={
                dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
              }
            />
          </Form.Item>
        </Col>
        {checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME) && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Code organisme"} required>
              <Input
                size="large"
                onChange={(e) => {
                  handleChange(e, "change", "code_organisme_principal");
                }}
                // minLength={0}
                // maxLength={9}
                defaultValue={SessionStorageService().getProspect()[
                  "code_organisme_principal"
                ]}
              />
            </Form.Item>
          </Col>
        )}

        {/* Unknown */}

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Régime"} required>
            <Select
              size="large"
              options={regimeOptions}
              onSelect={(e) => handleChange(e, "change", "")}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].job.regime
              }
              disabled
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Exercée en qualité de"} required>
            <Select
              size="large"
              options={exerceequaliteOptions}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "exerceequalite_assure||Exercée en qualité de||17"
                )
              }
              defaultValue={
                dataAdhesion["exerceequalite_assure||Exercée en qualité de||17"]
              }
            />
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  ) : (
    <></>
  );
};

export default Assure;
