import React from "react";
import { Row, Col } from "reactstrap";
import { Button, notification } from "antd";
import classes from "./Comparateur.module.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Budget from "./Budget/Budget";
import Propositions from "./Propositions/Propositions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import SessionStorageService from "../../utils/sessionStorageService";

import InformationsComplementaires from "./InformationsComplementaires/InformationsComplementaires";
import Identite from "./Identite/Identite";
import axios from "../../utils/axios";
import GlobalContext from "../../contexts/GlobalContext";
import dayjs from "dayjs";
import { alertMessage } from "../../utils/alertFunction";
import { useState } from "react";
const Comparateur = () => {
  const [stage, setStage] = React.useState(
    sessionStorage.getItem("reset") === "1" ? 3 : 0
  );
  const { dateEffet, setDateEffet, dataAdhesion } =
    React.useContext(GlobalContext);
  const [validFamille, setValidFamille] = useState(true);
  // const [opendn, setOpendn] = React.useState(false);
  // const [openDnInf, setOpenDnInf] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [openNom, setOpenNom] = React.useState(false);
  // const [openPrenom, setOpenPrenom] = React.useState(false);
  // const [openf, setOpenf] = React.useState(false);
  // const [openDateEffet, setOpenDateEffet] = React.useState(false);

  /*const handleRequirementNom = () => {
    setOpenNom(true);
  };
  const handleRequirementPrenom = () => {
    setOpenPrenom(true);
  };*/

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  //   setOpendn(false);
  //   setOpenDnInf(false);
  // };
  // const handleCloseNom = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpenNom(false);
  // };
  // const handleClosePrenom = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpenPrenom(false);
  // };

  const [groupeGaranties, setGroupeGaranties] = React.useState([]);
  const [grpGarantieOptique, setGrpGarantieOptique] = React.useState({});
  const [customerInformation, setCostumerInformation] = React.useState(
    SessionStorageService()?.getCustomerInfo() || [
      {
        number: 476820,
        primary_subscriber: true,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: SessionStorageService().getProspect()?.DN
            ? SessionStorageService().getProspect()?.DN
            : "",

          // "01/01/1940" ||
          // "01/01/" + SessionStorageService().getProspect()?.DN,
        },
        address: {
          zip_code: "" || SessionStorageService().getProspect()?.CP,
        },
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
        },
        relationship: "primary subscriber",
        relationship_rank: 1,
      },
    ]
  );

  React.useEffect(() => {
    SessionStorageService().setCustomerInfo(customerInformation);
  }, [customerInformation]);
  // const [messageError, setMessageError] = React.useState("");
  const [garantiesFilterValues, setGarantiesFilterValues] = React.useState([
    {
      name: "ex23",
      value: "0",
    },
    {
      name: "ex22",
      value: "0",
    },
    { name: "or1", value: "0" },
    { name: "ex34", value: "0" },
    { name: "ex17", value: "0" },
    {
      name: "ex41",
      value: "0",
    },
    {
      name: "ex42",
      value: "0",
    },
    {
      name: "ex43",
      value: "0",
    },
    {
      name: "ex44",
      value: "0",
    },
    {
      name: "ex47",
      value: "0",
    },
    {
      name: "ex31",
      value: "0",
    },
    {
      name: "ex32",
      value: "0",
    },
    {
      name: "ex33",
      value: "0",
    },
    {
      name: "ex11",
      value: "0",
    },
    {
      name: "ex12",
      value: "0",
    },
    {
      name: "ex16",
      value: "0",
    },
    {
      name: "ex19",
      value: "0",
    },
    {
      name: "pb",
      value: "0",
    },
    {
      name: "pbo",
      value: "0",
    },
    {
      name: "pnr",
      value: "0",
    },
    { name: "pbob", value: "0" },
  ]);
  const [garantiesSouscripteur, setGarantiesSouscripteur] = React.useState({
    Êtes_vous_bénéficiaire_de_la_CSS: false,
    Bénéficiez_vous_une_aide_à_la_complémentaire_santé: false,
    Faites_vous_objet_une_mesure_de_Curatelle_ou_de_Tutelle: false,
  });
  // const [messageFamille,setMessageFamille]=useState(false)
  const steps = [
    "Identité",
    "Informations complémentaires",
    "Budget et besoin",
    "Proposition",
  ];
  function isValidNom() {
    let name = SessionStorageService().getProspect().name;

    var regex = new RegExp(/^[a-zA-Z]{1,30}[' ']{0,1}[a-zA-Z]{1,30}$/);
    let validNom = regex.test(name);

    return validNom;
  }
  function isValidPrenom() {
    let name = SessionStorageService().getProspect().surname;

    var regex = new RegExp(/^[a-zA-Z]{1,30}[' ']{0,1}[a-zA-Z]{1,30}$/);
    let validPrenom = regex.test(name);

    return validPrenom;
  }
  const getYears = (years) => {
    const effetDate = moment(
      SessionStorageService().getProspect()?.dateEffet,
      "DD-MM-YYYY"
    );
    const yearEffet = dayjs(effetDate).year();
    return yearEffet - years;
  };

  // const alertMessage = (message) => {
  //   notification.warning({
  //     message: "Warning",
  //     description: message,
  //   });
  // };
  const nextHandler = () => {
    if (stage < steps.length - 1) {
      let regime = SessionStorageService().getProspect().ro;
      let dn = SessionStorageService().getProspect().DN;
      const { locality, zip_code } =
        SessionStorageService().getCustomerInfo()[0]?.address;
      let informationsAboutCustomer = customerInformation;

      if (
        garantiesSouscripteur["Êtes_vous_bénéficiaire_de_la_CSS"] === false &&
        garantiesSouscripteur[
          "Bénéficiez_vous_une_aide_à_la_complémentaire_santé"
        ] === false &&
        garantiesSouscripteur[
          "Faites_vous_objet_une_mesure_de_Curatelle_ou_de_Tutelle"
        ] === false
      ) {
        if (
          regime &&
          locality &&
          zip_code &&
          regime !== "" &&
          locality !== "" &&
          zip_code !== "" &&
          dateEffet !== "" &&
          dateEffet !== "Invalid Date"
        ) {
          let prospect = informationsAboutCustomer.find(
            (e) => e.relationship_rank === 1
          );
          // let dateAfter = moment(
          //   SessionStorageService().getProspect()?.dateEffet,
          //   "DD-MM-YYYY"
          // ).isAfter(moment().add(120, "day"), "day");
          // let dateBefore = moment(
          //   SessionStorageService().getProspect()?.dateEffet,
          //   "DD-MM-YYYY"
          // ).isBefore(moment().startOf("day"), "day");

          // const yearEffectiveDate = moment(
          //   SessionStorageService().getProspect()?.dateEffet,
          //   "DD-MM-YYYY"
          // )?.year();
          // const yearBirthDate = moment(
          //   prospect.birth_info?.date,
          //   "DD-MM-YYYY"
          // )?.year();
          // let diffY = yearEffectiveDate - yearBirthDate;
          if (!dn || dn === "Invalid Date" || dateEffet === "Invalid Date") {
            //  handleRequirements();
            alertMessage(
              "Veuillez remplir tous les champs obligatoires.",
              "error"
            );
          } else if (dn && dn !== "") {
            let validFamille = true;

if (informationsAboutCustomer.length > 1) {
  informationsAboutCustomer?.forEach((val, index) => {
    if (val.relationship_rank !== 1) {
      if (!val.birth_info.date || val.birth_info.date === "Invalid Date") {
        validFamille = false;
      }
    }
  });

              if (!validFamille) {
                alertMessage(
                  "Afin de poursuivre la souscription, veuillez compléter toutes les informations requises pour les membres de la famille.",
                  "error"
                );
              }
              if (
                dataAdhesion[
                  "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                ] === "Oui" &&
                !dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"] &&
                stage === 1
              ) {
                alertMessage(
                  "Afin de poursuivre la souscription, veuillez remplir le montant de votre cotisation mensuelle.",
                  "error"
                );
              } else {
                if (validFamille) {
                  notification.destroy();

                  setStage(stage + 1);
                }
              }
            } else {
              if (
                dataAdhesion[
                  "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                ] === "Oui" &&
                !dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"] &&
                stage === 1
              ) {
                alertMessage(
                  "Afin de poursuivre la souscription, veuillez remplir le montant de votre cotisation mensuelle.",
                  "error"
                );
              } else {
                notification.destroy();

                setStage(stage + 1);
              }
            }
          }
        } else {
          alertMessage(
            "Veuillez remplir tous les champs obligatoires.",
            "error"
          );
        }
      } else {
        // if (garantiesSouscripteur["Êtes_vous_bénéficiaire_de_la_CSS"] === true)
        //   alertMessage(
        //     "Vous n'êtes pas éligible à cette assurance en tant que bénéficiaire de la CSS.",
        //     "error"
        //   );
        // if (
        //   garantiesSouscripteur[
        //     "Bénéficiez_vous_une_aide_à_la_complémentaire_santé"
        //   ] === true
        // )
        //   alertMessage(
        //     "Vous ne pouvez pas souscrire à cette assurance en raison de votre participation à un programme d'Aide à la Complémentaire Santé.",
        //     "error"
        //   );
        // if (
        //   garantiesSouscripteur[
        //     "Faites_vous_objet_une_mesure_de_Curatelle_ou_de_Tutelle"
        //   ] === true
        // )
        alertMessage(
          "Nous sommes désolés, le produit n'est pas adapté à vos besoins.",
          ""
        );
      }
    }
  };

  const prevHandler = () => {
    if (stage > 0) {
      notification.destroy();
      setStage(stage - 1);
      // window.scrollTo(0, 0);*
    }
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [stage]);

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/groupe_garantie/get_garantie_by_groupe_and_type/10`
      )
      .then((res) => {
        let temp = [...res.data];
        let a = temp.find((i) => i.nom_groupe === "Soins courants");
        let b = temp.find((i) => i.nom_groupe === "Pharmacie");
        let x = a.list.concat(b.list);
        a.list = x;
        setGroupeGaranties(temp);
        const garantOptique = temp?.find(
          (item) => item?.nom_groupe === "Optique"
        );
        const garantieOptiqueFilter = garantOptique?.list?.filter(
          (element) => element.id !== "ex322" && element.id !== "ex333"
        );
        garantOptique.list = garantieOptiqueFilter;
        setGrpGarantieOptique(garantOptique);
      })
      .catch((err) => {
        console.log(err);
      });
    sessionStorage.setItem("reset", "0");

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.container}>
      <Row className={classes.titleRow}>
        <Col className={classes.titleCol}>
          <h3 className={classes.title}>Parcours de conseil Niveau 2</h3>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xs="12" lg="10">
          <Box
            sx={{
              width: "100%",
              paddingInline: "2rem",
            }}
          >
            <Stepper activeStep={stage} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <br />
            <div className={classes.stageContainer}>
              {stage === 0 && (
                <Identite
                  garantiesSouscripteur={garantiesSouscripteur}
                  setGarantiesSouscripteur={setGarantiesSouscripteur}
                  customerInformation={customerInformation}
                  setCostumerInformation={setCostumerInformation}
                  isValidNom={isValidNom}
                  isValidPrenom={isValidPrenom}
                  getYears={getYears}
                />
              )}
              {stage === 1 && <InformationsComplementaires />}
              {stage === 2 && (
                <Budget
                  grpGarantieOptique={grpGarantieOptique}
                  dateEffet={dateEffet}
                  setDateEffet={setDateEffet}
                  garantiesFilterValues={garantiesFilterValues}
                  setGarantiesFilterValues={setGarantiesFilterValues}
                  groupeGaranties={groupeGaranties}
                />
              )}
              {stage === 3 && (
                <Propositions
                  dateEffet={dateEffet}
                  garantiesFilterValues={garantiesFilterValues}
                  customerInformation={customerInformation}
                  setCostumerInformation={setCostumerInformation}
                />
              )}
            </div>
            <div className={classes.stepsBtnContainer}>
              <Button
                disabled={stage === 0}
                type="primary"
                size="large"
                onClick={prevHandler}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "0.5rem" }}
                  icon={faArrowLeft}
                />
                Précédent
              </Button>

              <Button
                // disabled={stage === 3}
                style={{ display: stage === 3 ? "none" : "block" }}
                size="large"
                type="primary"
                onClick={nextHandler}
              >
                Suivant
                <FontAwesomeIcon
                  style={{ marginLeft: "0.5rem" }}
                  icon={faArrowRight}
                />
              </Button>
            </div>
          </Box>
        </Col>
      </Row>
      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Veuillez remplir tous les champs obligatoires.
        </Alert>
      </Snackbar>
      <Snackbar open={openNom} autoHideDuration={3000} onClose={handleCloseNom}>
        <Alert onClose={handleCloseNom} severity="error" sx={{ width: "100%" }}>
          veuillez verifier votre nom
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPrenom}
        autoHideDuration={3000}
        onClose={handleClosePrenom}
      >
        <Alert
          onClose={handleClosePrenom}
          severity="error"
          sx={{ width: "100%" }}
        >
          veuillez verifier votre prénom
        </Alert>
      </Snackbar>
      <Snackbar open={opendn} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {messageError}
        </Alert>
      </Snackbar>
      <Snackbar open={openDnInf} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Veuillez noter que l'âge de souscription pour cette assurance est
          compris entre 18 ans et 80 ans inclus.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openf}
        autoHideDuration={3000}
        onClose={() => setOpenf(false)}
      >
        <Alert
          onClose={() => setOpenf(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez remplir tous les champs relatifs aux membres de la famille.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDateEffet}
        autoHideDuration={3000}
        onClose={() => setDateEffet(false)}
      >
        <Alert
          onClose={() => setDateEffet(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez vérifier la date d'effet
        </Alert>
      </Snackbar> */}
    </div>
  );
};

export default Comparateur;
