import React from "react";
import classes from "../InformationsCard/InformationsCard.module.css";
// import { Row, Col, Form, Label, Input } from "reactstrap";
import { Row, Col, Form, Select, InputNumber } from "antd";
import GlobalContext from "../../../../contexts/GlobalContext";
import SessionStorageService from "../../../../utils/sessionStorageService";
// import SessionStorageService from "../../../../utils/sessionStorageService";

const InformationsCard = () => {
  const [form] = Form.useForm();
  const { dataAdhesion, setDataAdhesion, setSharedFurtherInformation } =
    React.useContext(GlobalContext);
  const answers = [
    { value: "Oui", label: "Oui" },
    { value: "Non", label: "Non" },
  ];
  React.useEffect(() => {
    setDataAdhesion({
      ...dataAdhesion,
      "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2":
        dataAdhesion[
          "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
        ] || "Non",

      "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3":
        dataAdhesion[
          "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
        ] || "Non",

      "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5":
        dataAdhesion[
          "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
        ] || "Non",

      "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6":
        dataAdhesion[
          "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
        ] || "Non",

      "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7":
        dataAdhesion[
          "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
        ] || "Non",

      "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8":
        dataAdhesion[
          "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
        ] || "Non",

      "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9":
        dataAdhesion[
          "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
        ] || "Non",
    });
  }, []);
  React.useEffect(() => {
    setSharedFurtherInformation({
      "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5":
        dataAdhesion[
          "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
        ],
      "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6":
        dataAdhesion[
          "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
        ],
      "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7":
        dataAdhesion[
          "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
        ],
      "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8":
        dataAdhesion[
          "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
        ],
      "meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15":
        dataAdhesion[
          "meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15"
        ],
      "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16":
        dataAdhesion[
          "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
        ],
      "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17":
        dataAdhesion[
          "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17"
        ],
      "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9":
        dataAdhesion[
          "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
        ],
      "budegtmensuel_garantie||Si oui: lequel ?||10":
        dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"],
    });
  }, [dataAdhesion]);
  const handleChange = (name, value) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = value;
    SessionStorageService().setProspect(prospect);
    setDataAdhesion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    dataAdhesion[
      "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
    ] && (
      <Form layout="vertical" className={classes.form} form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={
                "Bénéficiez-vous actuellement d'une complémentaire santé ou d'une mutuelle ?"
              }
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={
                "S'agit-il d'un contrat complémentaire santé de votre employeur ?"
              }
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={
                "Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel(le) ?"
              }
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7",
                    option
                  )
                }
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={
                "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions, à votre assureur ou à votre mutuelle actuel(le) ?"
              }
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Vous souhaitez mettre en avant le meilleur prix ?"}
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={
                "Vous souhaitez mettre en avant le meilleur rapport qualité prix ?"
              }
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Vous souhaitez mettre en avant la meilleure couverture ?"}
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Avez-vous réfléchi à un budget mensuel de cotisation ?"}
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                size="large"
                defaultValue={
                  dataAdhesion[
                    "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                  ]
                }
                options={answers}
                onSelect={(option) =>
                  handleChange(
                    "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9",
                    option
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Si oui : lequel (montant en euro) ?"}
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <InputNumber
                controls={false}
                disabled={
                  dataAdhesion[
                    "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                  ] === "Non"
                }
                style={{ width: "100%" }}
                type="number"
                size="large"
                defaultValue={
                  dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"]
                }
                onChange={(e) =>
                  handleChange(
                    "budegtmensuel_garantie||Si oui: lequel ?||10",
                    e
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  );
};

export default InformationsCard;
