import "./App.css";
import AppContext from "./contexts/AppContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Comparateur from "./pages/Comparateur/Comparateur";
import Layout from "./components/Layout/Layout";
import TableauComparatif from "./pages/Comparateur/TableauComparatif/TableauComparatif";
import LayoutAdhesion from "./components/LayoutAdhesion/LayoutAdhesion";

import Tarificateur from "./pages/Tarificateur/Tarificateur";
import LayoutTarificateur from "./components/LayoutTarificateur/LayoutTarificateur";
import DevisComparatif from "./pages/Comparateur/TableauComparatif/DevisComparatif/DevisComparatif";
import DevisTarificateur from "./pages/Comparateur/TableauComparatif/DevisTarificateur/DevisTarificateur";
import DevisUrl from "./pages/Comparateur/TableauComparatif/DevisComparatif/DevisURL";
import Adhesion from "./pages/Adhesion/Adhesion";
import DevisTarifURL from "./pages/Comparateur/TableauComparatif/DevisTarificateur/DevisTarifURL";
import Success from "./pages/Adhesion/Success/Success";
import React from "react";
import NotFound from "./pages/NotFound/NotFound";
import Reprise from "./utils/Reprise";
//a
import * as Sentry from "@sentry/react";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
// function FallbackComponent() {
//   return <div>Oups, une erreur s'est produite!</div>;
// }
function App() {
  const [clearing, setClearing] = React.useState(false);
  React.useEffect(() => {
    if (
      window.location.pathname === "/" &&
      sessionStorage.getItem("reset") !== "1"
    ) {
      sessionStorage.clear();
    }
    setClearing(true);
  }, []);
  if (clearing) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
        <ConfigProvider locale={frFR}>
        <AppContext>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Comparateur />} />
                {/* <Route path="/devisTarificateur" element={<DevisTarificateur />} /> */}
                <Route
                  path="/tableauComparatif"
                  element={<TableauComparatif />}
                />
                {/* <Route path="/devis" element={<DevisComparatif />} /> */}

                {/* <Route path="/devisComparatif/:id" element={<DevisUrl />} /> */}
              </Route>
              <Route path="/reprise/:id" element={<Reprise />} />
              <Route path="/*" element={<NotFound />} />
              <Route path="/devis" element={<DevisComparatif />} />
              <Route path="/devisComparatif/:id" element={<DevisUrl />} />
              <Route path="/tarificateur" element={<LayoutTarificateur />}>
                <Route path="/tarificateur" element={<Tarificateur />} />
                <Route
                  path="/tarificateur/:param_id"
                  element={<Tarificateur />}
                />
                {/* <Route path="/tarificateur/devisTarif/:id" element={<DevisTarifURL />} /> */}
              </Route>
              <Route
                path="/tarificateur/devisTarif/:id"
                element={<DevisTarifURL />}
              />
              <Route
                path="/devisTarificateur"
                element={<DevisTarificateur />}
              />
              <Route path="/adhesion" element={<LayoutAdhesion />}>
                <Route path="/adhesion" element={<Adhesion />} />
                <Route path="/adhesion/success" element={<Success />} />
              </Route>
              <Route path="/error" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </AppContext>
        </ConfigProvider>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
