import React from "react";
import classes from "./Garantiesoptionnels.module.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Row, Col } from "react-bootstrap";
import GlobalContext from "../../../contexts/GlobalContext";
import SessionStorageService from "../../../utils/sessionStorageService";

function GarantiesOptionnels({ disabledOption, prospect }) {
  const { optionHorsSante, setOptionHorsSante } =
    React.useContext(GlobalContext);
  return (
    <div className={classes.garanties}>
      {!disabledOption ? (
        <Row className={classes.GarantiesContainer}>
          <h4> Garanties en inclusion hors Aésio</h4>
          <Col>
            <FormControlLabel
              control={
                <Switch
                  // onChange={() => {
                  //   let temp = [...optionHorsSante];
                  //   let f = temp.find((value) => value === "Assistance");
                  //   if (!f) {
                  //     temp.push("Assistance");
                  //   } else {
                  //     temp.indexOf(f) > -1 && temp.splice(temp.indexOf(f), 1);
                  //   }
                  //   setOptionHorsSante(temp);
                  //   SessionStorageService().setProspect({
                  //     ...SessionStorageService().getProspect(),
                  //     optionHorsSante: temp,
                  //   });
                  // }}
                  checked={
                    prospect.optionHorsSante?.includes("Assistance") ||
                    optionHorsSante?.includes("Assistance")
                  }
                  color="success"
                />
              }
              label="Assistance"
            />
          </Col>
          <Col>
            <FormControlLabel
              control={
                <Switch
                  // onChange={() => {
                  //   let temp = [...optionHorsSante];
                  //   let f = temp.find(
                  //     (value) => value === "Protection juridique"
                  //   );
                  //   if (!f) {
                  //     temp.push("Protection juridique");
                  //   } else {
                  //     temp.indexOf(f) > -1 && temp.splice(temp.indexOf(f), 1);
                  //   }
                  //   setOptionHorsSante(temp);
                  //   SessionStorageService().setProspect({
                  //     ...SessionStorageService().getProspect(),
                  //     optionHorsSante: temp,
                  //   });
                  // }}
                  checked={
                    prospect.optionHorsSante?.includes(
                      "Protection juridique"
                    ) || optionHorsSante?.includes("Protection juridique")
                  }
                  color="success"
                />
              }
              label="Protection Juridique"
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    onChange={() => {
                      let temp = [...optionHorsSante];
                      let f = temp.find((value) => value === "Assistance");
                      if (!f) {
                        temp.push("Assistance");
                      } else {
                        temp.indexOf(f) > -1 && temp.splice(temp.indexOf(f), 1);
                      }
                      setOptionHorsSante(temp);
                      SessionStorageService().setProspect({
                        ...SessionStorageService().getProspect(),
                        optionHorsSante: temp,
                      });
                    }}
                    checked={
                      prospect.optionHorsSante?.includes("Assistance") ||
                      optionHorsSante?.includes("Assistance")
                    }
                    color="success"
                  />
                }
                label="Assistance"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    onChange={() => {
                      let temp = [...optionHorsSante];
                      let f = temp.find(
                        (value) => value === "Protection juridique"
                      );
                      if (!f) {
                        temp.push("Protection juridique");
                      } else {
                        temp.indexOf(f) > -1 && temp.splice(temp.indexOf(f), 1);
                      }
                      setOptionHorsSante(temp);
                      SessionStorageService().setProspect({
                        ...SessionStorageService().getProspect(),
                        optionHorsSante: temp,
                      });
                    }}
                    checked={
                      prospect.optionHorsSante?.includes(
                        "Protection juridique"
                      ) || optionHorsSante?.includes("Protection juridique")
                    }
                    color="success"
                  />
                }
                label="Protection Juridique"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default GarantiesOptionnels;
