import React from "react";
import classes from "./Tarificateur.module.css";
import { Col, Form, Row, Select, notification } from "antd";
import { Button } from "antd";
import GarantiesModal from "./GarantiesModal/GarantiesModal";
import UserInfo from "./Userinfo/Userinfo";
import GroupMember from "../Tarificateur/GroupMember/GroupMember";
import TotalCotisation from "../Tarificateur/TotalCotisation/TotalCotisation";
import { useNavigate } from "react-router-dom";
import SessionStorageService from "../../utils/sessionStorageService";
import axios from "../../utils/axios";
import GlobalContext from "../../contexts/GlobalContext";
import moment from "moment/moment";
import GarantiesOptionnels from "./Garantiesoptionnels/Garantiesoptionnels";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { alertMessage } from "../../utils/alertFunction";
import FraisTarificateur from "../../components/FraisTarificateur/FraisTarificateur";
import { ErrorBoundary } from "@sentry/react";
import { checkSelectedGamme } from "../../utils/utils";

const Tarificateur = () => {
  const {
    optionHorsSante,
    tarifFinal,
    setTarifFinal,
    // eslint-disable-next-line no-unused-vars
    options,
    setCots,
    // eslint-disable-next-line no-unused-vars
    seChangedProduct,
    changedProduct,
    setTarifLoader,
    repriseData,
    fraisLie,
    fraisCourtage,
    setFraisCourtage,
    setMalo,
    setFraisAssociatif,
    setFraisQuittancement,
    setFraisPj,
    setAlturgence,
    calculated,
    setCalculated,
    fraisAesio,
    setFraisAesio,
    cotisationInitialeReprise
  } = React.useContext(GlobalContext);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const [calculating, setCalculating] = React.useState(true);
  const frais_dossier_aesio = [
    { label: "0 €", value: 0 },
    { label: "10 €", value: 10 },
    { label: "20 €", value: 20 },
    { label: "30 €", value: 30 },
    { label: "40 €", value: 40 },
  ];
  //const ap = JSON.parse(sessionStorage.getItem("ap"));
  // const params = useLocation();
  const options_frais_courtage = [
    { label: "0 €", value: 0 },
    { label: "2 €", value: 2 },
    { label: "4 €", value: 4 },
    { label: "7 €", value: 7 },
    { label: "10 €", value: 10 },
  ];
  const {
    tarif,
    tarifs,
    proposition,
    garantiesFilterValues,
    prospect,
    customerInfo,
  } = repriseData;

  const [baseTarif, setBaseTarif] = React.useState(tarif);
  const containerDiv = React.createRef(null);
  const navigate = useNavigate();
  const param_id = new URLSearchParams(window.location.search).get("ref");
  //eslint-disable-next-line
  const [modal, setModal] = React.useState(false);
  const dateEffet = SessionStorageService().getProspect()?.dateEffet
    ? SessionStorageService().getProspect()?.dateEffet
    : moment().add(1, "days").format("DD-MM-YYYY");

  const toggle = () => {
    setModal(!modal);
  };

  const createOpp = (navigateTo) => {
    let customerInformation = SessionStorageService().getCustomerInfo();
    let tempProspect = SessionStorageService().getProspect();
    let info_specifiques_campagnes = { budget: "" };
    garantiesFilterValues?.forEach((element, index) => {
      info_specifiques_campagnes[element.name] = element.value;
    });

    let info_specifiques_ligne_produit = {
      Ro:
        customerInformation.find(
          (el) => el.relationship === "primary subscriber"
        )?.job?.regime || null,
      Ro_conjoint:
        customerInformation.find((el) => el.relationship === "married")?.job
          .regime || null,
      annee_naissance:
        moment(
          customerInformation.find(
            (el) => el.relationship === "primary subscriber"
          )?.birth_info?.date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD") || null,
      annee_naissance_conjoint:
        customerInformation.find((el) => el.relationship === "married")
          ?.birth_info?.date || null,
      annee_naissance_enfant1:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 11
        )?.birth_info?.date || null,
      annee_naissance_enfant2:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 12
        )?.birth_info?.date || null,
      annee_naissance_enfant3:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 13
        )?.birth_info?.date || null,
      annee_naissance_enfant4:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 14
        )?.birth_info?.date || null,
      annee_naissance_enfant5:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 15
        )?.birth_info?.date || null,
      annee_naissance_enfant6:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 16
        )?.birth_info?.date || null,
      ro_enfant1:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 11
        )?.job?.regime || null,
      ro_enfant2:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 12
        )?.job?.regime || null,
      ro_enfant3:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 13
        )?.job?.regime || null,
      ro_enfant4:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 14
        )?.job?.regime || null,
      ro_enfant5:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 15
        )?.job?.regime || null,
      ro_enfant6:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 16
        )?.job.regime || null,
      situation_pro: tempProspect?.situation,
      NumeroSecuriteSocial: null,
      NumeroSecuriteSocialConjoint: null,
      NumeroSecuriteSocialEnfant1: null,
      NumeroSecuriteSocialEnfant2: null,
      NumeroSecuriteSocialEnfant3: null,
      NumeroSecuriteSocialEnfant4: null,
      NumeroSecuriteSocialEnfant5: null,
      eSignRef: null,
      YOUSIGN_URL: "",
    };
    var tab = [];
    if (customerInformation.length > 1) {
      customerInformation
        .filter((element) => element.relationship_rank >= 2)
        .forEach((element) => {
          tab = [
            ...tab,
            {
              name: "inconnu",
              surname: "inconnu",
              dn: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              id: "",
              id_opp: "",
              subs_tag:
                element?.relationship_rank === 2
                  ? "Conjoint"
                  : "Enfant" + (element?.relationship_rank - 10),
              campagne_id: "",
              post: "",
              interlocuteur: "",
              ville:
                SessionStorageService().getCustomerInfo()[0]?.address?.locality,
              // situation: element?.relationship === "married" ? "married" : "",
              situation: element?.job?.situation_pro,
              users_nom: "",
              users_prenom: "",
              adresse_mail: "",
              civilite: "",
              prospects_civ: "",

              prospects_situation: "",
              nb_enfants: "",
              adresses_adresse: "",
              streetNumber: "",
              CP: element?.address?.zip_code,
              streetName: "",
              adresses_ville: "",
              complement_adr: "",
              tel: "",
              tel2: "",
              mobile: "",
              first_campagne: "",
              description: "",
              etat: "",
              lat: "",
              latitude: "",
              longitude: "",
              commercieux_id: "",
              commentaire: "",
              geom: "",
              tags: [],
              lng: "",
              date_naiss: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              DN: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              collab: "",
              id_pros: "",
              id_camp: "",
              coment_plus: "",
              code: "",
              rue: "",
              numero: "",
              complement: "",
              mail: "",
              nb_enfant: "",
              infcomp: "",
              relation:
                element.relationship === "married" ? "Conjoint" : "Descendant",
              form: {
                data: {
                  rang: element?.relationship_rank,
                  sec:
                    element?.social_security_number +
                    element?.social_security_number_link,
                  ro: element?.job?.regime,
                },
              },
              pays: "",
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: "",
                titulaire_compte: "",
                iban: "",
                bic_swift: "",
                type_iban: "",
                nom_banque: "",
              },
              prospects_id: "",
            },
          ];
        });
    }

    let offre = {
      info_specifiques_campagnes: info_specifiques_campagnes,
      info_specifiques_ligne_produit: info_specifiques_ligne_produit,
      prospects: {
        listProspects: tab,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: customerInformation[0]?.address?.locality,
          situation: SessionStorageService().getProspect()?.situation,
          adresse_mail: null,
          civilite: null,
          name: tempProspect?.name,
          surname: tempProspect?.surname,
          DN: moment(
            SessionStorageService().getProspect()?.DN,
            "yyyy-MM-DD"
          ).format("YYYY-MM-DD"),
          nb_enfants:
            customerInformation[1]?.relationship === "married"
              ? customerInformation.length - 2
              : customerInformation.length - 1,
          form: {
            data: {
              rang: customerInformation[0]?.relationship_rank,
              sec:
                customerInformation[0]?.social_security_number +
                customerInformation[0]?.social_security_number_link,
              ro: customerInformation[0]?.job?.regime,
            },
          },
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: customerInformation[0]?.address?.zip_code,
          streetName: null,
          complement_adr: null,
          tel: null,
          tel2: null,
          mobile: null,
          description: null,
          relation: "principal",
          subs_tag: "Prospect",
        },
      },
    };

    sessionStorage.setItem("addOffre", JSON.stringify(offre));
    axios
      .post(`${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/offre/add`, offre, {
        headers: {
          idSession: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        SessionStorageService().setId(res.data.id_opportunite_md5);
        SessionStorageService().setIdProspect(res.data.id_prospect_md5);
        sessionStorage.setItem(
          "_subs_tags",
          JSON.stringify(res.data?._subs_tags)
        );

        if (navigateTo === "adhesion")
          navigate("/adhesion", {
            state: {
              proposition: changedProduct ? changedProduct : proposition,
              optionHorsSante,
            },
          });
        if (navigateTo === "devis") {
          setOpenbackdrop(false);
          let apiKey = window.location.search;
          navigate(`/devisTarificateur${apiKey}`, {
            state: {
              proposition: changedProduct ? changedProduct : proposition,
              baseTarif: baseTarif,
              param_id: param_id,
              tarif: tarifFinal,
            },
          });
        }
      })
      .catch((err) => {
        setOpenbackdrop(false);
        console.log(err);
      });
  };

  // const CopyURLHandler = () => {
  //   // const idSessionURL = Date.now() + SessionStorageService().getId();
  //   // sessionStorage.setItem("idUrl", idSessionURL);
  //   // //copy url to clipboard
  //   // axios.post(
  //   //   `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
  //   //   {
  //   //     id: idSessionURL,
  //   //     objet: {
  //   //       prospect: SessionStorageService().getUserInfo(),
  //   //       customerInfo: SessionStorageService().getCustomerInfo(),
  //   //       baseTarif: baseTarif,
  //   //       tarifs: tarifs,
  //   //       tarifFinal: tarifFinal,
  //   //       proposition: proposition,
  //   //       gerneratedDate: Date.now(),
  //   //       optionHorsSante: optionHorsSante,
  //   //       ap: ap,
  //   //     },
  //   //   }
  //   // );

  //   // const el = document.createElement("textarea");
  //   // el.value = `${window.location.href}/${idSessionURL}`;
  //   // document.body.appendChild(el);
  //   // el.select();
  //   // document.execCommand("copy");
  //   // document.body.removeChild(el);
  //   // window.open(el.value + `/?partnership_id=${id}`, "_blank");
  //   window.open(window.location);
  // };

  // React.useEffect(() => {
  //   setCalculated(false)
  //   getNewTarif(sessionStorage.getItem("selectedGamme") ||
  //   proposition?.produit.produit_sante.game,"1");
  // }, [fraisLie, fraisAesio, fraisCourtage]);

  const getNewTarif = (gamme, option) => {
    if (!calculated) {
      setCalculating(true);
      axios
        .post(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/prod_garanties/get_produit_details_v2`,
          {
            game: gamme,
            option: option,
          },
          {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          seChangedProduct(res.data.produit[0]);
          sessionStorage.setItem(
            "product",
            JSON.stringify(res.data.produit[0])
          );
          // fetch(
          //   `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_mass_pricing`,
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //       idSession: localStorage.getItem('token'),

          //     },
          //     body: JSON.stringify({
          //       business_introducer: {
          //         login: "Z04",
          //       },
          //       third_app: {
          //         name: "ASolutions",
          //         id_external: 476820,
          //       },
          //       customer_information: SessionStorageService().getCustomerInfo(),
          //       contract_information: {
          //         effective_date: moment(dateEffet, "DD-MM-YYYY").format(
          //           "DD/MM/YYYY"
          //         ),
          //         mode_splitting: "Mensuel",
          //       },
          //       products: {
          //         0: Array(SessionStorageService().getCustomerInfo().length).fill(
          //           [
          //             parseInt(
          //               res.data?.produit[0]?.produit?.produit_sante.cotation_id
          //             ),
          //           ]
          //         ),
          //       },
          //     }),
          //   }
          // )
          //   .then((res) => res.json())
          let modify_customer_information = [];
          let customer_info = SessionStorageService().getCustomerInfo();
          customer_info.map((val, idx) =>
            modify_customer_information.push({
              ...val,
              contract_information: {
                ...val.contract_information,
                product: [
                  parseInt(
                    res.data?.produit[0]?.produit?.produit_sante.cotation_id
                  ),
                ],
              },
            })
          );
          let contract_information = {
            effective_date: moment(dateEffet, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            ),
            mode_splitting: "Mensuel",
            apply_reduction: true,
            prorata: true,
          };
          if (
            checkSelectedGamme(
              process.env.REACT_APP_CCMO_ID_GAMME,
              proposition
            ) ||
            checkSelectedGamme(
              process.env.REACT_APP_AESIO_ID_GAMME,
              proposition
            )
          ) {
            contract_information = {
              ...contract_information,
              has_modular_collective_contract: false,
              contribution_selected: [
                {
                  name: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
                    ? "Frais de dossier distributeur"
                    : "FraisDossierAesio",
                  value: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
                    ? fraisLie
                    : fraisAesio,
                  tax_set: [],
                },
                {
                  name: "FCA",
                  value: fraisCourtage,
                  tax_set: [],
                },
              ],
            };
          }
          axios
            .post(
              `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_pricing`,
              {
                business_introducer: {
                  login: "Z04",
                },
                third_app: {
                  name: "ASolutions",
                  id_external: 476820,
                },
                customer_information: modify_customer_information,
                contract_information: contract_information,
                products: {
                  0: Array(
                    SessionStorageService().getCustomerInfo().length
                  ).fill([
                    parseInt(
                      res.data?.produit[0]?.produit?.produit_sante.cotation_id
                    ),
                  ]),
                },
              },
              {
                headers: {
                  idSession: localStorage.getItem("token"),
                },
              }
            )
            .then((data) => {
              try {
                setCalculated(true);
                let contributions =
                  data.data.data.product_ranges[0]?.contracts[0]?.contributions
                    ?.contributions;
                setFraisPj(tarifs[1143]);
                setMalo(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) => val.id == process.env.REACT_APP_ID_FRAIS_MALO
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisAssociatif(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == process.env.REACT_APP_ID_FRAIS_ASSOCIATIF
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisQuittancement(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == process.env.REACT_APP_ID_FRAIS_QUITTANCEMENT
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setAlturgence(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == process.env.REACT_APP_ID_FRAIS_ALTURGENCES
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
              } catch (e) {
                throw new ErrorBoundary();
              }
              setCalculating(false);
              setCots(data.data.data);
              cotisationInitialeReprise === false && data.data.data?.total &&
                setBaseTarif(
                  parseFloat(String(data.data.data.total).replace(",", ".")).toFixed(2)
                );
              setTarifLoader(false);
              // setTarifFinal(
              //   parseFloat(String(data.data.data.total).replace(",", "."))
              // );
              if (
                checkSelectedGamme(
                  process.env.REACT_APP_AESIO_ID_GAMME,
                  proposition
                )
              ) {
                if (optionHorsSante?.includes("Assistance") && optionHorsSante?.includes("Protection juridique")) {
                  setTarifFinal(
                    parseFloat(
                      String(
                        parseFloat(String(data.data.data.total).replace(",", ".")) +
                          tarifs[process.env.REACT_APP_ASSISTANCE_CONTRACT]+ tarifs[process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT]
                      ).replace(",", ".")
                    ).toFixed(2)
                  );
                 
                  
                }
               
              } 
              else if (
                checkSelectedGamme(
                  process.env.REACT_APP_CCMO_ID_GAMME,
                  proposition
                )
              ) {
                // setTarifFinal(
                //   parseFloat(
                //     String(
                //       parseFloat(String(data.data.data.total).replace(",", ".")) +
                //         tarifs[
                //           process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT
                //         ]
                //     ).replace(",", ".")
                //   ).toFixed(2)
                // );
                setTarifFinal(
                  parseFloat(
                    String(
                      parseFloat(String(data.data.data.total).replace(",", "."))
                    ).replace(",", ".")
                  ).toFixed(2)
                );
              
              }
              optionHorsSante &&
                sessionStorage.setItem(
                  "OptionHorsSante",
                  JSON.stringify(optionHorsSante)
                );
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      setCalculating(false);
      setTarifLoader(false);
    }
    /* let temp = ap.find((item) => item.produit.produit_sante.option === option);
    temp && seChangedProduct(temp); */
  };
  const isValidName = (name) => {
    const regexName = new RegExp(
      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/
    );

    return regexName.test(name.replace(/ /g, ""));
  };
  const onSelectedFraisCourtage = (optionValue) => {
    setCalculating(true);
    setTarifLoader(true);
    let option =
      SessionStorageService()?.getProspect()?.formule ||
      proposition?.produit?.produit_sante?.option;
    let gamme =
      sessionStorage.getItem("selectedGamme") ||
      proposition?.produit.produit_sante.game;
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/prod_garanties/get_produit_details_v2`,
        {
          game: gamme,
          option: option,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        seChangedProduct(res.data.produit[0]);
        sessionStorage.setItem("product", JSON.stringify(res.data.produit[0]));

        let modify_customer_information = [];
        let customer_info = SessionStorageService().getCustomerInfo();
        customer_info.map((val, idx) =>
          modify_customer_information.push({
            ...val,
            contract_information: {
              ...val.contract_information,
              product: [
                parseInt(
                  res.data?.produit[0]?.produit?.produit_sante.cotation_id
                ),
              ],
            },
          })
        );
        let contract_information = {
          effective_date: moment(dateEffet, "DD-MM-YYYY").format("DD/MM/YYYY"),
          mode_splitting: "Mensuel",
          apply_reduction: true,
          prorata: true,
        };
        if (
          checkSelectedGamme(
            process.env.REACT_APP_CCMO_ID_GAMME,
            proposition
          ) ||
          checkSelectedGamme(process.env.REACT_APP_AESIO_ID_GAMME, proposition)
        ) {
          contract_information = {
            ...contract_information,
            has_modular_collective_contract: false,
            contribution_selected: [
              {
                name: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
                  ? "Frais de dossier distributeur"
                  : "FraisDossierAesio",
                value: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
                  ? fraisLie
                  : fraisAesio,
                tax_set: [],
              },
              {
                name: "FCA",
                value: optionValue,
                tax_set: [],
              },
            ],
          };
        }
        axios
          .post(
            `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_pricing`,
            {
              business_introducer: {
                login: "Z04",
              },
              third_app: {
                name: "ASolutions",
                id_external: 476820,
              },
              customer_information: modify_customer_information,
              contract_information: contract_information,
              products: {
                0: Array(SessionStorageService().getCustomerInfo().length).fill(
                  [
                    parseInt(
                      res.data?.produit[0]?.produit?.produit_sante.cotation_id
                    ),
                  ]
                ),
              },
            },
            {
              headers: {
                idSession: localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            try {
              setCalculated(true);
              let contributions =
                data.data.data.product_ranges[0]?.contracts[0]?.contributions
                  ?.contributions;
              setFraisPj(tarifs[1143]);
              setMalo(
                parseFloat(
                  String(
                    contributions.find(
                      (val) => val.id == process.env.REACT_APP_ID_FRAIS_MALO
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisAssociatif(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == process.env.REACT_APP_ID_FRAIS_ASSOCIATIF
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisQuittancement(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == process.env.REACT_APP_ID_FRAIS_QUITTANCEMENT
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setAlturgence(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == process.env.REACT_APP_ID_FRAIS_ALTURGENCES
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
            } catch (e) {
              throw new ErrorBoundary();
            }
            setCalculating(false);
            setCots(data.data.data);
            // data.data.data?.total &&
            //   setBaseTarif(
            //     parseFloat(String(data.data.data.total).replace(",", "."))
            //   );
            setTarifLoader(false);
            // setTarifFinal(
            //   parseFloat(String(data.data.data.total).replace(",", "."))
            // );
           
            if (
              checkSelectedGamme(
                process.env.REACT_APP_AESIO_ID_GAMME,
                proposition
              )
            ) {
              if (optionHorsSante?.includes("Assistance") && optionHorsSante?.includes("Protection juridique")) {
                setTarifFinal(
                  parseFloat(
                    String(
                      (parseFloat(String(data.data.data.total).replace(",", ".")) +
                        tarifs[process.env.REACT_APP_ASSISTANCE_CONTRACT]+tarifs[
                          process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT
                        ]).toFixed(2)
                    ).replace(",", ".")
                  )
                );
             
              }
           
            } else if (
              checkSelectedGamme(
                process.env.REACT_APP_CCMO_ID_GAMME,
                proposition
              )
            ) {
              
            
              // setTarifFinal(
              //   parseFloat(
              //     String(
              //      (parseFloat(String(data.data.data.total).replace(",", ".")) +
              //         tarifs[
              //           process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT
              //         ]).toFixed(2)
              //     ).replace(",", ".")
              //   )
              // );
              setTarifFinal(
                parseFloat(
                  String(
                   (parseFloat(String(data.data.data.total).replace(",", "."))).toFixed(2)
                  ).replace(",", ".")
                )
              );
            
            }
            optionHorsSante &&
              sessionStorage.setItem(
                "OptionHorsSante",
                JSON.stringify(optionHorsSante)
              );
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => console.log(err));
  };
  // React.useEffect(() => {
  //   setCalculating(true);
  //   setTarifLoader(true);
  //   let option =
  //     SessionStorageService()?.getProspect()?.formule ||
  //     proposition?.produit?.produit_sante?.option;
  //   let gamme =
  //     sessionStorage.getItem("selectedGamme") ||
  //     proposition?.produit.produit_sante.game;
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/prod_garanties/get_produit_details_v2`,
  //       {
  //         game: gamme,
  //         option: option,
  //       },
  //       {
  //         headers: {
  //           idSession: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       seChangedProduct(res.data.produit[0]);
  //       sessionStorage.setItem("product", JSON.stringify(res.data.produit[0]));

  //       let modify_customer_information = [];
  //       let customer_info = SessionStorageService().getCustomerInfo();
  //       customer_info.map((val, idx) =>
  //         modify_customer_information.push({
  //           ...val,
  //           contract_information: {
  //             ...val.contract_information,
  //             product: [
  //               parseInt(
  //                 res.data?.produit[0]?.produit?.produit_sante.cotation_id
  //               ),
  //             ],
  //           },
  //         })
  //       );
  //       let contract_information = {
  //         effective_date: moment(dateEffet, "DD-MM-YYYY").format("DD/MM/YYYY"),
  //         mode_splitting: "Mensuel",
  //         apply_reduction: true,
  //         prorata: true,
  //       };
  //       if (
  //         checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME, proposition)
  //       ) {
  //         contract_information = {
  //           ...contract_information,
  //           has_modular_collective_contract: false,
  //           contribution_selected: [
  //             {
  //               name: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
  //                 ? "Frais de dossier distributeur"
  //                 : "FraisDossierAesio",
  //               value: checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
  //                 ? fraisLie
  //                 : fraisAesio,
  //               tax_set: [],
  //             },
  //             {
  //               name: "FCA",
  //               value: fraisCourtage,
  //               tax_set: [],
  //             },
  //           ],
  //         };
  //       }
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_pricing`,
  //           {
  //             business_introducer: {
  //               login: "Z04",
  //             },
  //             third_app: {
  //               name: "ASolutions",
  //               id_external: 476820,
  //             },
  //             customer_information: modify_customer_information,
  //             contract_information: contract_information,
  //             products: {
  //               0: Array(SessionStorageService().getCustomerInfo().length).fill(
  //                 [
  //                   parseInt(
  //                     res.data?.produit[0]?.produit?.produit_sante.cotation_id
  //                   ),
  //                 ]
  //               ),
  //             },
  //           },
  //           {
  //             headers: {
  //               idSession: localStorage.getItem("token"),
  //             },
  //           }
  //         )
  //         .then((data) => {
  //           try {
  //             setCalculated(true);
  //             let contributions =
  //               data.data.data.product_ranges[0]?.contracts[0]?.contributions
  //                 ?.contributions;
  //             setFraisPj(tarifs[1143]);
  //             setMalo(
  //               parseFloat(
  //                 String(
  //                   contributions.find(
  //                     (val) => val.id == process.env.REACT_APP_ID_FRAIS_MALO
  //                   )?.raw_total
  //                 ).replace(",", ".")
  //               )
  //             );
  //             setFraisAssociatif(
  //               parseFloat(
  //                 String(
  //                   contributions.find(
  //                     (val) =>
  //                       val.id == process.env.REACT_APP_ID_FRAIS_ASSOCIATIF
  //                   )?.raw_total
  //                 ).replace(",", ".")
  //               )
  //             );
  //             setFraisQuittancement(
  //               parseFloat(
  //                 String(
  //                   contributions.find(
  //                     (val) =>
  //                       val.id == process.env.REACT_APP_ID_FRAIS_QUITTANCEMENT
  //                   )?.raw_total
  //                 ).replace(",", ".")
  //               )
  //             );
  //             setAlturgence(
  //               parseFloat(
  //                 String(
  //                   contributions.find(
  //                     (val) =>
  //                       val.id == process.env.REACT_APP_ID_FRAIS_ALTURGENCES
  //                   )?.raw_total
  //                 ).replace(",", ".")
  //               )
  //             );
  //           } catch (e) {
  //             throw new ErrorBoundary();
  //           }
  //           setCalculating(false);
  //           setCots(data.data.data);
  //           data.data.data?.total &&
  //             setBaseTarif(
  //               parseFloat(String(data.data.data.total).replace(",", "."))
  //             );
  //           setTarifLoader(false);
  //           setTarifFinal(
  //             parseFloat(String(data.data.data.total).replace(",", "."))
  //           );
  //         })
  //         .catch((err) => {
  //           console.log("err", err);
  //         });
  //     })
  //     .catch((err) => console.log(err));
  // }, [fraisCourtage]);
  React.useEffect(() => {
    //setProducts(products);
    sessionStorage.setItem("reset", "1");
    if (!SessionStorageService().getProspect()) {
      SessionStorageService().setProspect({
        ...SessionStorageService().getProspect(),
        ...prospect,
      });
    } else {
      SessionStorageService().setProspect({
        ...SessionStorageService().getProspect(),
      });
    }
    // SessionStorageService().setCustomerInfo(customerInfo);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    sessionStorage.setItem(
      "product",
      changedProduct
        ? JSON.stringify(changedProduct)
        : JSON.stringify(proposition)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedProduct]);

  // React.useEffect(() => {
  //   console.log(
  //     "tarifs[process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT]",
  //     tarifs[process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT]
  //   );
  //   console.log(
  //     "ASSISTANCE_CONTRACT]",
  //     tarifs[process.env.REACT_APP_ASSISTANCE_CONTRACT]
  //   );
  //   if (
  //     tarifs &&
  //     checkSelectedGamme(process.env.REACT_APP_AESIO_ID_GAMME, proposition)
  //   ) {
  //     let temp = parseFloat(String(baseTarif).replace(",", "."));
  //     if (optionHorsSante?.includes("Assistance")) {
  //       temp = temp + tarifs[process.env.REACT_APP_ASSISTANCE_CONTRACT];
  //     }
  //     if (optionHorsSante?.includes("Protection juridique")) {
  //       temp =
  //         temp + tarifs[process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT];
  //     }
  //     setTarifFinal(temp?.toFixed(2));
  //   } else if (
  //     tarifs &&
  //     checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME, proposition)
  //   ) {
  //     let temp = parseFloat(String(baseTarif).replace(",", "."));
  //     temp = temp + tarifs[process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT];
  //     setTarifFinal(temp?.toFixed(2));
  //   }
  //   optionHorsSante &&
  //     sessionStorage.setItem(
  //       "OptionHorsSante",
  //       JSON.stringify(optionHorsSante)
  //     );
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [optionHorsSante, baseTarif, repriseData, tarifFinal]);
  // const { nom, surname } = React.useContext(GlobalContext);
  // function hasLeadingOrTrailingSpace(inputString) {
  //   if (inputString === undefined || inputString === null) return false;
  //   return inputString?.trim() !== inputString;
  // }
  return repriseData && Object.keys(repriseData).length > 0 ? (
    <div ref={containerDiv} className={classes.container}>
      <GarantiesModal
        toggle={toggle}
        modal={modal}
        proposition={changedProduct ? changedProduct : proposition}
        tarif={baseTarif}
      />
      <Row className={classes.btnRow} gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className={classes.btnCol1}
        >
          <Button
            size="large"
            type="primary"
            classNames={classes.adhesionButton}
            style={{ backgroundColor: "#faad14", color: "white" }}
            className={classes.btn}
            disabled={calculating}
            onClick={() => {
              if (
                // !SessionStorageService().getEmailProspect().name ||
                // !SessionStorageService().getProspect().surname ||
                !SessionStorageService()
                  .getEmailProspect()
                  .name?.replace(/ /g, "") ||
                !SessionStorageService()
                  .getProspect()
                  .surname?.replace(/ /g, "")
              ) {
                alertMessage(
                  "Veuillez remplir les champs nom et prénom.",
                  "error"
                );
              }
              //  else if (
              //   /\d/.test(SessionStorageService().getEmailProspect().name)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ nom.",
              //     "error"
              //   );
              // } else if (
              //   /\d/.test(SessionStorageService().getProspect().surname)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ prénom.",
              //     "error"
              //   );
              // }
              else if (
                !isValidName(SessionStorageService()?.getEmailProspect()?.name)
              ) {
                alertMessage("Veuillez vérifier votre nom.", "error");
              } else if (
                !isValidName(SessionStorageService()?.getProspect()?.surname)
              ) {
                alertMessage("Veuillez vérifier votre prénom.", "error");
              }
              // else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getEmailProspect().name
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ nom.",
              //     "error"
              //   );
              // }
              //  else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getProspect().surname
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ prenom.",
              //     "error"
              //   );
              // }
              else {
                notification.destroy();
                !sessionStorage.getItem("SessionURLID") && createOpp();
                let apiKey = window.location.search;
                navigate(`/adhesion${apiKey}`, {
                  state: {
                    proposition: changedProduct ? changedProduct : proposition,
                    optionHorsSante,
                  },
                });
              }
            }}
          >
            Adhésion
          </Button>

          <Button
            color="info"
            size="large"
            disabled={calculating}
            type="primary"
            style={{ backgroundColor: "#36cfc9", color: "white" }}
            onClick={() => {
              if (
                !SessionStorageService().getEmailProspect().name ||
                !SessionStorageService().getProspect().surname ||
                SessionStorageService()
                  .getEmailProspect()
                  .name?.replace(/ /g, "") === "" ||
                SessionStorageService()
                  .getProspect()
                  .surname?.replace(/ /g, "") === ""
              ) {
                alertMessage(
                  "Veuillez remplir les champs nom et prénom.",
                  "error"
                );
              }
              //  else if (
              //   /\d/.test(SessionStorageService().getEmailProspect().name)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ nom.",
              //     "error"
              //   );
              // } else if (
              //   /\d/.test(SessionStorageService().getProspect().surname)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ prénom.",
              //     "error"
              //   );
              // }
              else if (
                !isValidName(SessionStorageService()?.getEmailProspect()?.name)
              ) {
                alertMessage("Veuillez vérifier votre nom.", "error");
              } else if (
                !isValidName(SessionStorageService()?.getProspect()?.surname)
              ) {
                alertMessage("Veuillez vérifier votre prénom.", "error");
              }
              // else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getEmailProspect().name
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ nom.",
              //     "error"
              //   );
              // }
              //  else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getProspect().surname
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ prenom.",
              //     "error"
              //   );
              // }
              else {
                notification.destroy();
                setOpenbackdrop(true);

                if (!sessionStorage.getItem("SessionURLID")) {
                  createOpp("devis");
                } else {
                  setOpenbackdrop(false);
                  let apiKey = window.location.search;
                  navigate(`/devisTarificateur${apiKey}`, {
                    state: {
                      proposition: changedProduct
                        ? changedProduct
                        : proposition,
                      baseTarif: baseTarif,
                      param_id: param_id,
                      tarif: tarifFinal,
                    },
                  });
                }
              }
            }}
            className={classes.btn}
          >
            Devis
          </Button>

          <Button
            color="success"
            size="large"
            type="primary"
            style={{ backgroundColor: "#389e0d", color: "white" }}
            className={classes.btn}
            onClick={toggle}
          >
            Garanties
          </Button>
        </Col>
        {/* <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className={classes.btnCol2}
        >
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#389e0d", color: "white" }}
            className={classes.btn}
            onClick={() => {
              id
                ? (window.location = `/?partnership_id=${id}`)
                : (window.location = "/");
            }}
          >
            Comparateur
          </Button>
          <Button
            onClick={CopyURLHandler}
            size="large"
            type="primary"
            style={{ backgroundColor: "#0958d9", color: "white" }}
            disabled={calculating}
            className={classes.btn}
          >
            Copier la Tarification
          </Button>
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#36cfc9", color: "white" }}
            disabled={calculating}
            className={classes.btn}
            onClick={() => {
              takeScreenshot(containerDiv.current)
                .then((dataUrl) => {
                  const downloadLink = document.createElement("a");
                  const fileName = "tarificateur.png";
                  downloadLink.href = dataUrl;
                  downloadLink.download = fileName;
                  downloadLink.click();
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Copier image
          </Button>
        </Col> */}
      </Row>
      <UserInfo
        getNewTarif={getNewTarif}
        proposition={proposition}
        prospect={prospect}
      />
      <GroupMember />
      {checkSelectedGamme(
        process.env.REACT_APP_AESIO_ID_GAMME,
        proposition
      ) && (
        <TotalCotisation
          cout={baseTarif}
          name="Total de la cotisation mensuelle initiale :"
        />
      )}
      {checkSelectedGamme(process.env.REACT_APP_AESIO_ID_GAMME, proposition) ? (
        <>
          <GarantiesOptionnels prospect={prospect} />
          <Form layout="vertical">
            <Row
              style={{
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Col xs={24} sm={24} md={24} xl={8} lg={8}>
                <Form.Item label="Frais de courtage accessoire" required>
                  <Select
                    size="large"
                    options={options_frais_courtage}
                    value={fraisCourtage}
                    onSelect={(option) => {
                      setFraisCourtage(option);
                      if (option !== fraisCourtage) {
                        onSelectedFraisCourtage(option);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item label="Frais de dossier AESIO" required>
                  <Select
                    value={fraisAesio}
                    size="large"
                    options={frais_dossier_aesio}
                    onSelect={(option) => {
                      setFraisAesio(option);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ) : checkSelectedGamme(
          process.env.REACT_APP_CCMO_ID_GAMME,
          proposition
        ) ? (
        <FraisTarificateur
          onSelectedFraisCourtage={onSelectedFraisCourtage}
          options_frais_courtage={options_frais_courtage}
        />
      ) : (
        <></>
      )}
      {checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME, proposition) && (
        <TotalCotisation
          cout={tarifFinal}
          name="Total de la cotisation mensuelle :"
        />
      )}
      {checkSelectedGamme(
        process.env.REACT_APP_AESIO_ID_GAMME,
        proposition
      ) && (
        <TotalCotisation
          cout={tarifFinal}
          name="Total de la cotisation mensuelle finale :"
          final={true}
        />
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: "100%" }}>
            <CircularProgress style={{ width: "4rem", height: "4rem" }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </div>
  ) : (
    <></>
  );
};

export default Tarificateur;
