let data1 = [];
let obj = [
  {
    name: 'nom_courtier||Courtier||0',
  },
  {
    name: 'codepostal_ville_adherant',
  },
  {
    name: 'prenom_assure||Prénom||2',
  },
  {
    name: 'adresse_assure||Adresse||3',
  },
  {
    name: 'cp_assure||Code postal||4',
  },
  {
    name: 'ville_assure||Ville||5',
  },
  {
    name: 'nomproduit_sante||Gamme||2',
  },
  {
    name: 'formuleproduit_sante||Formule||3',
  },
  {
    name: 'assistance_sante||Assistance||4',
  },
  {
    name: 'pj_sante||Protection juridique||5',
  },
  {
    name: 'civilite_assure||Civilité||0',
  },
  {
    name: 'dn_assure||Date de naissance||3',
  },
  {
    name: 'dn_conjoint||Date de naissance||3',
  },
  {
    name: 'dn_enfant1||Date de naissance||3',
  },
  {
    name: 'dn_enfant2||Date de naissance||3',
  },
  {
    name: 'dn_enfant3||Date de naissance||3',
  },
  {
    name: 'dn_enfant4||Date de naissance||3',
  },
  {
    name: 'dn_enfant5||Date de naissance||3',
  },
  {
    name: 'dn_enfant6||Date de naissance||3',
  },
  {
    name: 'ro_assure||Régime||4',
  },
  {
    name: 'ro_conjoint||Régime||4',
  },
  {
    name: 'ro_enfant1||Régime||4',
  },
  {
    name: 'ro_enfant2||Régime||4',
  },
  {
    name: 'ro_enfant3||Régime||4',
  },
  {
    name: 'ro_enfant4||Régime||4',
  },
  {
    name: 'ro_enfant5||Régime||4',
  },
  {
    name: 'ro_enfant6||Régime||4',
  },
  {
    name: 'lienf_assure||Lien famillial||5',
  },
  {
    name: 'lienf_enfant6||Lien famillial||5',
  },
  {
    name: 'lienf_enfant5||Lien famillial||5',
  },
  {
    name: 'lienf_enfant4||Lien famillial||5',
  },
  {
    name: 'lienf_enfant3||Lien famillial||5',
  },
  {
    name: 'lienf_enfant2||Lien famillial||5',
  },
  {
    name: 'lienf_enfant1||Lien famillial||5',
  },
  {
    name: 'lienf_conjoint||Lien famillial||5',
  },
  {
    name: 'num_sec_assure||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_conjoint||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant1||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant2||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant3||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant4||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant5||N° sécurité sodiale||6',
  },
  {
    name: 'num_sec_enfant6||N° sécurité sodiale||6',
  },
  {
    name: 'rang_enfant1||Rang||7',
  },
  {
    name: 'rang_enfant2||Rang||7',
  },
  {
    name: 'rang_enfant3||Rang||7',
  },
  {
    name: 'rang_enfant4||Rang||7',
  },
  {
    name: 'rang_enfant5||Rang||7',
  },
  {
    name: 'rang_enfant6||Rang||7',
  },
  {
    name: 'rang_conjoint||Rang||7',
  },
  {
    name: 'rang_assure||Rang||7',
  },
  {
    name: 'suro_assure||Surco||8',
  },
  {
    name: 'suro_enfant6||Surco||8',
  },
  {
    name: 'suro_enfant5||Surco||8',
  },
  {
    name: 'suro_enfant4||Surco||8',
  },
  {
    name: 'suro_enfant3||Surco||8',
  },
  {
    name: 'suro_enfant2||Surco||8',
  },
  {
    name: 'suro_enfant1||Surco||8',
  },
  {
    name: 'suro_conjoint||Surco||8',
  },
  {
    name: 'civilite_enfant6||Civilité||0',
  },
  {
    name: 'civilite_enfant5||Civilité||0',
  },
  {
    name: 'civilite_enfant4||Civilité||0',
  },
  {
    name: 'civilite_enfant3||Civilité||0',
  },
  {
    name: 'civilite_enfant2||Civilité||0',
  },
  {
    name: 'civilite_enfant1||Civilité||0',
  },
  {
    name: 'civilite_conjoint||Civilité||0',
  },
  {
    name: 'teletransmission_enfant6||Télétransmission||8',
  },
  {
    name: 'teletransmission_enfant5||Télétransmission||8',
  },
  {
    name: 'teletransmission_enfant4||Télétransmission||8',
  },
  {
    name: 'teletransmission_enfant3||Télétransmission||8',
  },
  {
    name: 'teletransmission_enfant2||Télétransmission||8',
  },
  {
    name: 'teletransmission_enfant1||Télétransmission||8',
  },
  {
    name: 'teletransmission_conjoint||Télétransmission||8',
  },
  {
    name: 'teletransmission_assure||Télétransmission||8',
  },
  {
    name: 'val_pj_text',
  },
  {
    name: 'val_assistance_text',
  },
  {
    name: 'numcontrat_sante||N° contrat||0',
  },
  {
    name: "date_effet_sante||Date d'effet||1",
  },
  {
    name: "de_sante||Date d'échéance||6",
  },
  {
    name: "dureeadhesion_sante||Durée de l'adhésion||7",
  },
  {
    name: 'preavisres_sante||Préavis de résiliation||8',
  },
  {
    name: 'modesignature_sante||Mode de signature||9',
  },
  {
    name: 'cot_mensuelle_sante||Cotisation mensuelle||10',
  },
  {
    name: 'cot_annuelle_sante||Cotisation annuelle||11',
  },
  {
    name: 'aesio_sante||AESIO mutuelle||12',
  },
  {
    name: 'tsa_sante||Taxe TSA||13',
  },
  {
    name: 'tax_sante||Taxe Assurance||14',
  },
  {
    name: 'cfdp_sante||Cfdp||15',
  },
  {
    name: 'frais_sante||Frais associatifs||20',
  },
  {
    name: 'fraisq_sante||Frais de quittancement||19',
  },
  {
    name: 'ht_taxe1_sante||HT Taxe Assurance||18',
  },
  {
    name: 'matuaide_sante||Mutuaide assistance||17',
  },
  {
    name: 'ht_taxe_sante||HT Taxe Assurance||16',
  },
  {
    name: 'fraisd_sante||Frais de dossier||21',
  },
  {
    name: 'modepc_sante||Mode de paiement du comptant||22',
  },
  {
    name: 'modepcs_sante||Mode de paiement des cotisations suivantes||23',
  },
  {
    name: 'date_prelevement_sante||Date de prélèvement||24',
  },
  {
    name: 'bicremb_assure||BIC rembourssement||10',
  },
  {
    name: 'ibanremb_assure||IBAN rembourssement||11',
  },
  {
    name: 'referenca_sepa||Référence||0',
  },
  {
    name: 'adresse_sepa||Adresse||2',
  },
  {
    name: 'nomprenom_sepa||Nom/Prénom du débiteur||1',
  },
  {
    name: 'cp_sepa||Code postal||3',
  },
  {
    name: 'bic_sepa||BIC||5',
  },
  {
    name: 'iban_sepa||IBAN||6',
  },
  {
    name: 'type_paiement_sepa||Type de paiement||7',
  },
  {
    name: 'ville_sepa||Ville||4',
  },
  {
    name: 'date0_echeance||Date||0',
  },
  {
    name: 'cotisation0_echeance||Cotisation TTC||1',
  },
  {
    name: 'periode1_echeance||Période||2',
  },
  {
    name: 'date1_echeance||Date||3',
  },
  {
    name: 'cotisation1_echeance||Cotisation TTC||4',
  },
  {
    name: 'date2_echeance||Date||6',
  },
  {
    name: 'cotisation2_echeance||Cotisation TTC||7',
  },
  {
    name: 'date3_echeance||Date||9',
  },
  {
    name: 'cotisation3_echeance||Cotisation TTC||10',
  },
  {
    name: 'date4_echeance||Date||12',
  },
  {
    name: 'cotisation4_echeance||Cotisation TTC||13',
  },
  {
    name: 'date5_echeance||Date||15',
  },
  {
    name: 'cotisation5_echeance||Cotisation TTC||16',
  },
  {
    name: 'date6_echeance||Date||18',
  },
  {
    name: 'cotisation6_echeance||Cotisation TTC||19',
  },
  {
    name: 'periode7_echeance||Période||18',
  },
  {
    name: 'periode11_echeance||Période||18',
  },
  {
    name: 'periode2_echeance||Période||5',
  },
  {
    name: 'periode3_echeance||Période||8',
  },
  {
    name: 'periode4_echeance||Période||11',
  },
  {
    name: 'periode5_echeance||Période||14',
  },
  {
    name: 'periode5_echeance||Période||17',
  },
  {
    name: 'date11_echeance||Date||0',
  },
  {
    name: 'cotisation10_echeance||Cotisation TTC||1',
  },
  {
    name: 'date7_echeance||Date||19',
  },
  {
    name: 'cotisation7_echeance||Cotisation TTC||20',
  },
  {
    name: 'periode8_echeance||Période||121',
  },
  {
    name: 'date8_echeance||Date||22',
  },
  {
    name: 'cotisation8_echeance||Cotisation TTC||23',
  },
  {
    name: 'periode9_echeance||Période||24',
  },
  {
    name: 'date9_echeance||Date||25',
  },
  {
    name: 'cotisation9_echeance||Cotisation TTC||26',
  },
  {
    name: 'periode10_echeance||Période||27',
  },
  {
    name: 'date10_echeance||Date||28',
  },
  {
    name: 'cotisation11_echeance||Cotisation TTC||29',
  },
  {
    name: 'periode12_echeance||Période||30',
  },
  {
    name: 'date12_echeance||Date||31',
  },
  {
    name: 'cotisation12_echeance||Cotisation TTC||32',
  },
  {
    name: 'moyen_assure||Moyen de communication utilisé||0',
  },
  {
    name: 'nom_assure||Nom||1',
  },
  {
    name: 'nom_conjoint||Nom||1',
  },
  {
    name: 'prenom_conjoint||Prénom||2',
  },
  {
    name: 'nom_enfant1||Nom||1',
  },
  {
    name: 'prenom_enfant1||Prénom||1',
  },
  {
    name: 'nom_enfant2||Nom||1',
  },
  {
    name: 'prenom_enfant2||Prénom||1',
  },
  {
    name: 'nom_enfant3||Nom||1',
  },
  {
    name: 'prenom_enfant3||Prénom||1',
  },
  {
    name: 'nom_enfant4||Nom||1',
  },
  {
    name: 'prenom_enfant4||Prénom||1',
  },
  {
    name: 'nom_enfant5||Nom||1',
  },
  {
    name: 'prenom_enfant5||Prénom||1',
  },
  {
    name: 'nom_enfant6||Nom||1',
  },
  {
    name: 'prenom_enfant6||Prénom||1',
  },
  {
    name: 'ds_sante||Date de souscription||0',
  },
  {
    name: 'civi_nom_prenomconjoint',
  },
  {
    name: 'civi_nom_prenomenf6',
  },
  {
    name: 'civi_nom_prenomenf5',
  },
  {
    name: 'civi_nom_prenomenf4',
  },
  {
    name: 'civi_nom_prenomenf3',
  },
  {
    name: 'civi_nom_prenomenf2',
  },
  {
    name: 'civi_nom_prenomenf1',
  },
  {
    name: 'civi_nom_prenomconj',
  },
  {
    name: 'civi_nom_prenomassure',
  },
  {
    name: 'numcontrat_lettre||N°contrat||1',
  },
  {
    name: 'date_fin_contrat_lettre||Date fin contrat||2',
  },
  {
    name: 'numeroRO_lettre||N° Sécurité Sociale ||0',
  },
  {
    name: 'nom_prenom_lr||Nom||1',
  },
  {
    name: 'cp_ville_lr||Ville||2',
  },
  {
    name: 'adresse_lr||Adresse||obligatoire||5',
  },
  {
    name: 'cpassure_text',
  },
  {
    name: 'mailassure_text',
  },
  {
    name: 'telassure_text',
  },
  {
    name: 'mobileassure_text',
  },
  {
    name: 'date_naissanceassure_text',
  },
  {
    name: 'professionassure_text',
  },
  {
    name: 'situationassure_text',
  },
  {
    name: 'exerceequalite_assure||Exercée en qualité de||17',
  },
  {
    name: 'beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2',
  },
  {
    name: 'complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3',
  },
  {
    name: 'mt_garantie||Si oui: laquelle (montant)||4',
  },
  {
    name: 'nomassure_text',
  },
  {
    name: 'prenomassure_text',
  },
  {
    name: 'roassure_text',
  },
  {
    name: 'dnassure_text',
  },
  {
    name: 'affiliationassure_text',
  },
  {
    name: 'inseeassure_text',
  },
  {
    name: 'roconjoint_text',
  },
  {
    name: 'dnconjoint_text',
  },
  {
    name: 'nomconjoint_text',
  },
  {
    name: 'prenomconjoint_text',
  },
  {
    name: 'inseeconjoint_text',
  },
  {
    name: 'affiliationconjoint_text',
  },
  {
    name: 'roenf1_text',
  },
  {
    name: 'dnenf1_text',
  },
  {
    name: 'nomenf1_text',
  },
  {
    name: 'prenomenf1_text',
  },
  {
    name: 'inseeenf1_text',
  },
  {
    name: 'affiliationenf1_text',
  },
  {
    name: 'roenf2_text',
  },
  {
    name: 'dnenf2_text',
  },
  {
    name: 'nomenf2_text',
  },
  {
    name: 'prenomenf2_text',
  },
  {
    name: 'affiliationenf2_text',
  },
  {
    name: 'inseeenf2_text',
  },
  {
    name: 'roenf3_text',
  },
  {
    name: 'dnenf3_text',
  },
  {
    name: 'nomenf3_text',
  },
  {
    name: 'prenomenf3_text',
  },
  {
    name: 'inseeenf3_text',
  },
  {
    name: 'affiliationenf3_text',
  },
  {
    name: 'dnenf4_text',
  },
  {
    name: 'nomenf4_text',
  },
  {
    name: 'prenomenf4_text',
  },
  {
    name: 'roenf4_text',
  },
  {
    name: 'inseeenf4_text',
  },
  {
    name: 'affiliationenf4_text',
  },
  {
    name: 'dnenf5_text',
  },
  {
    name: 'nomenf5_text',
  },
  {
    name: 'prenomenf5_text',
  },
  {
    name: 'inseeenf5_text',
  },
  {
    name: 'affiliationenf5_text',
  },
  {
    name: 'roenf5_text',
  },
  {
    name: 'dnenf6_text',
  },
  {
    name: 'nomenf6_text',
  },
  {
    name: 'prenomenf6_text',
  },
  {
    name: 'inseeenf6_text',
  },
  {
    name: 'affiliationenf6_text',
  },
  {
    name: 'roenf6_text',
  },
  {
    name: 'cie_text',
  },
  {
    name: 'produit_text',
  },
  {
    name: 'villeassure_text',
  },
  {
    name: 'datesous_text',
  },
  {
    name: 'nomprenomproducteur_text',
  },
  {
    name: 'nomprenomassure_text',
  },
  {
    name: 'concordancebesoins_garantie||Avantages concordance avec les besoins||20',
  },
  {
    name: 'offre1_garantie||Par téléphone||22',
  },
  {
    name: 'offre2_garantie||Par mail||23',
  },
  {
    name: 'offre3_garantie||Par courrier||24',
  },
  {
    name: 'nbreenfantsassure_text',
  },
  {
    name: "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5",
  },
  {
    name: "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6",
  },
  {
    name: "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7",
  },
  {
    name: "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8",
  },
  {
    name: 'refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9',
  },
  {
    name: 'hospi_garantie||Hospitalisation||11',
  },
  {
    name: 'dentaire_garantie||Dentaire||12',
  },
  {
    name: 'optique_garantie||Optique||13',
  },
  {
    name: 'soins_garantie||Soins courants||14',
  },
  {
    name: 'meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15',
  },
  {
    name: 'budegtmensuel_garantie||Si oui: lequel ?||10',
  },
  {
    name: 'meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16',
  },
  {
    name: 'meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleur couverture ?||17',
  },
  {
    name: 'rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19',
  },
  {
    name: "signature_garantie||Cette fiche d’information et de conseil a été signée dans le cadre d'une adhésion Signée de façon||21",
  },
  {
    name: "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18",
  },
  {
    name: 'declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19',
  },
  {
    name: 'nom_courtier_hidden||Courtier||0',
  },
  {
    name: 'rcs_courtier||RCS||1',
  },
  {
    name: 'orias_courtier||N° ORIAS||2',
  },
  {
    name: 'adresse_courtier||Adresse||3',
  },
  {
    name: 'adrcorrespondance_courtier||Adresse de correspondance||4',
  },
  {
    name: 'civilite_producteur||Civilité||0',
  },
  {
    name: 'nom_producteur||Nom||1',
  },
  {
    name: 'prenom_producteur||Prénom||2',
  },
  {
    name: 'qualite_producteur||Agissant en qualité||3',
  },
  {
    name: 'orias_producteur||ORIAS N°||4',
  },
  {
    name: 'tel_producteur||Téléphone||5',
  },
  {
    name: 'prefix_mobile_assure',
  },
  {
    name: 'suffix_mobile_assure',
  },
  {
    name: 'tax_aesio_mutuelle||Taxe Assurance||3',
  },
  {
    name: 'cfdp_tax_as||Taxe Assurance||6',
  },
  {
    name: 'mutuaide_taxe1_sante||HT Taxe Assurance||9',
  },
  {
    name: "date_effet_sante||Date d'effet||22",
  },
  {
    name: 'meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17',
  },
];

// fill data1 with data from obj
for (var i = 0; i < obj.length; i++) {
  data1[i] = [];
  data1[i][obj[i].name.toString()] = '';
}
//eslint-disable-next-line
// const DataIndex = data1.findIndex(
//   (singleData) => Object.keys(singleData)[0] === 'prenomenf3_text'
// );

export default data1;
