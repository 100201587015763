import axios from '../utils/axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Reprise() {
  const navigate = useNavigate();
  const { id } = useParams();
  React.useEffect(() => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          if (res.data?.url) {
            window.location = res.data?.url;
          } else {
            navigate('/NotFound');
          }
        })
        .catch(() => navigate('/NotFound'));
    } else {
      navigate('/NotFound');
    }
    //eslint-disable-next-line
  }, []);
}

export default Reprise;
