import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';

import classes from './Success.module.css';
function Success() {
  const params = useLocation();
  return (
    <Container fluid style={{ minHeight: '70vh' }}>
      <Button
        type='primary'
        size='large'
        className={classes.btn}
        onClick={() => {
          sessionStorage.clear();
          localStorage.getItem('api_key')
            ? (window.location = `/?partnership_id=${localStorage.getItem(
                'api_key'
              )}`)
            : (window.location = '/');
        }}
      >
        Retour vers comparateur
      </Button>
      <div className={classes.divBox}>
        <div className={classes.box}>
          <div className={classes.icon}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ height: '4rem', color: '#07d410' }}
            />
          </div>
          <div className={classes.titre}>
            <h2 className={classes.success}>Success</h2>
            <h4>
              {params?.state?.message
                ? params?.state?.message
                : "L'affaire a été intégrée dans GeoProd et est actuellement en attente de la signature de l'adhérent."}
            </h4>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Success;
