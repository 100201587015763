let newData = {
  'nom_courtier||Courtier||0': '',
  codepostal_ville_adherant: '',
  'prenom_assure||Prénom||2': '',
  'adresse_assure||Adresse||3': '',
  'cp_assure||Code postal||4': '',
  'ville_assure||Ville||5': '',
  'nomproduit_sante||Gamme||2': '',
  'formuleproduit_sante||Formule||3': '',
  'assistance_sante||Assistance||4': '',
  'pj_sante||Protection juridique||5': '',
  'civilite_assure||Civilité||0': '',
  'dn_assure||Date de naissance||3': '',
  'dn_conjoint||Date de naissance||3': '',
  'dn_enfant1||Date de naissance||3': '',
  'dn_enfant2||Date de naissance||3': '',
  'dn_enfant3||Date de naissance||3': '',
  'dn_enfant4||Date de naissance||3': '',
  'dn_enfant5||Date de naissance||3': '',
  'dn_enfant6||Date de naissance||3': '',
  'ro_assure||Régime||4': '',
  'ro_conjoint||Régime||4': '',
  'ro_enfant1||Régime||4': '',
  'ro_enfant2||Régime||4': '',
  'ro_enfant3||Régime||4': '',
  'ro_enfant4||Régime||4': '',
  'ro_enfant5||Régime||4': '',
  'ro_enfant6||Régime||4': '',
  'lienf_assure||Lien famillial||5': '',
  'lienf_enfant6||Lien famillial||5': '',
  'lienf_enfant5||Lien famillial||5': '',
  'lienf_enfant4||Lien famillial||5': '',
  'lienf_enfant3||Lien famillial||5': '',
  'lienf_enfant2||Lien famillial||5': '',
  'lienf_enfant1||Lien famillial||5': '',
  'lienf_conjoint||Lien famillial||5': '',
  'num_sec_assure||N° sécurité sodiale||6': '',
  'num_sec_conjoint||N° sécurité sodiale||6': '',
  'num_sec_enfant1||N° sécurité sodiale||6': '',
  'num_sec_enfant2||N° sécurité sodiale||6': '',
  'num_sec_enfant3||N° sécurité sodiale||6': '',
  'num_sec_enfant4||N° sécurité sodiale||6': '',
  'num_sec_enfant5||N° sécurité sodiale||6': '',
  'num_sec_enfant6||N° sécurité sodiale||6': '',
  'rang_enfant1||Rang||7': '',
  'rang_enfant2||Rang||7': '',
  'rang_enfant3||Rang||7': '',
  'rang_enfant4||Rang||7': '',
  'rang_enfant5||Rang||7': '',
  'rang_enfant6||Rang||7': '',
  'rang_conjoint||Rang||7': '',
  'rang_assure||Rang||7': '',
  'suro_assure||Surco||8': '',
  'suro_enfant6||Surco||8': '',
  'suro_enfant5||Surco||8': '',
  'suro_enfant4||Surco||8': '',
  'suro_enfant2||Surco||8': '',
  'suro_enfant1||Surco||8': '',
  'suro_conjoint||Surco||8': '',
  'civilite_enfant6||Civilité||0': '',
  'civilite_enfant5||Civilité||0': '',
  'civilite_enfant4||Civilité||0': '',
  'civilite_enfant3||Civilité||0': '',
  'civilite_enfant2||Civilité||0': '',
  'civilite_enfant1||Civilité||0': '',
  'civilite_conjoint||Civilité||0': '',
  'teletransmission_enfant6||Télétransmission||8': '',
  'teletransmission_enfant5||Télétransmission||8': '',
  'teletransmission_enfant4||Télétransmission||8': '',
  'teletransmission_enfant3||Télétransmission||8': '',
  'teletransmission_enfant2||Télétransmission||8': '',
  'teletransmission_enfant1||Télétransmission||8': '',
  'teletransmission_conjoint||Télétransmission||8': '',
  'teletransmission_assure||Télétransmission||8': '',
  val_pj_text: '',
  val_assistance_text: '',
  'numcontrat_sante||N° contrat||0': '',
  "date_effet_sante||Date d'effet||1": '',
  "de_sante||Date d'échéance||6": '',
  "dureeadhesion_sante||Durée de l'adhésion||7": '',
  'preavisres_sante||Préavis de résiliation||8': '',
  'modesignature_sante||Mode de signature||9': 'Electronique (SMS ou EMAIL)',
  'cot_mensuelle_sante||Cotisation mensuelle||10': '',
  'cot_annuelle_sante||Cotisation annuelle||11': '',
  'aesio_sante||AESIO mutuelle||12': '',
  'tsa_sante||Taxe TSA||13': '',
  'tax_sante||Taxe Assurance||14': '',
  'cfdp_sante||Cfdp||15': '',
  'frais_sante||Frais associatifs||20': '',
  'fraisq_sante||Frais de quittancement||19': '',
  'ht_taxe1_sante||HT Taxe Assurance||18': '',
  'matuaide_sante||Mutuaide assistance||17': '',
  'ht_taxe_sante||HT Taxe Assurance||16': '',
  'fraisd_sante||Frais de dossier||21': "",
  'modepc_sante||Mode de paiement du comptant||22': '',
  'modepcs_sante||Mode de paiement des cotisations suivantes||23': '',
  'date_prelevement_sante||Date de prélèvement||24': '',
  'bicremb_assure||BIC rembourssement||10': '',
  'ibanremb_assure||IBAN rembourssement||11': '',
  'referenca_sepa||Référence||0': '',
  'adresse_sepa||Adresse||2': '',
  'nomprenom_sepa||Nom/Prénom du débiteur||1': '',
  'cp_sepa||Code postal||3': '',
  'bic_sepa||BIC||5': '',
  'iban_sepa||IBAN||6': '',
  'type_paiement_sepa||Type de paiement||7': '',
  'ville_sepa||Ville||4': '',
  'date0_echeance||Date||0': '',
  'cotisation0_echeance||Cotisation TTC||1': '',
  'periode1_echeance||Période||2': '',
  'date1_echeance||Date||3': '',
  'cotisation1_echeance||Cotisation TTC||4': '',
  'date2_echeance||Date||6': '',
  'cotisation2_echeance||Cotisation TTC||7': '',
  'date3_echeance||Date||9': '',
  'cotisation3_echeance||Cotisation TTC||10': '',
  'date4_echeance||Date||12': '',
  'cotisation4_echeance||Cotisation TTC||13': '',
  'date5_echeance||Date||15': '',
  'cotisation5_echeance||Cotisation TTC||16': '',
  'date6_echeance||Date||18': '',
  'cotisation6_echeance||Cotisation TTC||19': '',
  'periode7_echeance||Période||18': '',
  'periode11_echeance||Période||18': '',
  'periode2_echeance||Période||5': '',
  'periode3_echeance||Période||8': '',
  'periode4_echeance||Période||11': '',
  'periode5_echeance||Période||14': '',
  'periode5_echeance||Période||17': '',
  'date11_echeance||Date||0': '',
  'cotisation10_echeance||Cotisation TTC||1': '',
  'date7_echeance||Date||19': '',
  'cotisation7_echeance||Cotisation TTC||20': '',
  'periode8_echeance||Période||121': '',
  'date8_echeance||Date||22': '',
  'cotisation8_echeance||Cotisation TTC||23': '',
  'periode9_echeance||Période||24': '',
  'date9_echeance||Date||25': '',
  'cotisation9_echeance||Cotisation TTC||26': '',
  'periode10_echeance||Période||27': '',
  'date10_echeance||Date||28': '',
  'cotisation11_echeance||Cotisation TTC||29': '',
  'periode12_echeance||Période||30': '',
  'date12_echeance||Date||31': '',
  'cotisation12_echeance||Cotisation TTC||32': '',
  'moyen_assure||Moyen de communication utilisé||0': '',
  'nom_assure||Nom||1': '',
  'nom_conjoint||Nom||1': '',
  'prenom_conjoint||Prénom||2': '',
  'nom_enfant1||Nom||1': '',
  'prenom_enfant1||Prénom||1': '',
  'nom_enfant2||Nom||1': '',
  'prenom_enfant2||Prénom||1': '',
  'nom_enfant3||Nom||1': '',
  'prenom_enfant3||Prénom||1': '',
  'nom_enfant4||Nom||1': '',
  'prenom_enfant4||Prénom||1': '',
  'nom_enfant5||Nom||1': '',
  'prenom_enfant5||Prénom||1': '',
  'nom_enfant6||Nom||1': '',
  'prenom_enfant6||Prénom||1': '',
  'ds_sante||Date de souscription||0': '',
  civi_nom_prenomconjoint: '',
  civi_nom_prenomenf6: '',
  civi_nom_prenomenf5: '',
  civi_nom_prenomenf4: '',
  civi_nom_prenomenf3: '',
  civi_nom_prenomenf2: '',
  civi_nom_prenomenf1: '',
  civi_nom_prenomconj: '',
  civi_nom_prenomassure: '',
  'numcontrat_lettre||N°contrat||1': '',
  'date_fin_contrat_lettre||Date fin contrat||2': '',
  'numeroRO_lettre||N° Sécurité Sociale ||0': '',
  'nom_prenom_lr||Nom||1': '',
  'cp_ville_lr||Ville||2': '',
  'adresse_lr||Adresse||obligatoire||5': '',
  cpassure_text: '',
  mailassure_text: '',
  telassure_text: '',
  mobileassure_text: '',
  date_naissanceassure_text: '',
  professionassure_text: '',
  situationassure_text: '',
  'exerceequalite_assure||Exercée en qualité de||17': '',
  'beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2':
    '',
  'complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3':
    '',
  'mt_garantie||Si oui: laquelle (montant)||4': '',
  nomassure_text: '',
  prenomassure_text: '',
  roassure_text: '',
  dnassure_text: '',
  affiliationassure_text: '',
  inseeassure_text: '',
  roconjoint_text: '',
  dnconjoint_text: '',
  nomconjoint_text: '',
  prenomconjoint_text: '',
  c: '',
  affiliationconjoint_text: '',
  roenf1_text: '',
  dnenf1_text: '',
  nomenf1_text: '',
  prenomenf1_text: '',
  inseeenf1_text: '',
  affiliationenf1_text: '',
  roenf2_text: '',
  dnenf2_text: '',
  nomenf2_text: '',
  prenomenf2_text: '',
  affiliationenf2_text: '',
  inseeenf2_text: '',
  roenf3_text: '',
  dnenf3_text: '',
  nomenf3_text: '',
  prenomenf3_text: '',
  inseeenf3_text: '',
  affiliationenf3_text: '',
  dnenf4_text: '',
  nomenf4_text: '',
  prenomenf4_text: '',
  roenf4_text: '',
  inseeenf4_text: '',
  affiliationenf4_text: '',
  dnenf5_text: '',
  nomenf5_text: '',
  prenomenf5_text: '',
  inseeenf5_text: '',
  affiliationenf5_text: '',
  roenf5_text: '',
  dnenf6_text: '',
  nomenf6_text: '',
  prenomenf6_text: '',
  inseeenf6_text: '',
  affiliationenf6_text: '',
  roenf6_text: '',
  cie_text: '',
  produit_text: '',
  villeassure_text: '',
  datesous_text: '',
  nomprenomproducteur_text: '',
  nomprenomassure_text: '',
  'concordancebesoins_garantie||Avantages concordance avec les besoins||20': '',
  'offre1_garantie||Par téléphone||22': '',
  'offre2_garantie||Par mail||23': '',
  'offre3_garantie||Par courrier||24': '',
  nbreenfantsassure_text: '',
  "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5":
    '',
  "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6":
    '',
  "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7":
    '',
  "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8":
    '',
  'refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9':
    '',
  'hospi_garantie||Hospitalisation||11': '',
  'dentaire_garantie||Dentaire||12': '',
  'optique_garantie||Optique||13': '',
  'soins_garantie||Soins courants||14': '',
  'meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15':
    '',
  'budegtmensuel_garantie||Si oui: lequel ?||10': '',
  'meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16':
    '',
  'meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleur couverture ?||17':
    '',
  'rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19': '',
  "signature_garantie||Cette fiche d’information et de conseil a été signée dans le cadre d'une adhésion Signée de façon||21":
    '',
  "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18":
    '',
  'declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19':
    '',
  'nom_courtier_hidden||Courtier||0': '',
  'rcs_courtier||RCS||1': '',
  'orias_courtier||N° ORIAS||2': '',
  'adresse_courtier||Adresse||3': '',
  'adrcorrespondance_courtier||Adresse de correspondance||4': '',
  'civilite_producteur||Civilité||0': '',
  'nom_producteur||Nom||1': '',
  'prenom_producteur||Prénom||2': '',
  'qualite_producteur||Agissant en qualité||3': '',
  'orias_producteur||ORIAS N°||4': '',
  'tel_producteur||Téléphone||5': '',
  prefix_mobile_assure: '',
  suffix_mobile_assure: '',
  'tax_aesio_mutuelle||Taxe Assurance||3': '',
  'cfdp_tax_as||Taxe Assurance||6': '',
  'mutuaide_taxe1_sante||HT Taxe Assurance||9': '',
  "date_effet_sante||Date d'effet||22": '',
};

export default newData;
