import React from "react";
import {
  Row,
  Col,
  Button,
  //  Progress
} from "reactstrap";
import classes from "./Propositions.module.css";
import SliderComponent from "./../../../components/SliderComponent/SliderComponent";
import axios from "../../../utils/axios";
import GlobalContext from "../../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import SessionStorageService from "../../../utils/sessionStorageService";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import GarantiesTable from "./GarantiesTable/GarantiesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { Empty } from "antd";
const Propositions = (props) => {
  const {
    prospectData,
    badgetvalue,
    propositions,
    setPropositions,
    tarifs,
    setOptions,
    setCots,
    setTarifs,
    setAp,
    ap,
    setProducts,
  } = React.useContext(GlobalContext);
  const { garantiesFilterValues, customerInformation, dateEffet } = props;

  const [comparison, setComparison] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loaderEmpty, setLoaderEmpty] = React.useState(true);
  const [loaderFail, setLoaderFail] = React.useState(true);
  const navigate = useNavigate();

  let option = [ap?.find((e) => e.produit?.produit_sante?.option === "4")];

  const getRo = (ro) => {
    switch (ro) {
      case "Régime général":
        return "1";
      // eslint-disable-next-line no-fallthrough
      case "Travailleurs non salariés":
        return "2";
      // eslint-disable-next-line no-fallthrough
      case "Agricole salarié":
        return "3";
      // eslint-disable-next-line no-fallthrough
      case "Agricole exploitant":
        return "4";
      // eslint-disable-next-line no-fallthrough
      case "Régime local":
        return "5";
      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  };

  React.useEffect(() => {
    // createOpp();
    let roAssuré = prospectData?.ro;

    const id = new URLSearchParams(window.location.search).get(
      "partnership_id"
    );
    let data = {
      date_effet: dateEffet,
      prospects: [
        {
          ro: prospectData?.ro,
          annee_nais: SessionStorageService().getProspect()["DN"],
          nature: "Assuré",
          code_postal:SessionStorageService().getCustomerInfo()[0]?.address?.zip_code
        },
      ],
      object: garantiesFilterValues,
    };
    if (customerInformation?.length > 1) {
      customerInformation
        .filter((element) => element.relationship_rank >= 2)
        .forEach((element) => {
          data.prospects.push({
            ro: getRo(element?.job?.regime),
            annee_nais: moment(element?.birth_info?.date, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            nature: element.relationship_rank > 2 ? "Children" : "Conjoint",
          });
        });
    }

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/prod_garanties/get_produit_by_ligne_produit/10/${roAssuré}`,
        data,
        // {
        //   prospects: [
        //     {
        //       ro: ro,
        //       annee_nais: SessionStorageService().getProspect()["DN"],
        //       nature: "Assuré",
        //     },
        //   ],
        //   object: garantiesFilterValues,
        // },
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: id,
          },
        }
      )
      .then((res) => {
        if (res?.data?.produit?.length !== 0) {
          sessionStorage.setItem(
            "notice_information",
            res.data?.notice_information
          );
          /*console.log(res.data); */ sessionStorage.setItem(
            "ipid",
            res.data?.ipid
          );
          /* console.log(res.data.produit); */ setPropositions(
            res.data?.produit
          );
          setAp(res.data?.produit);
          sessionStorage.setItem("ap", JSON.stringify(res.data?.produit));
          let productsIds = {};
          let tempProducts = [];
          //create an array of products ids length of customerInformation
          res.data.produit.forEach((product, index) => {
            // let temp = [];
            if (
              tempProducts.find(
                (element) => element.nom_cie === product.produit.nom_cie
              ) === undefined
            )
              tempProducts.push({
                nom_cie: product.produit.nom_cie,
                nom_produit: product.produit.produit_sante.nom_produit,
                gamme: product.produit.produit_sante?.game,
                id: product.produit.produit_sante?.cotation_id,
              });
            for (let i = 0; i < customerInformation.length; i++) {
              // temp.push(product?.produit?.produit_sante?.cotation_id);
              productsIds[index] = Array(customerInformation.length).fill([
                parseInt(product?.produit?.produit_sante?.cotation_id),
              ]);
            }
          });
          setProducts(tempProducts);
          productsIds[res.data.produit.length] = Array(
            customerInformation.length
          ).fill([parseInt(process.env.REACT_APP_ASSISTANCE_CONTRACT)]);
          productsIds[res.data.produit.length + 1] = Array(
            customerInformation.length
          ).fill([
            parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
          ]);
          let result = res.data.produit;
          // sessionStorage.setItem(
          //   'customerInfo',
          //   JSON.stringify(customerInformation)
          // );
          // console.log('masspricing call');
          let customerInformations = SessionStorageService().getCustomerInfo();

          axios
            .post(
              `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_mass_pricing`,
              {
                business_introducer: {
                  login: "Z04",
                },
                third_app: {
                  name: "ASolutions",
                  id_external: 476820,
                },
                customer_information: customerInformations,
                contract_information: {
                  effective_date: moment(dateEffet, "DD-MM-YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  mode_splitting: "Mensuel",
                },
                products: productsIds,
              },
              {
                headers: {
                  idSession: localStorage.getItem("token"),
                },
              }
            )
            .then((data) => {
              let dataWithTarifs = data.data.data
                .filter(
                  (e) =>
                    e.product_range !== "Protection Juridique" &&
                    e.product_range !== "Assistance"
                )
                .filter((e) => e.success === true);
              if (dataWithTarifs.length === 0) {
                setLoaderEmpty(false);
              }
              // console.log(data.data.data.filter((e)=>e.success===true));
              /* console.log('data:', data); */ const PJ = data.data.data.find(
                (d) => d.product_range === "Protection Juridique"
              );
              const AS = data.data.data.find(
                (d) => d.product_range === "Assistance"
              );

              let object = {
                PJ: PJ,
                AS: AS,
              };

              setCots(data.data.data);

              setOptions(object);

              let tempTarifs = {};
              Object.keys(productsIds).forEach((key, index) => {
                tempTarifs[productsIds[key][0][0]] = data.data.data.find(
                  (product) => product.number === index.toString()
                )?.total_monthly;
              });
              setTarifs(tempTarifs);

              if (tempTarifs) {
                let temp = [...result];
                Object.keys(tempTarifs).forEach((key) => {
                  if (
                    tempTarifs[key] < badgetvalue[0] ||
                    tempTarifs[key] > badgetvalue[1] ||
                    !tempTarifs[key]
                  ) {
                    temp.forEach((val, index) => {
                      if (
                        val?.produit?.produit_sante?.cotation_id.toString() ===
                        key
                      ) {
                        temp.splice(index, 1);
                      }
                    });
                  }
                });

                // if(temp.length === 0){
                //   // const option = [ap.find((e)=>e.produit.produit_sante.option==='4')];
                //   setPropositions(option);
                //   // return null;
                // }

                //   else{
                setPropositions(temp);

                // }

                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setLoaderFail(false);
              // setPropositions([]);
            });
        } else {
          setLoading(false);
          setLoaderEmpty(false);
          // setPropositions([]);
        }
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
        setLoaderFail(false);

        // setPropositions([]);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (comparison.length >= 2) {
      SessionStorageService().setBareComparison(comparison);
      sessionStorage.setItem("reset", "1");
      let apiKey = window.location.search
      navigate(`/tableauComparatif${apiKey}`, {
        state: {
          comparison,
          tarifs,
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Vous devez choisir au moins deux propositions !",
      });
    }
  };
  function isArrayUndefined(arr) {
    return arr.length === 0 || (arr.length === 1 && arr[0] === undefined);
  }
  return (
    <Row className={classes.PropositionsContainer}>
      <Col>
        <SliderComponent />
        <div className={classes.topButtons}>
          <Button
            color="primary"
            onClick={handleClick}
            disabled={comparison.length < 2}
            size="lg"
            style={{ borderRadius: "10px", width: "20rem" }}
          >
            <FontAwesomeIcon
              icon={faScaleBalanced}
              style={{ marginRight: "5px" }}
            />{" "}
            Comparer
          </Button>
        </div>
        {/* <div className={classes.progressContainer}>
          <div className='text-center'>50%</div>
          <Progress value={50} />
        </div> */}
        {/* <div className={classes.statsWidgetContainer}>
         {loading===false?<StatsWidget
            num={ Object.keys(propositions).length }
            txt={ Object.keys(propositions).length===1?"Tarif":"Tarifs"}
          />:"" } */}
        {/* <StatsWidget
            num={propositions && propositions.length}
            txt={"Possibilités du tarification"}
          /> */}
        {/* </div> */}
        <div>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : loaderFail && loaderEmpty && !isArrayUndefined(propositions) ? (
            <div className={classes.scroll} style={{ overflowX: "auto" }}>
              {" "}
              <GarantiesTable
                setLoaderEmpty={setLoaderEmpty}
                option={option}
                setPropositions={setPropositions}
                badgetvalue={badgetvalue}
                propositions={propositions}
                tarifs={tarifs}
                comparison={comparison}
                setComparison={setComparison}
              />
            </div>
          ) : !loaderEmpty || isArrayUndefined(propositions) ? (
            <Empty
              description={
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Aucune proposition de produit disponible pour le moment.
                </span>
              }
            />
          ) : (
            ""
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Propositions;
