import React from 'react';
import { Col, Row } from 'reactstrap';
import Member from './Member/Member';
import classes from './GroupMember.module.css';

import SessionStorageService from '../../../utils/sessionStorageService';

function GroupMember() {
  //eslint-disable-next-line
  const [customerInformation, setCostumerInformation] = React.useState(
    SessionStorageService().getCustomerInfo() ? SessionStorageService().getCustomerInfo(): []
  );

  let childCount = 0;
  // let conjointCount = 0;

  return (
    <div className={classes.cotisation}>
      <Row>
        {customerInformation.map((CI, index) => {
          if (CI.relationship === 'primary subscriber') {
            return (
              <Col xs='12' lg='6' key={index}>
                {' '}
                <Member
                  name={`Assuré`}
                  birth_date={CI.birth_info.date}
                  RO={CI.job.regime}
                  member={CI}
                />
              </Col>
            );
          } else if (CI.relationship === 'children') {
            childCount += 1;
            return (
              <Col xs='12' lg='6' key={index}>
                {' '}
                <Member
                  name={`Enfant ${childCount}`}
                  birth_date={CI.birth_info.date}
                  RO={CI.job.regime}
                  member={CI}
                />
              </Col>
            );
          } else if (CI.relationship === 'married') {
            // conjointCount += 1;
            return (
              <Col xs='12' lg='6' key={index}>
                <Member
                  name={`Conjoint `}
                  birth_date={CI.birth_info.date}
                  RO={CI.job.regime}
                  member={CI}
                />
              </Col>
            );
          }
          return null;
        })}
      </Row>
    </div>
  );
}

export default GroupMember;
