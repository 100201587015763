import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import classes from './Budget.module.css';
import SliderComponent from './../../../components/SliderComponent/SliderComponent';
import GarantiesComponent from './GarantiesComponent/GarantiesComponent';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Button } from 'antd';

const Budget = (props) => {
  const {
    groupeGaranties,
    garantiesFilterValues,
    setGarantiesFilterValues,
    grpGarantieOptique,
  } = props;

  const [modal, setModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [filterTempValues, setFilterTempValues] = React.useState(
    garantiesFilterValues
  );

  const toggle = (persist = false) => {
    setModal(!modal);
    if (persist) {
      setGarantiesFilterValues(filterTempValues);
    } else {
      setGarantiesFilterValues(garantiesFilterValues);
      setFilterTempValues(garantiesFilterValues);
    }
  };
  return (
    <Row className={classes.budgetContainer}>
      <Modal
        centered={true}
        size='lg'
        isOpen={modal}
        toggle={() => {
          toggle(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            toggle(false);
          }}
        ></ModalHeader>
        {modalData && (
          <ModalBody>
            <div className={classes.modalBodyStyle}>
              <Row
                style={{
                  backgroundColor: '#D2DFEC',
                  paddingBlock: '1rem',
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={require('../../../assets/assuree.png')}
                      alt='hospitalisation'
                      style={{ marginRight: '1rem' }}
                    />
                    <h5>{modalData?.nom_groupe}</h5>
                  </div>
                </Col>
              </Row>
              {modalData?.list?.map((item, index) => (
                <Row
                  key={index}
                  style={{
                    width: '100%',
                    paddingInline: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Col>{item?.libelle}</Col>
                  <Col>
                    <Box
                      width={300}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span className={classes.filterSliderLabels}>
                        {(item?.val_min || '') + (item?.type || '')}
                      </span>
                      <Slider
                        onChange={(event, value) => {
                          const newState = filterTempValues.map((obj) => {
                            if (obj.name === item.id) {
                              return { ...obj, value: value.toString() };
                            }

                            // 👇️ otherwise return object as is
                            return obj;
                          });
                          setFilterTempValues(newState);

                          // setGarantiesFilterValues([
                          //   ...garantiesFilterValues,
                          //   { name: item.id, value: value.toString() },
                          // ]);
                          // garantiesFilterValues.find(
                          //   (v) => v.name === item.id
                          // ).value = value;
                        }}
                        defaultValue={
                          parseInt(
                            filterTempValues?.find((x) => x.name === item.id)
                              ?.value
                          ) || 0
                        }
                        value={parseInt(
                          filterTempValues?.find((v) => v.name === item.id)
                            ?.value
                        )}
                        valueLabelDisplay='auto'
                        min={parseInt(item?.val_min)}
                        max={parseInt(item?.val_max)}
                        // marks={[
                        //   {
                        //     value: parseInt(item?.val_min),
                        //     label: item?.val_min + item?.type,
                        //   },
                        //   {
                        //     value: parseInt(item?.val_max),
                        //     label: item?.val_max + item?.type,
                        //   },
                        // ]}
                      />
                      <span className={classes.filterSliderLabels}>
                        {(item?.val_max || '') + (item?.type || '')}
                      </span>
                    </Box>
                  </Col>
                </Row>
              ))}
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Annuler
          </Button>
          <Button
            type='primary'
            onClick={() => {
              toggle(true);
            }}
          >
            Valider
          </Button>
        </ModalFooter>
      </Modal>
      <Col>
        <SliderComponent />
        <GarantiesComponent
          toggle={toggle}
          groupeGaranties={groupeGaranties.find(
            (item) => item.nom_groupe === 'Hospitalisation'
          )}
          setGarantiesFilterValues={setGarantiesFilterValues}
          garantiesFilterValues={garantiesFilterValues}
          setModalData={setModalData}
          modalData={modalData}
          color={'#DCE1F5'}
          btn={true}
          items={[
            '<= 125€',
            '> 125€ et <=150€',
            '> 150€ et <= 250€',
            '> 250€',
            'Indifférent',
          ]}
          title={
            "Hospitalisation (dépassement d'honoraires, prestations de confort)"
          }
        />
        <GarantiesComponent
          setGarantiesFilterValues={setGarantiesFilterValues}
          garantiesFilterValues={garantiesFilterValues}
          groupeGaranties={groupeGaranties.find(
            (item) => item.nom_groupe === 'Soins courants'
          )}
          toggle={toggle}
          setModalData={setModalData}
          modalData={modalData}
          color={'#EFF2FF'}
          btn={true}
          items={[
            '<= 125€',
            '> 125€ et <=150€',
            '> 150€ et <= 250€',
            '> 250€',
            'Indifférent',
          ]}
          title={'Soins courants(consultations, pharmacie)'}
        />
        <GarantiesComponent
          setModalData={setModalData}
          modalData={modalData}
          color={'#DCE1F5'}
          btn={false}
          items={['Oui', 'Non', 'Indifférent']}
          title={'Médecines douces'}
        />
        <GarantiesComponent
          setGarantiesFilterValues={setGarantiesFilterValues}
          garantiesFilterValues={garantiesFilterValues}
          groupeGaranties={groupeGaranties.find(
            (item) => item?.nom_groupe === 'Dentaire'
          )}
          toggle={toggle}
          setModalData={setModalData}
          modalData={modalData}
          color={'#EFF2FF'}
          btn={true}
          items={[
            '<= 125€',
            '> 125€ et <=150€',
            '> 150€ et <= 250€',
            '> 250€',
            'Indifférent',
          ]}
          title={'Dentaire (prothèses, orthodontie)'}
        />
        <GarantiesComponent
          setGarantiesFilterValues={setGarantiesFilterValues}
          garantiesFilterValues={garantiesFilterValues}
          groupeGaranties={grpGarantieOptique}
          toggle={toggle}
          setModalData={setModalData}
          modalData={modalData}
          color={'#DCE1F5'}
          btn={true}
          items={['Verres progressifs', 'Verres simples', 'Indifférent']}
          title={'Optique'}
        />
        {/* <OptionHorsSanteComponent
          color={"#E6DDD0"}
          items={["Assistance", "Protection juridique"]}
          title={"Options Hors santé"}
        /> */}
      </Col>
    </Row>
  );
};

export default Budget;
