import React from "react";
import { Col, Row } from "antd";
import Member from "./Member/Member";
import SessionStorageService from "../../../../utils/sessionStorageService";
function GroupMember(props) {
  const customerInformation = props?.customerInfo
    ? props?.customerInfo
    : SessionStorageService().getCustomerInfo();

  let childCount = 0;
  // let conjointCount = 0;
  let key = 1;

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Row
        gutter={24}
        style={{
          width: "95%",
          background: "white",
          paddingInline: "1rem",
          paddingBlock: "1rem",
        }}
      >
        {customerInformation &&
          customerInformation?.map((CI) => {
            key++;
            if (CI.relationship === "primary subscriber") {
              return (
                <Col xs={24} sm={24} md={24} lg={24} xl={24} key={key}>
                  <Member
                    count={childCount}
                    name={`Assuré`}
                    birth_date={CI.birth_info.date}
                    ro={CI.job.regime}
                  />
                </Col>
              );
            } else if (CI.relationship === "children") {
              childCount += 1;
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  key={key.toString()}
                >
                  {" "}
                  <Member
                    count={childCount}
                    name={`Enfant ${childCount}`}
                    birth_date={CI.birth_info.date}
                    ro={CI.job.regime}
                  />
                </Col>
              );
            } else if (CI.relationship === "married") {
              // conjointCount += 1;
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  key={key.toString()}
                >
                  <Member
                    // count={conjointCount}
                    name={`Conjoint `}
                    birth_date={CI.birth_info.date}
                    ro={CI.job.regime}
                  />
                </Col>
              );
            }
            return null;
          })}
      </Row>
    </div>
  );
}

export default GroupMember;
