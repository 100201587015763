import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import headerImage from '../DevisTarificateur/Img-header.png';
import DevisTarificateurCard from './DevisTarificateurCard/DevisTarificateurCard';
import PrincipalesGaranties from './PrincipalesGaranties/PrincipalesGaranties';
// eslint-disable-next-line no-unused-vars
import { Link, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  Row,
  Col,
  Table,
  Container,
} from 'reactstrap';
import classes from './DevisTarificateur.module.css';
import SendEmail from '../SendEmail/SendEmail';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import GlobalContext from '../../../../contexts/GlobalContext';
// import ReactToPrint from 'react'
const DevisTarifURL = () => {
  const { id } = useParams();

  const {
    modal,
    setModal,
    genDate,
    tarif,
    prospect,
    proposition,
    fetchComparison,
    notice_information,
    customerInformation,
    entreprise,
    image,
    numero,
    numDevis,
  } = React.useContext(GlobalContext);

  const [loaded, setLoaded] = React.useState(false);

  let [customerRelations, setCustomerRelations] = React.useState(null);

  React.useEffect(() => {
     id && fetchComparison(id);


    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    if (customerInformation) {
      setLoaded(true);
      const CR = customerInformation;
      setCustomerRelations(CR);
    }
  }, [customerInformation]);

  const printRef = useRef();
  const [open, setOpen] = useState('0');

  const togglemodal = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  /* const handleDownloadPdf = async () => {
    let element = printRef.current;
    let canvas = await html2canvas(
      element,
      {
        onclone: function (document, el) {
          const buttons = el.querySelectorAll("." + classes.noPrint);
          for (let btn of buttons) btn.classList.add(classes.hide);
        },
      },
      {
        onclone: function (document, el) {
          const buttons = el.querySelectorAll("." + classes.noPrint);
          for (let btn of buttons) btn.classList.add(classes.hide);
        },
      }
    );
    let data = canvas?.toDataURL("image/png");

    let pdf = new jsPDF();
    let imgProperties = pdf?.getImageProperties(data);
    let pdfHeight = pdf.internal.pageSize.getHeight() - 20;
    let pdfWidth = (imgProperties?.width * pdfHeight) / imgProperties?.height;
    pdf.addImage(
      data,
      "PNG",
      (pdf.internal.pageSize.getWidth() - pdfWidth + 35) / 2,
      10,
      pdfWidth - 35,
      pdfHeight
    );

    pdf.save("devis.pdf");
  }; */
  const toggle = () => {
    setModal(!modal);
  };
  return !loaded ? (
    <div>Loader will be here</div>
  ) : (
    <>
      {entreprise && (
        <div ref={printRef}>
          <Container
            fluid
            style={{
              paddingInline: '4rem',
              backgroundColor: 'white',
            }}
          >
            <Row style={{ position: 'relative' }}>
              <img
                className={classes.headerImage}
                src={headerImage}
                alt='headerImage'
              />
              {image && (
                <img
                  className={classes.head}
                  alt='logo'
                  src={`data:image/png;base64,${image}`}
                />
              )}
              <h1 className={classes.headerTitle}>Votre devis</h1>
            </Row>
            <Row style={{ margin: '3rem' }}>
              <Col lg={4} className={classes.devisContainer}>
                <h2>Devis n° {numDevis}</h2>
              </Col>
              <Col lg={4}>
                <h2 style={{ textAlign: 'center' }}>
                  {entreprise.nom_organisme_master}
                </h2>
              </Col>
              {numero && (
                <Col
                  lg='4'
                  xs='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={classes.phoneIcon}
                  />
                  <h3 className={classes.phoneNumber}> {numero} </h3>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs='12'>
                <h4>
                  Date de génération : {moment().format("DD/MM/YYYY")}
                </h4>
                <h4>Offre valable pour 60 jours.</h4>
              </Col>
            </Row>

            <Row xs='12' className={classes.grayDiv}>
              <Row>
                <Col xs='12' style={{ padding: '1.5rem' }}>
                  <p className={classes.devis}>
                    {' '}
                    Bonjour, {prospect?.civilite} {prospect?.name}{' '}
                    {prospect?.surname} Habitant à{' '}
                    {customerInformation[0].address?.country}{' '}
                    {customerInformation[0].address?.locality}
                  </p>
                  <p style={{ fontSize: '1.5rem' }}>
                    Merci de nous avoir choisi pour l'élaboration de votre étude
                    personnalisée.
                    <br />
                    Suite à notre entretien, vous trouverez ci-dessous le
                    récapitulatif de la (des) garantie(s) que nous avons
                    sélectionnée(s) ensemble, les tarifs ainsi que des
                    liens pour les consulter.
                  </p>
                </Col>{' '}
              </Row>
              <Row className={classes.anneeEffetContainer}>
                <Col xs='6'>
                  <h3 className={classes.Asolutions}>Mutuelle : AESIO</h3>
                </Col>
                <Col
                  xs='6'
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <h3 className={classes.Asolutions}>
                    ANNÉE D'EFFET :{' '}
                    {moment(prospect?.dateEffet, 'DD-MM-YYYY').format('YYYY')}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs='12'>
                  <Accordion
                    open={open}
                    toggle={togglemodal}
                    className={classes.accordion}
                  >
                    <AccordionItem className={classes.accordionItem}>
                      {/* <AccordionHeader targetId="1">
                      <p className={classes.accordionHeader}>
                        [+] Bénéficiaires
                      </p>
                    </AccordionHeader> */}
                      <AccordionBody accordionId='1'>
                        <ul>
                          <Row className={classes.accordionBody}>
                            <Col>
                              <li
                                style={{
                                  fontSize: '1.5rem',
                                }}
                              >
                                Assuré :
                              </li>
                            </Col>
                            <Col>
                              {' '}
                              <p className={classes.p}> 45.08 €</p>
                            </Col>
                          </Row>

                          <Row className={classes.accordionBody}>
                            <Col>
                              <li
                                style={{
                                  fontSize: '1.5rem',
                                }}
                              >
                                Conjoint:
                              </li>
                            </Col>
                            <Col>
                              {' '}
                              <p className={classes.p}> 61.95 €</p>
                            </Col>
                          </Row>
                        </ul>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>

              <Row>
                <Col xs='12' lg='4'>
                  <DevisTarificateurCard
                    title='PRODUIT'
                    content={proposition?.produit?.produit_sante?.nom_produit}
                  />
                </Col>
                <Col xs='12' lg='4'>
                  <DevisTarificateurCard
                    title='GARANTIES'
                    content={`${proposition?.produit?.produit_sante?.option}`}
                  />
                </Col>
                <Col xs='12' lg='4'>
                  <DevisTarificateurCard
                    title='COTISATION'
                    content={`${tarif} €/mois *`}
                  />
                </Col>
              </Row>
            </Row>
            {customerRelations.length >= 1 ? (
              <Row className={classes.table}>
                <Table bordered>
                  <thead className={classes.tableheader}>
                    <tr>
                      <th>Bénéficier</th>
                      <th>Date de naissance</th>
                      <th>RO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerRelations.map((val, index) => {
                      var type = '';
                      var ro = '';
                      switch (val.relationship) {
                        case 'married':
                          type = 'Conjoint';
                          break;
                        case 'children':
                          type = 'Enfant';
                          break;
                        case 'primary subscriber':
                          type = 'adhérent';
                          break;
                        default:
                          break;
                      }
                      switch (val.job.regime) {
                        case 'Régime local':
                          ro = 'Alsace Moselle';
                          break;
                        case 'Régime général':
                          ro = 'Sécurité Sociale';
                          break;
                        default:
                          ro = val.job.regime;
                          break;
                      }
                      return (
                        <tr
                          className={
                            val.relationship === 'married'
                              ? 'table-danger'
                              : 'table-light'
                          }
                          key={index}
                        >
                          <td>{type}</td>
                          <td>{val.birth_info.date}</td>
                          <td>{ro}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a
                  href={notice_information}
                  target='_blank'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  rel='noreferrer'
                >
                  <img
                    className={classes.pdfImage}
                    alt='pdf_icon'
                    src='https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png'
                  />
                  <p style={{ fontSize: '1.5rem', color: 'black' }}>
                    Conditions générales
                  </p>
                </a>
              </Col>
              <Col>
                <a
                  href={proposition?.tableau_garantie[0]}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    className={classes.pdfImage}
                    alt='pdf_icon'
                    src='https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png'
                  />

                  <p style={{ fontSize: '1.5rem', color: 'black' }}>
                    Tableau de garanties{' '}
                  </p>
                </a>
              </Col>
              <Col>
                <a
                  href={proposition?.produit?.ipid}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'black',
                    alignItems: 'center',
                  }}
                >
                  <img
                    className={classes.pdfImage}
                    alt='pdf_icon'
                    src='https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png'
                  />

                  <p style={{ fontSize: '1.5rem' }}>Fiches IPID </p>
                </a>
              </Col>
            </Row>

            {/*  <Row className={classes.grayDiv}>
            <p className={classes.principalesGaranties}>
              Vos Principales Garanties<span style={{ color: "red" }}>**</span>
            </p>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" HOSPITALISATION"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Hospitalisation_2.png"
                data={[
                  {
                    key: "Honoraires",
                    value: `${proposition.garanties.find((item)=>item.name==="ex22").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex22").type}`,
                  },
                  {
                    key: "Chambre particulière",
                    value: `${proposition.garanties.find((item)=>item.name==="ex23").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex23").type}`,
                  },
                ]}
              />
            </Col>
           
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" OPTIQUE"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Optique_2.png"
                data={[
                  {
                    key: "Monture + Verres simples",
                    value: `${proposition.garanties.find((item)=>item.name==="ex41").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex41").type}`,
                  },
                  {
                    key: "Monture + Verres complexes",
                    value: `${proposition.garanties.find((item)=>item.name==="pb").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pb").type}`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" SOINS COURANTS"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Soins courants_2.png"
                data={[
                  {
                    key: "Consultations",
                    value: `${proposition.garanties.find((item)=>item.name==="pbo").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbo").type}`,
                  },
                  {
                    key: "Labo & Auxiliaires",
                    value: `${proposition.garanties.find((item)=>item.name==="pbob").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex44").type}`,
                  },
                  {
                    key: `${proposition.garanties.find((item)=>item.name==="pnr").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pnr").type}`,
                    value: "-",
                  },
                  {
                    key: "Radiologie",
                    value: `${proposition.garanties.find((item)=>item.name==="conforme").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="conforme").type}`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title="PHARMACIE"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Pharmacie_2.png"
                data={[
                  {
                    key: "Médicaments - Rendu important",
                    value: `${proposition.garanties.find((item)=>item.name==="pb").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pb").type}`,
                  },
                  {
                    key: "Médicaments - Rendu modéré",
                    value: `${proposition.garanties.find((item)=>item.name==="pbo").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbo").type}`,
                  },
                  {
                    key: "Médicaments - Rendu faible",
                    value: `${proposition.garanties.find((item)=>item.name==="pbob").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbob").type}`,
                  },
                  {
                    key: "Pharmacie non remboursée",
                    value: `${proposition.garanties.find((item)=>item.name==="pnr").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pnr").type}`,
                  },
                ]}
              />
            </Col>

            <Row>
              <Col xs="12" className={classes.etoile}>
                <p className={classes.etoile_p}>(*) </p>{" "}
                <h6 className={classes.tarif_h6}>
                  Tarif valable 60 jours, hors offre promotionnelle
                </h6>
              </Col>
            </Row> */}
            <Row className={classes.grayDiv}>
              <p className={classes.principalesGaranties}>
                Vos garanties principales
              </p>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title=' HOSPITALISATION'
                  icon='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Hospitalisation_2.png'
                  data={[
                    {
                      key: 'Honoraires',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex22'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex22'
                        ).type
                      }`,
                    },
                    {
                      key: 'Chambre particulière',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex23'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex32'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title='DENTAIRE'
                  icon='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Dentaire_2.png'
                  data={[
                    {
                      key: 'Soins',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex41'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex41'
                        ).type
                      }`,
                    },
                    {
                      key: 'Prothèse',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex42'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex42'
                        ).type
                      }`,
                    },
                    {
                      key: 'Orthodontie',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex44'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex44'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title=' OPTIQUE'
                  icon='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Optique_2.png'
                  data={[
                    {
                      key: 'Monture + Verres simples',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex31'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex31'
                        ).type
                      }`,
                    },
                    {
                      key: 'Monture + Verres complexes',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex32'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex32'
                        ).type
                      }`,
                    },
                    {
                      key: 'Lentille remboursé par la ss',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex33'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex33'
                        ).type
                      }`,
                    },
                    {
                      key: 'Chirurgie refractive',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex34'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex34'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title=' SOINS COURANTS'
                  icon='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Soins courants_2.png'
                  data={[
                    {
                      key: 'Consultations',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex11'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex11'
                        ).type
                      }`,
                    },
                    {
                      key: 'Labo ',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex17'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex17'
                        ).type
                      }`,
                    },
                    {
                      key: 'Auxiliaires medicaux ',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex12'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex12'
                        ).type
                      }`,
                    },
                    {
                      key: 'Médecine alternative',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex16'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex16'
                        ).type
                      }`,
                    },
                    {
                      key: 'Radiologie',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex19'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'ex19'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title='PHARMACIE'
                  icon='https://mutuelle-direct.cloud.geoprod.com/images/garanties/Pharmacie_2.png'
                  data={[
                    {
                      key: 'Médicaments-important ',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pb'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pb'
                        ).type
                      }`,
                    },
                    {
                      key: 'Médicaments - Rendu modéré',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pbo'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pbo'
                        ).type
                      }`,
                    },
                    {
                      key: 'Médicaments - Rendu faible',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pbob'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pbob'
                        ).type
                      }`,
                    },
                    {
                      key: 'Pharmacie non remboursée',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pnr'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'pnr'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>
              <Col xs='12' lg='4'>
                <PrincipalesGaranties
                  title='Audio prothèse'
                  icon='https://sdn.geoprod.com/static/uploads/cmp/353508955/1665402132.160585__ear.png'
                  data={[
                    {
                      key: 'Audio prothèse',
                      value: `${
                        proposition?.garanties.find(
                          (item) => item?.name === 'or1'
                        ).valeur_affiche
                      } ${
                        proposition?.garanties.find(
                          (item) => item?.name === 'or1'
                        ).type
                      }`,
                    },
                  ]}
                />
              </Col>

              <Row>
                <Col xs='12' className={classes.etoile}>
                  <p className={classes.etoile_p}>(*) </p>{' '}
                  <h6 className={classes.tarif_h6}>
                    Tarif valable 60 jours, hors offre promotionnelle
                  </h6>
                </Col>
              </Row>

              <Row>
                <Col className={classes.etoile}>
                  <p className={classes.doubleEtoile_p}>(**) </p>
                  <h6 className={classes.doubleEtoile_h6}>
                    {' '}
                    Pour l’exhaustivité du tableau de garanties, voir les liens
                    ci-dessus tableau de garanties et notice
                  </h6>
                </Col>
              </Row>

              {/* <Row style={{ padding: "2rem" }}>
                <Col xs="12" className={classes.buttons}>
                  <Button
                    onClick={handleDownloadPdf}
                    className={classes.pdfButton}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className={classes.pdfIcon}
                    />
                    ExporterPdf
                  </Button>

                  <Button
                onClick={() => {
                  window.print();
                }}
                className={classes.printButton}
              >
                <FontAwesomeIcon icon={faPrint} className={classes.printIcon} />{" "}
                Imprimer
              </Button>

                   <ReactToPrint
                  trigger={() => (
                    <Button className={classes.printButton}>
                      <FontAwesomeIcon
                        icon={faPrint}
                        className={classes.printIcon}
                      />{" "}
                      Imprimer
                    </Button>

                     <ReactToPrint
                  trigger={() => (
                    <Button className={classes.printButton}>
                      <FontAwesomeIcon
                        icon={faPrint}
                        className={classes.printIcon}
                      />{" "}
                      Imprimer
                    </Button>
                  )}
                  pageStyle=" @media print { body { -webkit-print-color-adjust: exact; padding: 30px ; } }"
                  
                /> 
                </Col>
              </Row> */}
            </Row>

            <Row>
              <Col>
                <h5 className={classes.h5}>
                  Je reste à votre entière disposition pour tout complément
                  d'informations.
                </h5>
                <h5 className={classes.h5}>
                  Je vous remercie de la confiance que vous nous témoignez, en
                  espérant avoir répondu à vos attentes.
                </h5>
                <h5 className={classes.cordialement_h5}>Cordialement.</h5>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <SendEmail toggle={toggle} modal={modal} />
    </>
  );
};

export default DevisTarifURL;
