export const checkSelectedGamme = (gamme, proposition = "") => {
  if (
    JSON.parse(gamme).includes(
      sessionStorage.getItem("selectedGamme") ||
        proposition?.produit.produit_sante.game
    )
  ) {
    return true;
  } else {
    return false;
  }
};
