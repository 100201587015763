import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import GlobalContext from "../../../contexts/GlobalContext";
import axios from "../../../utils/axios";
import { Form, Select, Input, Button, notification, Radio, DatePicker } from "antd";
import { alertMessage } from "../../../utils/alertFunction";
import SessionStorageService from "../../../utils/sessionStorageService";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";

const ResiliationPopup = ({ modal, toggle, stage, setStage }) => {
  const { resiliation, setResiliation, dataAdhesion } =
    useContext(GlobalContext);
  const [companies, setCompanies] = useState([]);
  //eslint-disable-next-line
  const [selectedComapny, setSelectedCompany] = useState({});
  const [companiesList, setCompaniesList] = useState();
  const onSearch = (value) => {};
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const type_resiliation = [
    {
      label: "Infra-annuelle santé",
      value: "infra-annuelle santé",
    },
    {
      label: "Demande de résiliation à l'échéance",
      value: "demande de a l'échéance",
    },
  ];
  useEffect(() => {
    function fetchCompanies() {
      axios
        .get(
          `${process.env.REACT_APP_GEOPROD_APIURL}v1/list_compagnies_details`,
          {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const temp = res.data.map((elem) => {
            return { ...elem, label: elem.denomination };
          });
          setCompaniesList(temp);

          const uniqueOptions = [
            ...new Set(temp.map((item) => item.denomination)),
          ].map((denomination) => {
            const item = temp.find(
              (item) => item.denomination === denomination
            );
            return {
              value: item.label,
              label: item.label,
              key: item.label,
            };
          });

          setCompanies(uniqueOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetchCompanies();
  }, []);

  const handleNext = () => {
    if (
      !resiliation.num_ancier_contrat ||
      !resiliation.type ||
      !resiliation.nom_ancier_assureur
    ) {
      alertMessage("Veuillez remplir tous les champs obligatoires.", "error");
    } else if (
      resiliation?.type === "demande de a l'échéance" &&
      (!resiliation?.date_echeance || resiliation?.date_echeance == "Invalid Date")
    ) {
      alertMessage("Veuillez remplir tous les champs obligatoires.", "error");
    } else {
      if (
        dataAdhesion[
          "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
        ] === "Oui" &&
        !dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"]
      ) {
        toggle();
        alertMessage(
          "Afin de poursuivre la souscription, veuillez remplir le montant de votre cotisation mensuelle.",
          "error"
        );
      } else {
        notification.destroy();
        setStage(stage + 1);
        toggle();
      }
    }
  };
  const handleChange = (e, name) => {
    if (name === "date_echeance") {
      setResiliation({
        ...resiliation,
        ["date_echeance"]: dayjs(e).format("DD/MM/YYYY"),
      });
      SessionStorageService().setResiliation({
        ...resiliation,
        ["date_echeance"]: dayjs(e).format("DD/MM/YYYY"),
      });
    } else {
      setResiliation({ ...resiliation, [e.target.name]: e.target.value });
      SessionStorageService().setResiliation({
        ...resiliation,
        [e.target.name]: e.target.value,
      });
    }
  };

  // const handleChange = (e) => {
  //   if (e.target.name === "date_echeance") {
  //     setResiliation({
  //       ...resiliation,
  //       [e.target.name]: e.target.value,
  //     });
  //     SessionStorageService().setResiliation({
  //       ...resiliation,
  //       [e.target.name]: e.target.value,
  //     });
  //   } else {
  //     setResiliation({ ...resiliation, [e.target.name]: e.target.value });
  //     SessionStorageService().setResiliation({
  //       ...resiliation,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };

  const handleAutoComplete = (value) => {
    setResiliation({ ...resiliation, nom_ancier_assureur: value });
    SessionStorageService().setResiliation({
      ...resiliation,
      nom_ancier_assureur: value,
    });
  };
  const handleSelect = (option) => {
    setSelectedCompany(
      companiesList?.find((company) => company?.label === option)
    );
    handleAutoComplete(option);
    SessionStorageService().setResiliationCompany(
      companiesList?.find((company) => company?.label === option)
    );
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Résiliation</ModalHeader>
        <ModalBody>
          {/* <FormGroup tag="fieldset">
            <legend>Type :</legend>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="type"
              value={resiliation?.num_ancier_contrat ? resiliation?.num_ancier_contrat : ""}
              onChange={(e) => handleChange(e)}
            >
              <FormControlLabel
                value={'infra-annuelle santé'}
                control={<Radio />}
                label='Infra-annuelle santé'
              />
              <FormControlLabel
                value={"demande de a l'échéance"}
                control={<Radio />}
                label="Demande de résiliation à l'échéance"
              />
            </RadioGroup>
          </FormGroup>{" "} */}

          <Form layout="vertical">
            <Form.Item name="type" label="Type :" required>
              <Radio.Group
                name="type"
                onChange={(e) => handleChange(e)}
                defaultValue={resiliation?.type}
              >
                {type_resiliation?.map((e, index) => (
                  <Radio key={index} value={e.value}>
                    {e.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Nom ancien assureur :" required>
              <Select
                size="large"
                allowClear
                style={{ width: "100%" }}
                defaultValue={resiliation?.nom_ancier_assureur}
                showSearch
                optionFilterProp="children"
                onChange={(option) => handleSelect(option)}
                onSearch={onSearch}
                filterOption={filterOption}
                options={companies}
              />
            </Form.Item>
            <Form.Item
              name="num_ancier_contrat"
              label="Numéro ancien contrat :"
              required
            >
              <Input
                size="large"
                name="num_ancier_contrat"
                defaultValue={resiliation?.num_ancier_contrat}
                onChange={(e) => handleChange(e)}
              />
            </Form.Item>
            {resiliation?.type === "demande de a l'échéance" && (
              <>
                {/* <Form.Item label="Date échéance :" required>
                  <Input
                    size="large"
                    name="date_echeance"
                    type="date"
                    defaultValue={resiliation?.date_echeance}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Item> */}
                <Form.Item label="Date échéance :" required>
                  <DatePicker
                    placeholder="JJ/MM/AAAA"
                    format={"DD/MM/YYYY"}
                    locale={locale}
                    style={{ width: "100%" }}
                    size="large"
                    name="date_echeance"
                    type="date"
                    defaultValue={
                      resiliation?.date_echeance &&
                      resiliation?.date_echeance !== "Invalid Date"
                        ? dayjs(resiliation?.date_echeance, "DD/MM/YYYY")
                        : ""
                    }
                    onChange={(e) => handleChange(e, "date_echeance")}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              handleNext();
            }}
          >
            Suivant
          </Button>
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#8c8c8c", color: "white" }}
            onClick={() => {
              setResiliation({
                accepte: "Oui",
                type: "",
                nom_ancier_assureur: "",
                num_ancier_contrat: "",
                date_echeance: "",
              });
              SessionStorageService().setResiliation(resiliation);
              toggle();
            }}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ResiliationPopup;
