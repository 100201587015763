import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, DatePicker, Form, Input, Row, Select, Tooltip } from "antd";
import classes from "./Familymember.module.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SessionStorageService from "../../../../../utils/sessionStorageService";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/fr_FR";

function FamilyMember(props) {
  const {
    count,
    name,
    customerInformation,
    setCostumerInformation,
    item,
    dn,
    disabledDate
  } = props;
  // eslint-disable-next-line
  const ageHandler = (e) => {
    // setDdn(e.target.value);
    let tempCi = [...customerInformation];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.birth_info.date = dayjs(e).format("DD/MM/YYYY");
    setCostumerInformation(tempCi);
  };
  const roHandler = (option) => {
    let tempCi = [...customerInformation];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    switch (option) {
      case "1":
        temp.job.regime = "Régime général";
        break;
      case "2":
        temp.job.regime = "Travailleurs non salariés";
        break;
      case "3":
        temp.job.regime = "Agricole salarié";
        break;
      case "4":
        temp.job.regime = "Agricole exploitant";
        break;
      case "5":
        temp.job.regime = "Régime local";
        break;
      default:
        temp.job.regime = "Régime général";
        break;
    }
    setCostumerInformation(tempCi);
  };
  const selectSituationPro = (option) => {
    let tempCi = [...customerInformation];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.job.situation_pro = option;
    setCostumerInformation(tempCi);
  };

  //function that reverse previous switch
  const reverseRegime = (regime) => {
    switch (regime) {
      case "Régime général":
        return "1";

      case "Travailleurs non salariés":
        return "2";

      case "Agricole salarié":
        return "3";

      case "Agricole exploitant":
        return "4";

      case "Régime local":
        return "5";

      default:
        return "1";
    }
  };

  const icon = () => {
    switch (count) {
      case 1:
        return (
          <img alt="couple" src={require("../../../../../assets/couple.png")} />
        );
      case 2:
        return (
          <img
            alt="enfant1"
            src={require("../../../../../assets/enfant1.png")}
          />
        );
      case 3:
        return (
          <img
            alt="enfant2"
            src={require("../../../../../assets/enfant2.png")}
          />
        );
      case 4:
        return (
          <img
            alt="enfant3"
            src={require("../../../../../assets/enfant3.png")}
          />
        );
      default:
        return (
          <img
            alt="enfant4"
            src={require("../../../../../assets/enfant4.png")}
          />
        );
    }
  };
  const deleteHandler = () => {
    let temp = [...customerInformation];
    let temp2 = temp.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.forEach((a, index) => {
      if (
        a.relationship_rank > item?.relationship_rank &&
        item?.relationship_rank !== 2
      ) {
        a.relationship_rank = a.relationship_rank - 1;
      }
    });
    temp.splice(temp.indexOf(temp2), 1);
    setCostumerInformation(temp);
    // setCostumerInformation((prevState) =>
    //   prevState.filter((i) => i.relationship_rank !== item?.relationship_rank)
    // );
  };
  const roOptions = [
    { label: "Sécurité sociale", value: "1" },
    { label: "Travailleurs non salariés", value: "2" },
    { label: "Agricole salarié", value: "3" },
    { label: "Agricole exploitant", value: "4" },
    { label: "Alsace moselle", value: "5" },
  ];
  const situationOptions = [
    { value: "Salarié", label: "Salarié" },
    { value: "Profession libérale", label: "Profession libérale" },
    { value: "Artisan", label: "Artisan" },
    { value: "Commerçant", label: "Commerçant" },
    { value: "Exploitant agricole", label: "Exploitant agricole" },
    { value: "Conjoint collaborateur", label: "Conjoint collaborateur" },
    { value: "Etudiant", label: "Etudiant" },
    { value: "Retraité", label: "Retraité" },
    { value: "Sans emploi", label: "Sans emploi" },
    // { value: "Auto-entrepreneur", label: "Auto-entrepreneur" },
    { value: "Non renseigné", label: "Non renseigné" },
    // { value: "Actif", label: "Actif" },
    // { value: "Enfant", label: "Enfant" },
  ];

  // if (name !== "Conjoint(e)") {
  //   situationOptions.push({
  //     value: "Enfant",
  //     label: "Enfant",
  //   });
  // }
  return (
    <Form layout="vertical"
    initialValues={{
    
      Situation: (SessionStorageService().getCustomerInfo().find((e)=>e.relationship_rank===item.relationship_rank)?.job?.situation_pro)?SessionStorageService().getCustomerInfo().find((e)=>e.relationship_rank===item.relationship_rank)?.job?.situation_pro : "Non renseigné",
    }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Accordion defaultActiveKey="1" style={{ marginBottom: 15 }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Row
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {icon()}
                    {name !== "Conjoint(e)" ? (
                      <Tooltip
                        placement="bottom"
                        title={
                          "Un enfant est défini comme une personne non salariée, qu'elle soit étudiante, en apprentissage ou à la recherche d'un premier emploi ."
                        }
                      >
                        <h5 className={classes.titre}> {name} </h5>
                      </Tooltip>
                    ) : (
                      <h5 className={classes.titre}> {name} </h5>
                    )}
                  </div>
                  {dn && dn !== "Invalid Date" && (
                    <h5 className={classes.titre}>
                      {moment(
                        SessionStorageService().getProspect()?.dateEffet,
                        "DD-MM-YYYY"
                      )?.year() - moment(dn, "DD-MM-YYYY")?.year()}{" "}
                      ans{" "}
                      {/* {moment(Date.now()).diff(dn, "years")} ans{" "} */}
                    </h5>
                  )}
                  <h5 className={classes.titre}>
                    {" "}
                    {item?.job.regime === "Régime général"
                      ? "Sécurité sociale"
                      : item?.job.regime === "Régime local"
                      ? "Alsace moselle"
                      : item?.job?.regime}
                  </h5>
                  <div
                    style={{
                      backgroundColor: "#EA4949",
                      border: "none",
                      borderRadius: "20%",
                    }}
                    onClick={deleteHandler}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        height: "1.2rem",
                        color: "white",
                        margin: "0.5rem",
                      }}
                    />
                  </div>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                {/* <Form layout="vertical" className={classes.family}> */}
                <Form.Item
                  required
                  label="Régime Obligatoire "
                  rules={[
                    {
                      required: true,
                      message: "Veuillez remplir ce champ.",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    defaultValue={reverseRegime(item?.job.regime)}
                    value={reverseRegime(item?.job.regime)}
                    onSelect={(option) => roHandler(option)}
                    options={roOptions}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Date de naissance"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez remplir ce champ.",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    locale={locale}
                    placeholder="JJ/MM/AAAA"
                    style={{ width: "100%" }}
                    size="large"
                    type="date"
                    defaultValue={dn && dn !== "Invalid Date" ? dayjs(dn, "DD/MM/YYYY") : ""}
                    onChange={(e) => ageHandler(e)}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item label="Situation Professionelle" name="Situation">
                  <Select
                    size="large"
                    listHeight={500}
                    // defaultValue={situationOptions[0].label}
                    defaultValue={
                      item?.job.situation_pro
                        ? item?.job.situation_pro
                        : situationOptions[0].label
                    }
                    value={item?.job.situation_pro}
                    onSelect={(option) => selectSituationPro(option)}
                    options={situationOptions}
                  />
                </Form.Item>
                {/* </Form> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Form>
  );
}

export default FamilyMember;
