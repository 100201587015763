import React, { useContext } from "react";
import classes from "./FicheConseil.module.css";
import { Row, Col, Form, Select, InputNumber } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import GlobalContext from "../../../contexts/GlobalContext";
const FicheConseil = ({ data, setData }) => {
  const { valueChecked } = useContext(GlobalContext);
  const prospect = SessionStorageService().getProspect();
  const answers = [
    { value: "Oui", label: "Oui" },
    { value: "Non", label: "Non" },
  ];
  const hositalisationOptions = [
    {
      label: "<= 125: prise en charge faible des dépassements d'honoraire",
      value: "<= 125",
    },
    {
      label:
        "> 125 et <= 150 : prise en charge forte des dépassements d'honoraire",
      value: "> 125 et <= 150",
    },
    {
      label:
        "> 150 et <= 250 : prise en charge forte des dépassements d'honoraire",
      value: "> 150 et <= 250",
    },
    {
      label: "> 250 : prise en charge trés forte des dépassements d'honoraire",
      value: "> 250",
    },
  ];
  const dentaireOptions = [
    {
      label: "<= 125: Besoin faible de prise en charge de prothèses dentaires",
      value: "<= 125",
    },
    {
      label:
        "> 125 et <= 150 : Besoin moyen de prise en charge de prothèses dentaires",
      value: "> 125 et <= 150",
    },
    {
      label:
        "> 150 et <= 250 : Besoin fort de prise en charge de prothèses dentaires",
      value: "> 150 et <= 250",
    },
    {
      label:
        "> 250 : Besoin très fort de prise en charge de prothèses dentaires",
      value: "> 250",
    },
  ];
  const soinGuarantieOptions = [
    {
      label: "<= 125: prise en charge faible des dépassements d'honoraire",
      value: "<= 125",
    },
    {
      label:
        "> 125 et <= 150 : prise en charge modérée des dépassements d'honoraire",
      value: "> 125 et <= 150",
    },
    {
      label:
        "> 150 et <= 250 : prise en charge forte des dépassements d'honoraire",
      value: "> 150 et <= 250",
    },
    {
      label: "> 250 : prise en charge trés forte des dépassements d'honoraire",
      value: "> 250",
    },
  ];
  const optiqueOptions = [
    { label: "Verres progressifs", value: "Verres progressifs" },
    { label: "Verres simples", value: "Verres simples" },
    { label: "Sans", value: "Sans" },
  ];
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e : e;
    SessionStorageService().setProspect(prospect);
    // setData([...data], (data[DataIndex][name] = e.target.value));
    /* let newdata = dataAdhesion
    newdata[`${name}`] = e.target.value */
    let key = name;
    setData((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e : e,
      };
    });
  };
  React.useEffect(() => {
    setData({
      ...data,
      "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2":
        prospect[
          "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
        ] || "Non",
      "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3":
        prospect[
          "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
        ] || "Non",
      "mt_garantie||Si oui: laquelle (montant)||4":
        prospect["mt_garantie||Si oui: laquelle (montant)||4"] || "",

      "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5":
        prospect[
          "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
        ] || "Non",
      "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9":
        prospect[
          "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
        ] || "Non",
      "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6":
        prospect[
          "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
        ] || "Non",

      "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18":
        prospect[
          "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
        ] || "Non",

      "declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19":
        prospect[
          "declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
        ] || "Oui",

      "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7":
        prospect[
          `vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7`
        ] || "Non",
      "rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19":
        prospect[
          "rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19"
        ] || "",
      "budegtmensuel_garantie||Si oui: lequel ?||10":
        prospect["budegtmensuel_garantie||Si oui: lequel ?||10"] || "",
      "hospi_garantie||Hospitalisation||11":valueChecked["Hospitalisation (dépassement d'honoraires, prestations de confort)"],
        // prospect["hospi_garantie||Hospitalisation||11"] || "<= 125",
      "dentaire_garantie||Dentaire||12":valueChecked["Dentaire (prothèses, orthodontie)"],
        // prospect["dentaire_garantie||Dentaire||12"] || "<= 125",
      "soins_garantie||Soins courants||14": valueChecked["Soins courants(consultations, pharmacie)"],
        // prospect["soins_garantie||Soins courants||14"] || "<= 125",
      "optique_garantie||Optique||13": valueChecked["Optique"],
        // prospect["optique_garantie||Optique||13"] || "Verres progressifs",
      "mt_garantie||Si oui:laquelle (montant)||4":
        prospect["mt_garantie||Si oui: laquelle (montant)||4"] || "",

      "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8":
        prospect[
          "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
        ]
          ? prospect[
              "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
            ]
          : "Non",
    });
    // eslint-disable-next-line
    // setResiliation({
    //   accepte: "Oui",
    //   type: "",
    //   nom_ancier_assureur: "",
    //   num_ancier_contrat: "",
    //   date_echeance: "",
    // });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data[
    "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
  ] ? (
    <Form layout="vertical" className={classes.form}>
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?"
            }
          >
            <Select
              size="large"
              defaultValue={
                data[
                  "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Bénéficiez-vous d’une aide à la complémentaire santé ?"}
          >
            <Select
              size="large"
              defaultValue={
                data[
                  "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Si oui: lequel"}
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              defaultValue={data["mt_garantie||Si oui: laquelle (montant)||4"]}
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "mt_garantie||Si oui: laquelle (montant)||4"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row> */}

      {/* New Questions */}

      <Row xs="1" sm="2">
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?"
            }
          >
            <Select
              size="large"
              defaultValue={
                data[
                  "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
                )
              }
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?"
            }
          >
            <Select
              size="large"
              defaultValue={
                data[
                  "declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {/* End of new Question */}

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Bénéficiez-vous actuellement d'une complémentaire santé ou d'une mutuelle ?"
            }
          >
            <Select
              size="large"
              disabled
              defaultValue={
                data[
                  "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "S'agit-il d'un contrat complémentaire santé de votre employeur ?"
            }
          >
            <Select
              disabled
              size="large"
              defaultValue={
                data[
                  "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
                )
              }
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel(le) ?"
            }
          >
            <Select
              disabled
              size="large"
              defaultValue={
                data[
                  "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
                )
              }
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions, à votre assureur ou à votre mutuelle actuel(le) ?"
            }
          >
            <Select
              size="large"
              defaultValue={
                data[
                  "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row xs="1" sm="2">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Avez-vous réfléchi à un budget mensuel de cotisation ?"}
          >
            <Select
            disabled
              size="large"
              defaultValue={
                data[
                  "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Si oui: lequel (montant en euro)?"}
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <InputNumber
              type="number"
              disabled
              // disabled={
              //   data[
              //     "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
              //   ] === "Non"
              // }
              controls={false}
              style={{ width: "100%" , color: "black"}}
              size="large"
              defaultValue={
                data["budegtmensuel_garantie||Si oui: lequel ?||10"]
              }
              onChange={(e) =>
                handleChange(
                  e,
                  "change",
                  "budegtmensuel_garantie||Si oui: lequel ?||10"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row xs="1" sm="2">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Vous souhaitez mettre en avant le meilleur prix ?"}
          >
            <Select
            disabled
              size="large"
              // defaultValue={
              //   data[
              //     "meilleurprix_garantie_meilleur_prix||Vous souhaitez mettre en avant le meilleur prix ?||15"
              //   ]
              // }
              value={SessionStorageService().getProspect()["meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15"]}
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "meilleurprix_garantie_meilleur_prix||Vous souhaitez mettre en avant le meilleur prix ?||15"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row xs="1" sm="2">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={
              "Vous souhaitez mettre en avant le meilleur rapport qualité prix ?"
            }
          >
            <Select
              disabled
              size="large"
              // defaultValue={
              //   data[
              //     "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
              //   ]
              // }
              value={SessionStorageService().getProspect()["meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"]}
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Vous souhaitez mettre en avant la meilleure couverture ?"}
          >
            <Select
            disabled
              size="large"
              // defaultValue={
              //   data[
              //     "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleur couverture ?||17"
              //   ]
              // }
              value={SessionStorageService().getProspect()["meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17"]}
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleur couverture ?||17"
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label={"Avantages Rapport Qualité Prix"}>
            <Select
              size="large"
              defaultValue={
                data[
                  "rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19"
                ]
              }
              options={answers}
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "rapportqualiteprix_garantie||Avantages Rapport Qualité Prix||19"
                )
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label={"Hospitalisation"}>
            <Select
              size="large"
              disabled
              // value={data["hospi_garantie||Hospitalisation||11"]}
              value={
                valueChecked[
                  "Hospitalisation (dépassement d'honoraires, prestations de confort)"
                ]==="<= 125€"?"<= 125€ : prise en charge faible des dépassements d'honoraire":valueChecked[
                  "Hospitalisation (dépassement d'honoraires, prestations de confort)"
                ]==="> 125€ et <=150€"?"> 125€ et <= 150€ : prise en charge forte des dépassements d'honoraire":valueChecked[
                  "Hospitalisation (dépassement d'honoraires, prestations de confort)"
                ]==="> 150€ et <= 250€"?"> 150€ et <= 250€ : prise en charge forte des dépassements d'honoraire":valueChecked[
                  "Hospitalisation (dépassement d'honoraires, prestations de confort)"
                ]==="> 250€" ?"> 250€ : prise en charge trés forte des dépassements d'honoraire": "Pas de besoin spécifique"
              }
              options={hositalisationOptions}
              onSelect={(e) =>
                handleChange(e, "select", "hospi_garantie||Hospitalisation||11")
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label={"Dentaire"}>
            <Select
              size="large"
              disabled
              // value={data["dentaire_garantie||Dentaire||12"]}
              value={
                valueChecked[
                  "Dentaire (prothèses, orthodontie)"
                ]==="<= 125€"?"<= 125€ : Besoin faible de prise en charge de prothèses dentaires":valueChecked[
                  "Dentaire (prothèses, orthodontie)"
                ]==="> 125€ et <=150€"?"> 125€ et <= 150€ : Besoin moyen de prise en charge de prothèses dentaires":valueChecked[
                  "Dentaire (prothèses, orthodontie)"
                ]==="> 150€ et <= 250€"?"> 150€ et <= 250€ : Besoin fort de prise en charge de prothèses dentaires":valueChecked[
                  "Dentaire (prothèses, orthodontie)"
                ]==="> 250€" ?"> 250€ : Besoin très fort de prise en charge de prothèses dentaires": "Pas de besoin spécifique"
                // valueChecked["dentaire_garantie||Dentaire||12"]===""
              }
              options={dentaireOptions}
              onSelect={(e) =>
                handleChange(e, "select", "dentaire_garantie||Dentaire||12")
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label={"Soins courants"}>
            <Select
              size="large"
              disabled
              // value={valueChecked["Soins courants(consultations, pharmacie)"]}
              value={
                valueChecked[
                  "Soins courants(consultations, pharmacie)"
                ]==="<= 125€"?"<= 125€ : prise en charge faible des dépassements d'honoraire":valueChecked[
                  "Soins courants(consultations, pharmacie)"
                ]==="> 125€ et <=150€"?"> 125€ et <= 150€ : prise en charge modérée des dépassements d'honoraire":valueChecked[
                  "Soins courants(consultations, pharmacie)"
                ]==="> 150€ et <= 250€"?"> 150€ et <= 250€ : prise en charge forte des dépassements d'honoraire":valueChecked[
                  "Soins courants(consultations, pharmacie)"
                ]==="> 250€" ?"> 250€ : prise en charge trés forte des dépassements d'honoraire": "Pas de besoin spécifique"
                
              }
              options={soinGuarantieOptions}
              onSelect={(e) =>
                handleChange(e, "select", "soins_garantie||Soins courants||14")
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row xs="1" sm="2">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item label={"Optique"}>
            <Select
            disabled
              size="large"
              // value={data["optique_garantie||Optique||13"]}
              value={
                valueChecked["Optique"] !== "Indifférent"
                  ? valueChecked["Optique"]
                  : "Pas de besoin spécifique"
              }
              options={optiqueOptions}
              onSelect={(e) =>
                handleChange(e, "select", "optique_garantie||Optique||13")
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <></>
  );
};

export default FicheConseil;
