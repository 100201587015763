import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import "dayjs/locale/fr";
import dayjs from "dayjs";

dayjs.locale("fr");
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://2ff92d807b8834553bdf5d9e228c60cb@sentry.neopolis-dev.com/3',
    release: process.env.REACT_APP_VERSION
  });
}

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
