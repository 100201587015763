import React from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Row, Col } from "reactstrap";
import GlobalContext from "../../contexts/GlobalContext";

function valuetext(value) {
  return `${value}€`;
}
const SliderComponent = () => {
  const { badgetvalue, setBadgetvalue, tarifs, ap, setPropositions } =
    React.useContext(GlobalContext);
  function handleChange(event, newValue) {
    // debugger;
    setBadgetvalue(newValue);
    if (tarifs) {
      let temp = [...ap];
      Object.keys(tarifs).forEach((key) => {
        if (tarifs[key] < badgetvalue[0] || tarifs[key] > badgetvalue[1]) {
          temp.forEach((val, index) => {
            if (val.produit.produit_sante.cotation_id.toString() === key) {
              temp.splice(index, 1);
            }
          });
        }
      });
      if (temp.length === 0) {
        const option = [
          ap.find((e) => e?.produit?.produit_sante?.option === "4"),
        ];
        setPropositions(option);

        // return null;
      } else {
        setPropositions(temp);
      }
    }
  }
  return (
    <Box>
      <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Budget mensuel de cotisation :
      </p>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs="1">
          <p style={{ color: "#116A90", fontWeight: "bold" }}>
            {badgetvalue[0]}€
          </p>
        </Col>
        <Col>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={badgetvalue || ""}
            max={500}
            min={0}
            onChange={handleChange}
            onChangeCommitted={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Col>
        <Col xs="1">
          <p style={{ color: "#116A90", fontWeight: "bold" }}>
            {badgetvalue[1]}€
          </p>
        </Col>
      </Row>
    </Box>
  );
};

export default SliderComponent;
