import { Col, Switch } from "antd";
import React from "react";
import classes from "./GarantiesSouscripteur.module.css";
function GarantiesSouscripteur(props) {
  const { text, garantiesSouscripteur, setGarantiesSouscripteur, name } = props;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Col>
        <p className={classes.question} style={{ marginBlock: "0.5rem" }}>
          {" "}
          {text}
        </p>
      </Col>
      <Col>
        <Switch
             defaultChecked={garantiesSouscripteur[name]}
          onChange={(checked) => {
            setGarantiesSouscripteur({
              ...garantiesSouscripteur,
              [name]: checked,
            });
          }}
          style={{ marginBlock: "0.5rem" }}
        />
      </Col>
    </div>
  );
}

export default GarantiesSouscripteur;
