import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import classes from './SendEmail.module.css';
import { Row, Col, Container } from 'reactstrap';
import EditorText from './EditorText/EditorText';
import PreviewEmail from './PreviewEmail/PreviewEmail';
import GlobalContext from '../../../../contexts/GlobalContext';
import { Button } from 'antd';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import Swal from 'sweetalert2';
import moment from 'moment';
import SessionStorageService from '../../../../utils/sessionStorageService';
import axios from '../../../../utils/axios';
import { checkSelectedGamme } from '../../../../utils/utils';
function SendEmail(props) {
  const {
    modal,
    toggle,
    setModal,
    type,
    generatedDevis,
    setSpiningMail,
    SpiningMail,
    url,
    idReprise,
  } = props;
  //eslint-disable-next-line
  const {
    destination,
    editorMessage,
    setEditorMessage,
    templateDevis,
    // products,
    objetEmail,
    repriseData,
  } = React.useContext(GlobalContext);
  const [openEmail, setOpenEmail] = useState();
  const [openEmailEmpty, setOpenEmailEmpty] = useState();
  const prospectInfo = SessionStorageService().getEmailProspect();
  const product = JSON.parse(sessionStorage?.getItem('product'));
  function isValidEmail() {
    // var regex = new RegExp(
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/
    // );
    var regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);
    let validEmail = regex.test(destination);

    return validEmail;
  }
  const handleEpmtyEmailRequirements = () => {
    setOpenEmailEmpty(true);
  };
  const handleCloseEmailEmpty = () => {
    setOpenEmailEmpty(false);
  };
  const handleRequirementsEmail = () => {
    setOpenEmail(true);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const { proposition } = repriseData;

  async function fetchAndConvertToBase64(name, url) {
    return await fetch(url)
      .then((response) => response?.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      })
      .then((result) => {
        let b64 = result?.split(',')[1];
        return {
          type: 'b64',
          name: name,
          content: b64,
        };
      });
  }

  const convertAndSendAllDocs = async (doc_to_send) => {
    let time = moment().format('MM_DD_YYYY_HH_MM_SS_A');
    const results = await Promise?.all(
      Object?.entries(doc_to_send)
        .filter(([name, url]) => url)
        .map(async ([name, url]) => {
          return fetchAndConvertToBase64(
            name === 'Devis'
              ? `Devis_${time}.pdf`
              : `${decodeURIComponent(url?.split('/')?.pop())}`,
            await url
          );
        })
    );
    return results;
  };

  const EnvoyerMail = async (doc_to_send) => {
    let template_to_send = templateDevis.replace(
      '*|FNAME|*',
      prospectInfo?.surname ? prospectInfo?.surname : ''
    );
    template_to_send = template_to_send.replace(
      '*|LNAME|*',
      prospectInfo?.name ? prospectInfo?.name : ''
    );

    template_to_send = template_to_send.replace(
      '[NOM OFFRE]',
      type === 'tarificateur' ? proposition?.produit?.produit_sante?.option : ''
    );
    template_to_send = template_to_send.replace(
      '[NOM PRODUIT]',
      type === 'tarificateur'
        ? `${sessionStorage.getItem('nom_cie')} - ${
            proposition?.produit?.produit_sante?.nom_produit
          }`
        : ''
    );
    template_to_send = type === 'tarificateur'?
    (checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)? template_to_send.replace(
      '[--urlDevis--]',
      ' https://as-solutions.fr/nos-produits/nos-produits-sante/perennite-sante'
    ): template_to_send.replace(
      '[--urlDevis--]',
      'https://as-solutions.fr/nos-produits/nos-produits-sante/full-sante/'
    )) :template_to_send.replace(
      '[--urlDevis--]',
      'https://as-solutions.fr'
    ) ;
   

    convertAndSendAllDocs(doc_to_send)?.then((attachments) => {
      const partnership_id = localStorage.getItem('api_key');
      axios
        .post(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/mailchimp/send_mail`,
          {
            recipient: destination,
            subject: objetEmail,
            html: template_to_send,
            attachments: [...attachments],
            sender: 'devis@as-solutions.fr',
            body: '',
            id_opp: sessionStorage.getItem('SessionURLID'),
            url: `${window.location.origin}/tarificateur?partnership_id=${partnership_id}&ref=${idReprise}`,
          },
          {
            headers: {
              idSession: `${localStorage.getItem('token')}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.error === false) {
            setSpiningMail(false);
            setModal(false);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'E-mail envoyé avec succès !',
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setModal(false);
            setSpiningMail(!SpiningMail);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Quelque chose s'est mal passé !",
            });
          }
        })
        .catch(() => setSpiningMail(false));
    });
  };
  const handleClick = async () => {
    if (destination === '') {
      handleEpmtyEmailRequirements();
    } else if (isValidEmail() === false) {
      handleRequirementsEmail();
    } else {
      setSpiningMail(!SpiningMail);
      setModal(true);
      if (type === 'tarificateur') {
        generatedDevis('email').then((response) => {
          const doc_to_send_ccmo = {
            Devis: response?.devis,
            ipid_cg_pj: response?.ipid_cg_pj,
            ipid_ni_assistance: response?.ipid_ni_assistance,
            ipid_tableau_garantie_exemple_remboursement:
              response?.ipid_tableau_garantie_exemple_remboursement,
            ni_ccmo: response?.ni_ccmo,
            statuts_4as: response?.statuts_4as,
          };
          const doc_to_send_aesio = {
            Devis: response?.devis,
            // ipid_aesio: product?.produit?.ipid,
            // ni_aesio: product?.produit?.notice,
            info: response?.info,
            status_aesio:
              'https://sdn.as-solution.cloud.geoprod.com/static/uploads/aesio_docs/STATUTS_AESIO_VDEF0.pdf',
            pj_ipid:
              'https://sdn.as-solution.cloud.geoprod.com/static/uploads/aesio_docs/PJ_IPID_SANTE.pdf',
            assistance_ni:
              'https://sdn.as-solution.cloud.geoprod.com/static/uploads/aesio_docs/ASSISTANCE_NI_2023.pdf',
            oj_cj:
              'https://sdn.as-solution.cloud.geoprod.com/static/uploads/aesio_docs/CJ_PJ_SANTE.pdf',
          };
          
          EnvoyerMail(
            checkSelectedGamme(process.env.REACT_APP_CCMO_ID_GAMME)
              ? doc_to_send_ccmo
              : doc_to_send_aesio
          );
        });
      } else {
        const doc_to_send = {
          Devis: url,
        };
        EnvoyerMail(doc_to_send);
      }
    }
  };

  return (
    <>
      <Snackbar
        open={openEmailEmpty}
        autoHideDuration={3000}
        onClose={handleCloseEmailEmpty}
      >
        <Alert
          onClose={handleCloseEmailEmpty}
          severity='error'
          sx={{ width: '100%' }}
        >
          Veuillez remplir l'adresse mail.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openEmail}
        autoHideDuration={3000}
        onClose={handleCloseEmail}
      >
        <Alert
          onClose={handleCloseEmail}
          severity='error'
          sx={{ width: '100%' }}
        >
          Veuillez vérifier l'adresse mail.
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={SpiningMail}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: '100%' }}>
            <CircularProgress style={{ width: '4rem', height: '4rem' }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>

      <Modal show={modal} fullscreen onHide={toggle}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container fluid className={classes.contain}>
            <Row>
              <Col xs='12' lg='6'>
                <EditorText
                  message={editorMessage}
                  onChange={setEditorMessage}
                />
              </Col>
              <Col xs='12' lg='6'>
                <PreviewEmail
                  message={editorMessage}
                  url={props.url}
                  type={type}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='primary'
            size='large'
            variant='primary'
            onClick={handleClick}
          >
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendEmail;
